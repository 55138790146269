const initState = {
  quizError: null,
  quizMessage: null,
  quizMessagePopup: false,
  quizErrorPopup: false,
  quiz: null,
  userResults: null,
};

const quizReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CLOSE_QUIZ_POPUPS':
      return {
        ...state,
        quizErrorPopup: false,
        quizMessagePopup: false,
        quizError: null,
        quizMessage: null,
      };

    case 'QUIZ_CREATED':
      return {
        ...state,
        quizMessage: 'Your quiz has been created',
        quizMessagePopup: true,
      };
    case 'QUESTION_ADDED_TO_QUIZ':
      return {
        ...state,
        quizMessage: 'Question has been added',
        quizMessagePopup: true,
      };
    case 'ERROR_QUESTION_ADDED_TO_QUIZ':
      return {
        ...state,
        quizError: action.err.message,
        quizErrorPopup: true,
      };
    case 'QUESTION_HAS_BEEN_UPDATED':
      return {
        ...state,
        quizMessage: 'Question has been updated',
        quizMessagePopup: true,
      };
    case 'ERROR_UPDATING_QUESTION':
      return {
        ...state,
        quizError: action.err.message,
        quizErrorPopup: true,
      };

    case 'ERROR_FINDING_QUIZ':
      return {
        ...state,
        quizError: 'We are sorry that quiz can not be found',
        quizErrorPopup: true,
      };

    case 'USERS_QUIZ_FOUND':
      let userQuiz = action.userQuizResults.quiz;
      let userResults = action.userQuizResults.usersResults;
      userQuiz.map((q, i) => {
        return q.options.map((o, n) => {
          if (o.option === userResults[i]) {
            userQuiz[i].options[n].userPicked = true;
            if (o.answer === true) {
              return (userQuiz[i].userGotAnswerCorrect = true);
            } else {
              return (userQuiz[i].userGotAnswerCorrect = false);
            }
          } else {
            return (userQuiz[i].options[n].userPicked = false);
          }
        });
      });

      return {
        ...state,
        quiz: userQuiz,
      };

    case 'QUIZ_FOUND':
      return {
        ...state,
        quiz: action.quiz,
      };

    default:
      return state;
  }
};

export default quizReducer;
