import getCurrentQuarter from '../components/OrderSystem/functions/whatQuarter';

export const closeOrderPopUps = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_ORDER_POPUPS' });
  };
};

export const loadAutoShipCompany = ({ clinicID, userID }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //console.log('this is my company id', clinicID);
    firestore
      .collection('companies')
      .doc(clinicID)
      .get()
      .then((snapShot) => {
        if (!snapShot.isEmpty) {
          let clinic = { id: snapShot.id, data: snapShot.data() };
          firestore
            .collection('companyTotalOrders')
            .doc(clinicID)
            .get()
            .then((totalSalesSnap) => {
              if (totalSalesSnap.exists) {
                clinic.totalSales = totalSalesSnap.data();
              } else {
                clinic.totalSales = [];
              }
              if (clinic.data.nationalProgram) {
                firestore
                  .collection('companyTotalOrders')
                  .doc(clinic.data.nationalProgramID)
                  .get()
                  .then((totalSalesSnap) => {
                    //console.log('this is my totalSalesSnap', totalSalesSnap);
                    if (totalSalesSnap.exists) {
                      clinic.nationalSales = totalSalesSnap.data();
                    } else {
                      clinic.nationalSales = [];
                    }
                    const autoshipField = 'recentSearches.' + clinicID;

                    firestore
                      .collection('users')
                      .doc(userID)
                      .update({
                        [autoshipField]: { searched: new Date(), name: clinic.data.name, clinicID: clinicID },
                      })
                      .then(() => {
                        firestore
                          .collection('natPrograms')
                          .where('promoOrderProgram', '==', clinic.data.nationalProgramID)
                          .get()
                          .then((programSnapshots) => {
                            let natPrograms = [];
                            programSnapshots.forEach((p) => natPrograms.push(p.data()));

                            clinic.natPrograms = natPrograms;
                            dispatch({ type: 'ORDER_COMPANY_FOUND', clinic });
                          });
                      });
                  });
              } else {
                const autoshipField = 'recentSearches.' + clinicID;

                firestore
                  .collection('users')
                  .doc(userID)
                  .update({
                    [autoshipField]: { searched: new Date(), name: clinic.data.name, clinicID: clinicID },
                  })
                  .then(() => {
                    dispatch({ type: 'ORDER_COMPANY_FOUND', clinic });
                  });
              }
            });
        } else {
          dispatch({ type: 'ORDER_COMPANY_COULD_NOT_BE_FOUND' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_FINDING_ORDER_COMPANY', err });
      });
  };
};

export const loadAutoShipCompanyfromSearch = ({ clinicID }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('companies')
      .doc(clinicID)
      .get()
      .then((snapShot) => {
        if (!snapShot.isEmpty) {
          let clinic = { id: snapShot.id, data: snapShot.data() };

          firestore
            .collection('companyTotalOrders')
            .doc(clinicID)
            .get()
            .then((totalSalesSnap) => {
              if (totalSalesSnap.exists) {
                clinic.totalSales = totalSalesSnap.data();
              } else {
                clinic.totalSales = [];
              }
              if (clinic.data.nationalProgram) {
                //console.log('this is my nationalProgram ID', clinic.data.nationalProgramID);
                firestore
                  .collection('companyTotalOrders')
                  .doc(clinic.data.nationalProgramID)
                  .get()
                  .then((nationalSalesSnap) => {
                    if (nationalSalesSnap.exists) {
                      clinic.nationalSales = nationalSalesSnap.data();
                      firestore
                        .collection('natPrograms')
                        .where('promoOrderProgram', '==', clinic.data.nationalProgramID)
                        .orderBy('purchaseUnitCommitmentStart')
                        .get()
                        .then((programSnapshots) => {
                          let natPrograms = [];
                          programSnapshots.forEach((p) => natPrograms.push(p.data()));

                          clinic.natPrograms = natPrograms;
                          dispatch({ type: 'ORDER_COMPANY_FOUND', clinic });
                        });
                    } else {
                      clinic.nationalSales = { totalTips: 0 };
                      dispatch({ type: 'ORDER_COMPANY_FOUND', clinic });
                    }
                  });
              } else {
                dispatch({ type: 'ORDER_COMPANY_FOUND', clinic });
              }
            });
        } else {
          dispatch({ type: 'ORDER_COMPANY_COULD_NOT_BE_FOUND' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_FINDING_ORDER_COMPANY', err });
      });
  };
};

export const createNewAutoShip = (clinic, user) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('orders')
      .add({
        clinicID: clinic.id,
        clinicName: clinic.data.name,
        clinicTier: clinic.data.companyLevel,
        salesRep: clinic.data.rep ? clinic.data.rep : '',
        userID: user.id,
        userName: user.fullName,
        orderStarted: new Date(),
        lastEdited: new Date(),
        submitted: false,
      })
      .then((ref) => {
        //console.log('this is my ref', ref);

        //Create an entrie in the company order listing
        const autoshipField = 'orders.' + ref.id;
        firestore
          .collection('companies')
          .doc(clinic.id)
          .update({
            [autoshipField]: { created: new Date(), clinic: clinic.data.name, clinicID: clinic.id, approved: false },
          })
          .then(() => {
            //Then add it to the users files

            firestore
              .collection('users')
              .doc(user.id)
              .update({
                [autoshipField]: { created: new Date(), clinic: clinic.data.name, clinicID: clinic.id, approved: false },
              })
              .then(() => {
                let resp = { id: ref.id };

                dispatch({ type: 'ORDER_ORDER_CREATED', resp });
              });
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_CREATING_ORDER_ORDER', err });
      });
  };
};

export const elevateAutoShipOrder = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'ELVATE_ORDER_ORDER' });
  };
};

//This function will build the page and setup  the reducer for the base information
//Grab months
//Grab the settings from the autoship settings in the database
//Send that data to the reducer
export const buildAutoShipData = () => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('items')
      .get()
      .then((itemSnapShots) => {
        if (!itemSnapShots.isEmpty) {
          const items = [];

          itemSnapShots.forEach((item) => {
            return items.push({ id: item.id, data: item.data() });
          });

          //Need to get the tiers as well
          firestore
            .collection('technologies')
            .get()
            .then((techSnapShots) => {
              const technologies = [];

              techSnapShots.forEach((tech) => {
                return technologies.push({ id: tech.id, data: tech.data() });
              });

              let resp = { items: items, tech: technologies };
              dispatch({ type: 'ORDER_SETTINGS_LOADED', resp });
            });
        } else {
          dispatch({ type: 'ORDER_NO_ITEMS' });
        }
      });
  };
};

//This function will build just the basic data
//Items
//Send that data to the reducer
export const buildSmallAutoShipData = () => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('items')
      .get()
      .then((itemSnapShots) => {
        if (!itemSnapShots.isEmpty) {
          const items = [];

          itemSnapShots.forEach((item) => {
            return items.push({ id: item.id, data: item.data() });
          });

          //Need to get the tiers as well
          firestore
            .collection('technologies')
            .get()
            .then((techSnapShots) => {
              const technologies = [];

              techSnapShots.forEach((tech) => {
                return technologies.push({ id: tech.id, data: tech.data() });
              });

              let resp = { items: items, tech: technologies };
              dispatch({ type: 'ORDER_SETTINGS_LIGHT_LOADED', resp });
            });
        } else {
          dispatch({ type: 'ORDER_NO_ITEMS' });
        }
      });
  };
};

export const selectMonthToEdit = (month) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SELECTING_MONTHING_TO_EDIT', month });
  };
};

export const adjustOrderNotes = (notes) => {
  return (dispatch, getState) => {
    dispatch({ type: 'ADJUST_ORDER_NOTES', notes });
  };
};

export const selectBrandToEdit = (brand, brandName) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SELECTING_BRAND_TO_EDIT', brand, brandName });
  };
};

export const addItemtoOrder = ({ item, techID, row }) => {
  return (dispatch, getState) => {
    const resp = { item: item, techID: techID, row: row };
    dispatch({ type: 'ADD_ITEM_TO_ORDER', resp });
  };
};

export const addBonusItemtoOrder = ({ item, techID, row }) => {
  return (dispatch, getState) => {
    const resp = { item: item, techID: techID, row: row };
    dispatch({ type: 'ADD_BONUS_ITEM_TO_ORDER', resp });
  };
};

export const addBonusItemTypetoOrder = ({ type, techID, row }) => {
  return (dispatch, getState) => {
    const resp = { type: type, techID: techID, row: row };
    dispatch({ type: 'ADD_BONUS_TYPE_TO_ITEM', resp });
  };
};

export const adjustItemQuantitytoOrder = ({ adjust, techID, row }) => {
  return (dispatch, getState) => {
    const resp = { adjust: adjust, techID: techID, row: row };
    dispatch({ type: 'ADD_QUANTITY_TO_ITEM_ORDER', resp });
  };
};

export const adjustBonusItemQuantitytoOrder = ({ adjust, techID, row }) => {
  return (dispatch, getState) => {
    const resp = { adjust: adjust, techID: techID, row: row };
    dispatch({ type: 'ADD_BONUS_QUANTITY_TO_ITEM_ORDER', resp });
  };
};

export const addDiscountItemtoOrder = ({ item, techID, row }) => {
  return (dispatch, getState) => {
    const resp = { item: item, techID: techID, row: row };
    dispatch({ type: 'ADD_DISCOUNT_ITEM_TO_ORDER', resp });
  };
};

export const adjustDiscountItemQuantitytoOrder = ({ adjust, techID, row }) => {
  return (dispatch, getState) => {
    const resp = { adjust: adjust, techID: techID, row: row };
    dispatch({ type: 'ADD_DISCOUNT_QUANTITY_TO_ITEM_ORDER', resp });
  };
};

export const loadAutoShipOrder = ({ orderID, load }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //console.log('this is my orderID', orderID);
    firestore
      .collection('orders')
      .doc(orderID)
      .get()
      .then((snapShot) => {
        if (!snapShot.isEmpty) {
          let resp = { id: snapShot.id, data: snapShot.data() };

          firestore
            .collection('companies')
            .doc(resp.data.clinicID)
            .get()
            .then((snapShot) => {
              if (!snapShot.isEmpty) {
                let clinic = { id: snapShot.id, data: snapShot.data() };
                //console.log('this is my clinic', clinic);
                firestore
                  .collection('tiers')
                  .doc(resp.data.tierID)
                  .get()
                  .then((natSnapShot) => {
                    clinic.tier = natSnapShot.data();

                    resp.clinic = clinic;

                    firestore
                      .collection('companyTotalOrders')
                      .doc(resp.data.clinicID)
                      .get()
                      .then((totalSalesSnap) => {
                        if (!totalSalesSnap.isEmpty) {
                          clinic.totalSales = totalSalesSnap.data();
                        } else {
                          clinic.totalSales = [];
                        }
                        if (clinic.data.nationalProgram) {
                          firestore
                            .collection('natPrograms')
                            .where('promoOrderProgram', '==', clinic.data.nationalProgramID)
                            .get()
                            .then((programSnapshots) => {
                              let natPrograms = [];
                              programSnapshots.forEach((p) => natPrograms.push(p.data()));

                              clinic.natPrograms = natPrograms;
                              dispatch({ type: 'ORDER_COMPANY_FOUND', clinic });
                            });
                        } else {
                          dispatch({ type: 'ORDER_ORDER_AND_CLINIC_FOUND', resp });
                        }
                      });
                  });
              } else {
                dispatch({ type: 'ORDER_COMPANY_COULD_NOT_BE_FOUND' });
              }
            })
            .catch((err) => {
              dispatch({ type: 'ERROR_FINDING_ORDER_COMPANY', err });
            });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SEARCHING_FOR_ORDER_ORDER', err });
      });
  };
};

export const loadNationalOrder = ({ orderID, load }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //console.log('this is my orderID', orderID);
    firestore
      .collection('orders')
      .doc(orderID)
      .get()
      .then((snapShot) => {
        if (snapShot.exists) {
          let resp = { id: snapShot.id, data: snapShot.data() };

          firestore
            .collection('companies')
            .doc(resp.data.clinicID)
            .get()
            .then((snapShot) => {
              if (snapShot.exists) {
                let clinic = { id: snapShot.id, data: snapShot.data() };
                resp.clinic = clinic;
                firestore
                  .collection('companyTotalOrders')
                  .doc(resp.data.orderProgram) //Need to load the informaiton for the natial group
                  .get()
                  .then((totalSalesSnap) => {
                    if (totalSalesSnap.exists) {
                      clinic.nationalSales = totalSalesSnap.data();
                    } else {
                      clinic.nationalSales = [];
                    }
                    firestore
                      .collection('companyTotalOrders')
                      .doc(resp.data.clinicID) //Need to load the informaiton for the clinic
                      .get()
                      .then((totalSalesSnap) => {
                        if (totalSalesSnap.exists) {
                          clinic.totalSales = totalSalesSnap.data();
                        } else {
                          clinic.totalSales = [];
                        }
                        firestore
                          .collection('natPrograms')
                          .where('promoOrderProgram', '==', clinic.data.nationalProgramID)
                          .get()
                          .then((programSnapshots) => {
                            let natPrograms = [];
                            programSnapshots.forEach((p) => natPrograms.push(p.data()));

                            clinic.natPrograms = natPrograms;
                            dispatch({ type: 'ORDER_ORDER_AND_CLINIC_FOUND', resp });
                          });
                      });
                  });
              } else {
                dispatch({ type: 'ORDER_COMPANY_COULD_NOT_BE_FOUND' });
              }
            })
            .catch((err) => {
              dispatch({ type: 'ERROR_FINDING_ORDER_COMPANY', err });
            });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SEARCHING_FOR_ORDER_ORDER', err });
      });
  };
};

export const startSavingProgress = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'STARTING_SAVING_ORDER' });
  };
};

export const saveAutoShipOrder = ({ clinic, user, order, name }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    console.log('!!!!!------ We are going to be saving the order ------!!!!!');
    console.log('Clinic >>>>>', clinic);
    console.log('User >>>>>', user);
    console.log('Order >>>>>', order);
    console.log('Name >>>>>', name);
    console.log('!!!!!------ END OF INFORMATION ------!!!!!');
    let orderName = name ? name : '';

    firestore
      .collection('orders')
      .doc(order.id)
      .update({
        order: order,
        lastEdited: new Date(),
        name: orderName,
      })
      .then((ref) => {
        //console.log('this is my ref', ref);
        console.log('We have updated the order ----> ', order.id);
        console.log('This is my reference ---- > ', ref);
        //Create an entrie in the company order listing
        const autoshipField = 'orders.' + order.id;
        firestore
          .collection('companies')
          .doc(clinic.id)
          .update({
            [autoshipField]: { created: new Date(), clinic: clinic.data.name, name: orderName },
          })
          .then(() => {
            //Then add it to the users files
            console.log('we are going to update the USER ---->  ');
            firestore
              .collection('users')
              .doc(user)
              .update({
                [autoshipField]: { created: new Date(), clinic: clinic.data.name, name: orderName, clinicID: clinic.id, approved: false },
              })
              .then(() => {
                dispatch({ type: 'ORDER_ORDER_WAS_SAVED' });
              });
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SAVING_ORDER_ORDER', err });
      });
  };
};

export const copyMonthOrder = (month) => {
  return (dispatch, getState) => {
    dispatch({ type: 'COPY_MONTH_ORDER', month });
  };
};

export const exitAutoShipApp = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'EXIT_ORDER_ORDER' });
  };
};

export const clearAutoShipApp = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLEAN_ORDER_ORDER' });
  };
};

export const submitAutoShipOrder = (orderID, user, form, order, companyID, company) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('orders')
      .doc(orderID)
      .update({
        submitted: true,
        submittedDate: new Date(),
        submittedUserID: user.id,
        submittedUser: user.fullName,
        submittedUserEmail: user.email,
        form: form,
      })
      .then((ref) => {
        //WE will need to check to see if it has a redeemed promo code
        console.log('this is my order ----> ', order);
        //We will want to place in the in an entry into the promos redeemed orders collection
        //THE ID WILL MATCH THE ORDERS ID
        // Order ID
        // Company ID
        // Company Name
        // Date order was placed
        // Free Tip that was given
        // The amount of free tips

        if (Array.isArray(order.redeemedPromo)) {
          order.redeemedPromo.map(async (promo) => {
            console.log('we have a redeemed promo order !!! ');
            if (promo.name !== '') {
              let bonusTip = [];
              let bonusTipCount = 0;

              Object.keys(order.order).map((o) => {
                if (order.order[o].bonus) {
                  bonusTip.push(...order.order[o].bonus.items);
                  return (bonusTipCount = bonusTipCount + order.order[o].bonus.items[0].data.quantiy ?? 0);
                } else {
                  //We do not have any items
                  return null;
                }
              });

              console.log('this is my redeem promo id ', promo.id);
              console.log('this is my data for my redemed code ----> ', orderID, bonusTip, bonusTipCount, companyID, company.data.name);
              return await firestore.collection('promos').doc(promo.id).collection('redeemedOrders').doc(orderID).set({
                orderID,
                bonusTip,
                bonusTipCount,
                companyID,
                company: company.data.name,
                submittedDate: new Date(),
              });
            } else return null;
          });
        }

        ////////////////////////////////////////////////////////////////////////////////
        //We want to set the selected promo into the promo itself so we can keep track of it.
        ////////////////////////////////////////////////////////////////////////////////

        if (Array.isArray(order.selectedPromo)) {
          order.selectedPromo.map(async (promo) => {
            if (promo.id !== '') {
              return await firestore
                .collection('promos')
                .doc(promo.id)
                .collection('enrolledOrders')
                .doc('orders')
                .set(
                  {
                    orders: firestore.FieldValue.arrayUnion(orderID),
                  },
                  { merge: true }
                );
            } else return null;
          });
        }

        dispatch({ type: 'SUBMIT_ORDER_ORDER' }); //WE will remove this line once we want approvals to go
      })
      .catch((err) => {
        console.log('this is my error ----->', err);

        dispatch({ type: 'ERROR_SUBMITTING_ORDER_ORDER' });
      });
  };
};

export const favoriteAutoShipClinic = ({ clinic, user }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    //Create an entrie in the company order listing
    const autoshipField = 'favClinics.' + clinic.id;

    firestore
      .collection('users')
      .doc(user)
      .update({
        [autoshipField]: { created: new Date(), clinic: clinic.name, clinicID: clinic.id, approved: false },
      })
      .then(() => {
        dispatch({ type: 'ORDER_COMPANY_WAS_FAV' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_FAV_ORDER_CLINIC', err });
      });
  };
};

export const unFavoriteAutoShipClinic = ({ clinic, user }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    //Create an entrie in the company order listing
    const autoshipField = 'favClinics.' + clinic;

    firestore
      .collection('users')
      .doc(user)
      .update({
        [autoshipField]: firestore.FieldValue.delete(),
      })
      .then(() => {
        dispatch({ type: 'ORDER_COMPANY_WAS_REMOVED_FROM_FAV' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_REMOVING_FAV_ORDER_CLINIC', err });
      });
  };
};

export const addAnotherItemToMonth = ({ techID }) => {
  return (dispatch, getState) => {
    //Create an entrie in the company order listing
    let resp = { techID: techID };
    dispatch({ type: 'ADD_ANOTHER_ITEM_TO_MONTH', resp });
  };
};

export const addAnotherBonusItem = ({ techID }) => {
  return (dispatch, getState) => {
    //Create an entrie in the company order listing
    let resp = { techID: techID };
    dispatch({ type: 'ADD_ANOTHER_BONUS_ITEM', resp });
  };
};

export const addAnotherDiscountItem = ({ techID }) => {
  return (dispatch, getState) => {
    //Create an entrie in the company order listing
    let resp = { techID: techID };
    dispatch({ type: 'ADD_ANOTHER_DISCOUNT_ITEM', resp });
  };
};

export const copyAutoShipOrder = ({ user, orderID }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('orders')
      .doc(orderID)
      .get()
      .then((snapShot) => {
        //console.log('this is my ref', ref);
        if (!snapShot.isEmpty) {
          //Create an entrie in the company order listing
          const autoshipData = snapShot.data();

          //clear submissions
          autoshipData.submitted = false;

          firestore
            .collection('orders')
            .add({
              ...autoshipData,
            })
            .then((ref) => {
              const autoshipField = 'orders.' + ref.id;
              firestore
                .collection('companies')
                .doc(snapShot.data().clinicID)
                .update({
                  [autoshipField]: { created: new Date(), clinic: snapShot.data().clinicName, clinicID: snapShot.data().clinicID, approved: false },
                })
                .then(() => {
                  //Then add it to the users files
                  firestore
                    .collection('users')
                    .doc(user)
                    .update({
                      [autoshipField]: { created: new Date(), clinic: snapShot.data().clinicName },
                    })
                    .then(() => {
                      let resp = { id: snapShot.id, data: snapShot.data() };

                      firestore
                        .collection('companies')
                        .doc(resp.data.clinicID)
                        .get()
                        .then((snapShot) => {
                          if (!snapShot.isEmpty) {
                            let clinic = { id: snapShot.id, data: snapShot.data() };

                            firestore
                              .collection('tiers')
                              .doc(snapShot.data().companyNaturalTier)
                              .get()
                              .then((natSnapShot) => {
                                clinic.naturalTier = natSnapShot.data();
                                clinic.naturalTier.id = natSnapShot.id;

                                firestore
                                  .collection('tiers')
                                  .doc(snapShot.data().companyNaturalTier)
                                  .get()
                                  .then((pureSnapShot) => {
                                    clinic.pureTier = pureSnapShot.data();
                                    clinic.pureTier.id = pureSnapShot.id;
                                    firestore
                                      .collection('companyTotalOrders')
                                      .doc(resp.data.clinicID)
                                      .get()
                                      .then((totalSalesSnap) => {
                                        if (!totalSalesSnap.isEmpty) {
                                          clinic.totalSales = totalSalesSnap.data();
                                        } else {
                                          clinic.totalSales = [];
                                        }

                                        resp.clinic = clinic;

                                        dispatch({ type: 'ORDER_ORDER_AND_CLINIC_FOUND', resp });
                                      });
                                  });
                              });
                          } else {
                            dispatch({ type: 'NO_CLINIC_FOUND' });
                          }
                        });
                    });
                });
            })
            .catch((err) => {
              dispatch({ type: 'ERROR_COPYING_ORDER', err });
            });
        } else {
          dispatch({ type: 'NO_ORDER_ORDER_FOUND' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_COPYING_ORDER_ORDER', err });
      });
  };
};

export const viewLoadAutoShipCompany = ({ clinicID }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('companies')
      .doc(clinicID)
      .get()
      .then((snapShot) => {
        if (!snapShot.isEmpty) {
          let clinic = { id: snapShot.id, data: snapShot.data() };

          firestore
            .collection('tiers')
            .doc(snapShot.data().companyNaturalTier)
            .get()
            .then((natSnapShot) => {
              clinic.naturalTier = natSnapShot.data();
              clinic.naturalTier.id = natSnapShot.id;

              firestore
                .collection('tiers')
                .doc(snapShot.data().companyNaturalTier)
                .get()
                .then((pureSnapShot) => {
                  clinic.pureTier = pureSnapShot.data();
                  clinic.pureTier.id = pureSnapShot.id;

                  firestore
                    .collection('companyTotalOrders')
                    .doc(clinicID)
                    .get()
                    .then((totalSalesSnap) => {
                      if (!totalSalesSnap.isEmpty) {
                        clinic.totalSales = totalSalesSnap.data();
                      } else {
                        clinic.totalSales = [];
                      }

                      dispatch({ type: 'ORDER_COMPANY_FOUND', clinic });
                    });
                });
            });
        } else {
          dispatch({ type: 'ORDER_COMPANY_COULD_NOT_BE_FOUND' });
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_FINDING_ORDER_COMPANY', err });
      });
  };
};

export const autoShipOnChange = (event) => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: 'ORDER_ON_CHANGE', event });
  };
};

export const updateAutoShipOrderName = ({ clinic, user, orderID, name }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('orders')
      .doc(orderID)
      .update({
        name: name,
      })
      .then((ref) => {
        //console.log('this is my ref', ref);

        //Create an entrie in the company order listing
        const autoshipField = 'orders.' + orderID;
        firestore
          .collection('companies')
          .doc(clinic.id)
          .update({
            [autoshipField]: { created: new Date(), clinic: clinic.data.name, name: name },
          })
          .then(() => {
            //Then add it to the users files
            firestore
              .collection('users')
              .doc(user)
              .update({
                [autoshipField]: { created: new Date(), clinic: clinic.data.name, name: name, clinicID: clinic.id, approved: false },
              })
              .then(() => {
                dispatch({ type: 'ORDER_ORDER_WAS_SAVED' });
              });
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SAVING_ORDER_ORDER', err });
      });
  };
};

export const sendAutoShipOrderEmail = ({ orderPDF, userEmail, orderID }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();
    console.log('these are my email address I am going to be send int it ', userEmail);
    firebase
      .storage()
      .ref()
      .child(`/pdfs/${orderPDF.fileName}`)
      .put(orderPDF.file)
      .then((snapShot) => {
        firebase
          .storage()
          .ref(`/pdfs/${orderPDF.fileName}`)
          .getDownloadURL()
          .then((url) => {
            //Get the email settings
            firestore
              .collection('settings')
              .doc('mailSettings')
              .get()
              .then((mailSettings) => {
                firestore
                  .collection('mail')
                  .doc()
                  .set({
                    emailType: 'submittedEmail',
                    orderID: orderID,
                    cc: [userEmail ? userEmail : '', mailSettings.data().admin],
                    message: {
                      subject: `Your Order has been submitted`,
                      text: `Thanks for placing your order. You can find a detail listing about your order in the attached pdf -- Solta Clinic Platform`,
                      html: `<p>Thanks for placing your order.</p><p>You can find a detail listing about your order in the attached pdf</p>  <br/><br/> -- Solta Clinic Platform`,
                      attachments: [
                        {
                          filename: orderPDF.fileName,
                          path: url,
                          contentType: 'application/pdf',
                          encoding: 'base64',
                        },
                      ],
                    },
                  })
                  .then((resp) => {
                    firestore
                      .collection('orders')
                      .doc(orderID)
                      .update({
                        fileName: orderPDF.fileName,
                        fileURL: `/pdfs/${orderPDF.fileName}`,
                      });
                    dispatch({ type: 'SENT_ORDER_SUBMITTED_EMAIL_OFF' });
                  })
                  .catch((err) => {
                    dispatch({ type: 'ERROR_ORDER_SUBMITTED_EMAIL_OFF', err });
                  });
              });
          });
      });
  };
};

export const deleteAutoShipOrder = ({ clinic, user, order }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('orders')
      .doc(order)
      .delete()
      .then((ref) => {
        const autoshipField = 'orders.' + order;
        firestore
          .collection('companies')
          .doc(clinic)
          .update({
            [autoshipField]: firestore.FieldValue.delete(),
          })
          .then(() => {
            //Then add it to the users files
            firestore
              .collection('users')
              .doc(user)
              .update({
                [autoshipField]: firestore.FieldValue.delete(),
              })
              .then(() => {
                dispatch({ type: 'ORDER_ORDER_WAS_DELETED' });
              });
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_DELETING_ORDER_ORDER', err });
      });
  };
};

export const createNewOrder = (clinic, user, order, quarter) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('orders')
      .add({
        clinicID: clinic.id,
        clinicName: clinic.data.name,
        clinicTier: clinic.data.companyLevel,
        salesRep: clinic.data.rep ? clinic.data.rep : '',
        userID: user.id,
        userName: user.fullName,
        tier: order.tierName,
        tierID: order.orderTier,
        pricingLevel: order.pricingLevel,
        orderProgram: order.orderProgram,
        orderProgramName: order.orderProgramName,
        orderStarted: new Date(),
        lastEdited: new Date(),
        submitted: false,
      })
      .then((ref) => {
        //console.log('this is my ref', ref);
        //Create an entrie in the company order listing
        const autoshipField = 'orders.' + ref.id;

        firestore
          .collection('companies')
          .doc(clinic.id)
          .update({
            [autoshipField]: { created: new Date(), clinic: clinic.data.name, orderProgram: order.orderProgramName, clinicID: clinic.id, approved: false },
          })
          .then(() => {
            //Then add it to the users files
            firestore
              .collection('users')
              .doc(user.id)
              .update({
                [autoshipField]: { created: new Date(), clinic: clinic.data.name, clinicID: clinic.id, orderProgram: order.orderProgramName, approved: false },
              })
              .then(() => {
                //Update the data for the total orders
                //const quarterInput = orders.year.month = array with orderID
                firestore
                  .collection('tiers')
                  .doc(order.orderTier)
                  .get()
                  .then((tierSnapShot) => {
                    const quarterInput = `orders.${quarter.year}.${quarter.quarter}`;
                    firestore
                      .collection('companyTotalOrders')
                      .doc(clinic.id)
                      .get()
                      .then((compTotalSnapshot) => {
                        console.log('this is my snapshot', compTotalSnapshot.exists);
                        if (!compTotalSnapshot.exists) {
                          firestore
                            .collection('companyTotalOrders')
                            .doc(clinic.id)
                            .set({
                              orders: { [quarter.year]: { [quarter.quarter]: firestore.FieldValue.arrayUnion(ref.id) } },
                            })
                            .then(() => {
                              let resp = { id: ref.id, tier: tierSnapShot.data(), natProgram: false };
                              dispatch({ type: 'NEW_ORDER_WAS_CREATED', resp });
                            });
                        } else {
                          console.log('lets just update the field');
                          //We will need to update the company totals on the order

                          firestore
                            .collection('companyTotalOrders')
                            .doc(clinic.id)
                            .update({
                              [quarterInput]: firestore.FieldValue.arrayUnion(ref.id),
                            })
                            .then(() => {
                              let resp = { id: ref.id, tier: tierSnapShot.data(), natProgram: false };

                              dispatch({ type: 'NEW_ORDER_WAS_CREATED', resp });
                            });
                        }
                      });
                  });
              });
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_CREATING_ORDER_ORDER', err });
      });
  };
};

export const createNewNatOrder = (clinic, user, order, quarter) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('orders')
      .add({
        clinicID: clinic.id,
        clinicName: clinic.data.name,
        clinicTier: clinic.data.companyLevel,
        salesRep: clinic.data.rep ? clinic.data.rep : '',
        userID: user.id,
        userName: user.fullName,
        tier: order.tierName,
        orderProgram: order.orderProgram,
        orderProgramName: order.orderProgramName,
        nationalProgramOrder: true,
        orderStarted: new Date(),
        lastEdited: new Date(),
        submitted: false,
      })
      .then((ref) => {
        //console.log('this is my ref', ref);
        //Create an entrie in the company order listing
        const autoshipField = 'orders.' + ref.id;

        firestore
          .collection('companies')
          .doc(clinic.id)
          .update({
            [autoshipField]: { created: new Date(), clinic: clinic.data.name, orderProgram: order.orderProgramName, clinicID: clinic.id, approved: false },
          })
          .then(() => {
            //Then add it to the users files
            firestore
              .collection('users')
              .doc(user.id)
              .update({
                [autoshipField]: { created: new Date(), clinic: clinic.data.name, clinicID: clinic.id, orderProgram: order.orderProgramName, approved: false },
              })
              .then(() => {
                //Update the data for the total orders
                //const quarterInput = orders.year.month = array with orderID
                //const quarterInput = `orders.${quarter.year}.${quarter.quarter}`;
                firestore
                  .collection('companyTotalOrders')
                  .doc(order.orderProgram) //National Program Orders will all be pooled together
                  .get()
                  .then((nationalTotalSnapshot) => {
                    let natData = nationalTotalSnapshot.exists ? nationalTotalSnapshot.data() : { totalTips: 0 };

                    firestore
                      .collection('companyTotalOrders')
                      .doc(order.orderProgram)
                      .set({
                        orders: { [quarter.year]: { [quarter.quarter]: firestore.FieldValue.arrayUnion(ref.id) } },
                      })
                      .then(() => {
                        firestore
                          .collection('companyTotalOrders') //Now grab the company data so that we can show that seperate
                          .doc(clinic.id)
                          .get()
                          .then((companyTotalOrders) => {
                            let companyData = companyTotalOrders.exists ? companyTotalOrders.data() : { totalTips: 0 };
                            let resp = { id: ref.id, natProgram: true, nationalProgram: natData, companyTotalOrders: companyData };

                            dispatch({ type: 'NEW_ORDER_WAS_CREATED', resp });
                          });
                      });
                  });
              });
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_CREATING_ORDER_ORDER', err });
      });
  };
};

export const updateRepQuarterGoal = ({ repID, quarterGoal, year, quarter }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    let updatePath = 'goals.' + year + '.' + quarter;

    firestore
      .collection('reps')
      .doc(repID)
      .update({
        [updatePath]: quarterGoal,
      })
      .then((resp) => {
        dispatch({ type: 'REP_QUARTER_GOAL_WAS_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_TO_UPDATE_REP_QUARTER_GOAL', err });
      });
  };
};

export const loadingOrderCompany = (clinicID) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SET_LOADING_COMPNAY_ID', clinicID });
  };
};

export const openOrderApproval = (order) => {
  return (dispatch, getState) => {
    dispatch({ type: 'START_APPROVAL_ORDER', order });
  };
};

export const closeOrderApproval = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_APPROVAL_ORDER' });
  };
};

export const approveOrder = ({ user, orderID, clinic, order }) => {
  return async (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    try {
      await firestore
        .collection('orders')
        .doc(orderID)
        .update({
          approved: {
            approvedBy: user.fullName,
            approvedByID: user.id,
            approvedOn: new Date(),
          },
        });

      //WE are going to need to update the total for the order so that we can get that information

      let companyTotalData = clinic.totalSales;

      let quarter = getCurrentQuarter();

      let quarterInputTotal = `ordersTotals.${quarter.year}.${quarter.quarter}`;

      console.log('this is my company Total Data ---- > ', companyTotalData);

      let quarterTotal = companyTotalData ? (companyTotalData.ordersTotals ? (companyTotalData.ordersTotals[quarter.year] ? (companyTotalData.ordersTotals[quarter.year][quarter.quarter] ? companyTotalData.ordersTotals[quarter.year][quarter.quarter] : 0) : 0) : 0) : 0;
      //console.log('this is my quarterTotal --->', quarterTotal);
      console.log('this is my order----->  ', order);

      let newTotal = order.order.totalOrder + quarterTotal;

      console.log('this is my company quarter Total ----> ', quarterTotal);
      console.log('this is my new total ---> ', newTotal);

      await firestore
        .collection('companyTotalOrders')
        .doc(order.clinicID)
        .update({
          [quarterInputTotal]: newTotal,
        });

      //console.log('We finished updating the total order');
      dispatch({ type: 'ORDER_WAS_APPROVED' });
    } catch (err) {
      dispatch({ type: 'ERROR_APPROVING_ORDER', err });
    }
  };
};

export const sendApproveOrderEmail = ({ user, order, pdf, userEmail }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    const firebase = getFirebase();

    /*
    const createPDF = firebase.functions().httpsCallable('createOrderPdf');
    console.log('I am about to call the ordser');
    createPDF(order).then((result) => {
      console.log('did I get a result', result);
    });
*/

    const fileDate = new Date().toISOString().replace(/T.*/, '').split('-').reverse().join('-');
    const fileName = order.name ? order.name.split(' ').join('-') + '-' : '';
    const orderProgram = order.orderProgramName ? order.orderProgramName.split(' ').join('-') : 'Solta';
    const clinicNameFileSafe = fileName + order.clinicName.split(' ').join('-') + '-' + orderProgram + '-order-' + fileDate + '-APPROVED.pdf';

    firebase
      .storage()
      .ref()
      .child(`/pdfs/approvedOrders/${clinicNameFileSafe}`)
      .put(pdf)
      .then((ref) => {
        firebase
          .storage()
          .ref(`/pdfs/approvedOrders/${clinicNameFileSafe}`)
          .getDownloadURL()
          .then((url) => {
            firestore
              .collection('users')
              .doc(order.userID)
              .get()
              .then((salesRep) => {
                //Get the email settings
                //console.log('this is my userID that I am getting -> ', order.userID);
                //console.log('this is my sales rep that I am getting -->', salesRep);
                firestore
                  .collection('settings')
                  .doc('mailSettings')
                  .get()
                  .then((mailSettings) => {
                    //Search to make sure that the email has not already been sent.
                    firestore
                      .collection('mail')
                      .where('orderID', '==', order.id)
                      .where('emailType', '==', 'approvedEmail')
                      .get()
                      .then((foundEmails) => {
                        //console.log('is there any these are my emails', foundEmails.empty);
                        if (foundEmails.empty) {
                          //console.log('no email has been sent');
                          firestore
                            .collection('mail')
                            .add({
                              emailType: 'approvedEmail',
                              orderID: order.id,
                              cc: [mailSettings.data().shipping, salesRep.data().email, userEmail ? userEmail : ''],
                              bcc: mailSettings.data().admin,
                              message: {
                                subject: `${order.clinicName} - ${order.name ? order.name : ''} order has been approved`,
                                text: `This order has been approved by ${user.fullName}. You can find a detail listing about the order in the attached pdf -- Solta Clinic Platform`,
                                html: `<p>This order has been approved by ${user.fullName}.</p><p>You can find a detail listing about the order in the attached pdf</p>  <br/><br/> -- Solta Clinic Platform`,
                                attachments: [
                                  {
                                    filename: clinicNameFileSafe,
                                    path: url,
                                    contentType: 'application/pdf',
                                    encoding: 'base64',
                                  },
                                ],
                              },
                            })
                            .then((resp) => {
                              firestore
                                .collection('orders')
                                .doc(order.id)
                                .update({
                                  submittedEmailID: resp.id,
                                  fileName: clinicNameFileSafe,
                                  fileURL: `/pdfs/approvedOrders/${clinicNameFileSafe}`,
                                })
                                .then((resp) => {
                                  dispatch({ type: 'ORDER_EMAIL_HAS_BEEN_SENT' });
                                });
                            })
                            .catch((err) => {
                              dispatch({ type: 'ERROR_SENDING_APPROVE_EMAIL_OFF', err });
                            });
                        } else {
                          console.log('this is email has already been sent.');
                          dispatch({ type: 'ORDER_EMAIL_HAS_ALREADY_BEEN_SENT' });
                        }
                      });
                  });
              });
          });
      });
  };
};

export const unApproveOrder = ({ user, order }) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('orders')
      .doc(order.id)
      .update({
        approved: false,
      })
      .then((ref) => {
        //console.log('this is my ref', ref);

        //Create an entrie in the company order listing
        const autoshipField = 'orders.' + order.id;
        firestore
          .collection('companies')
          .doc(order.clinicID)
          .update({
            [autoshipField]: { created: new Date(), clinic: order.clinicName, name: order.name, approved: false },
          })
          .then(() => {
            //Then add it to the users files
            firestore
              .collection('users')
              .doc(user.id)
              .update({
                [autoshipField]: { created: new Date(), clinic: order.clinicName, name: order.name, clinicID: order.clinicID, approved: false },
              })
              .then(() => {
                dispatch({ type: 'ORDER_WAS_UNAPPROVED' });
              });
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_UNAPPROVING_ORDER', err });
      });
  };
};

export const rejectSubmittedOrder = ({ user, order, rejectedNote }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    //console.log('this is my order information', order);
    firestore
      .collection('orders')
      .doc(order.id)
      .update({
        rejected: {
          rejectedBy: user.fullName,
          rejectedByID: user.id,
          rejectedOn: new Date(),
          rejectedNote: rejectedNote,
        },
        submitted: false,
      })
      .then((ref) => {
        //console.log('this is my ref', ref);
        //Create an entrie in the company order listing
        firestore
          .collection('users')
          .doc(order.userID)
          .get()
          .then((salesRep) => {
            firestore
              .collection('mail')
              .add({
                emailType: 'rejectedEmail',
                orderID: order.id,
                cc: [user.email, salesRep.data().email],
                message: {
                  subject: `REJECTED - ${order.clinicName} - ${order.name} order`,
                  text: `This order has been rejected by ${user.fullName}. ${rejectedNote} -- Solta Clinic Platform`,
                  html: `<p>This order has been rejected by ${user.fullName}.</p><p> ${rejectedNote}</p>  <br/><br/> -- Solta Clinic Platform`,
                },
              })
              .then((resp) => {
                //console.log('this is my resp', resp);

                dispatch({ type: 'ORDER_WAS_REJECTED' });
              })
              .catch((err) => {
                dispatch({ type: 'ERROR_SENDING_REJECTED_EMAIL_OFF', err });
              });
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_APPROVING_ORDER', err });
      });
  };
};

export const cancelSubmittedOrder = ({ user, order }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    console.log('this is my user', user);
    console.log('this is my order -->', order);
    firestore
      .collection('orders')
      .doc(order.id)
      .update({
        cancel: {
          canceledBy: user.fullName,
          canceledByID: user.id,
          canceledOn: new Date(),
        },
        //submitted: false,
      })
      .then((ref) => {
        //console.log('this is my ref', ref);
        //Create an entrie in the company order listing
        firestore
          .collection('users')
          .doc(order.userID)
          .get()
          .then((salesRep) => {
            console.log('this is my sales rep --->', salesRep);
            firestore
              .collection('settings')
              .doc('mailSettings')
              .get()
              .then((mailSettings) => {
                firestore
                  .collection('mail')
                  .add({
                    emailType: 'canceledEmail',
                    orderID: order.id,
                    cc: [mailSettings.data().shipping, mailSettings.data().admin, salesRep.data().email],
                    message: {
                      subject: `ORDER CANCELED - ${order.clinicName} - ${order.name} order`,
                      text: `This order has been CANCELED by ${user.fullName}.  -- Solta Clinic Platform`,
                      html: `<p>This order has been CANCELED by ${user.fullName}.</p>  <br/><br/> -- Solta Clinic Platform`,
                    },
                  })
                  .then((resp) => {
                    //We need to remove the companies totals from their history
                    firestore
                      .collection('orders')
                      .doc(order.id)
                      .get()
                      .then((orderDataSnap) => {
                        const orderData = orderDataSnap.data();
                        let totalOrderData = {};
                        let totalTipCount = calculateTipsFromAllOrders(totalOrderData, orderData);

                        firestore
                          .collection('companyTotalOrders')
                          .doc(orderData.clinicID)
                          .get()
                          .then((oldCompTotals) => {
                            let oldTotals = oldCompTotals.data();

                            let updatedTipOrder = removeTipsFromAllOrdersGroup(oldTotals.tips, totalTipCount.tips);

                            //Update the data in the database with the new information

                            firestore
                              .collection('companyTotalOrders')
                              .doc(orderData.clinicID)
                              .update({
                                totalTips: firestore.FieldValue.increment(-totalTipCount.totalTipCount),
                                tips: { ...updatedTipOrder },
                                totalOrders: firestore.FieldValue.increment(-totalTipCount.totalOrders),
                              })
                              .then((ref) => {
                                firestore
                                  .collection('reps')
                                  .doc(order.userID)
                                  .update({
                                    //[userPath]: firestore.FieldValue.arrayUnion({ order: orderID, total: order.order.totalOrder }),
                                    totalOrders: firestore.FieldValue.increment(-totalTipCount.totalOrders),
                                  })

                                  .then((ref) => {
                                    if (orderData.nationalProgramOrder) {
                                      firestore
                                        .collection('companyTotalOrders')
                                        .doc(orderData.orderProgram)
                                        .update({
                                          totalTips: firestore.FieldValue.increment(-totalTipCount.totalTipCount),
                                          tips: { ...updatedTipOrder },
                                          totalOrders: firestore.FieldValue.increment(-totalTipCount.totalOrders),
                                        })
                                        .then((ref) => {
                                          dispatch({ type: 'ORDER_WAS_CANCELED' });
                                        });
                                    } else {
                                      dispatch({ type: 'ORDER_WAS_CANCELED' });
                                    }
                                  });
                              });
                          });
                        //firestore.collection('companyTotalOrders').doc(companyID).update({});
                      });
                  })
                  .catch((err) => {
                    dispatch({ type: 'ERROR_SENDING_CANCELED_EMAIL_OFF', err });
                  });
              });
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_CANCELING_ORDER', err });
      });
  };
};

export const calculateOrderTips = (order, companyID) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('companyTotalOrders')
      .doc(companyID)
      .get()
      .then((snapShot) => {
        if (snapShot.exists) {
          //This is my data that we currently have
          let totalOrderData = snapShot.data();
          let totalTipCount = calculateTipsFromAllOrdersGroup(totalOrderData, order);
          let quarterData = getCurrentQuarter();
          //console.log('this is my total tip count', totalTipCount);
          //Update the data in the database with the new information
          let orderProgramPath = 'orderPrograms.' + quarterData.year;
          let orderTierPath = 'orderTiers.' + quarterData.year;
          firestore
            .collection('companyTotalOrders')
            .doc(companyID)
            .update({
              totalTips: firestore.FieldValue.increment(totalTipCount.totalTipCount),
              tips: totalTipCount.tips,
              totalOrders: totalTipCount.totalOrders,
              [orderProgramPath]: totalTipCount.orderPrograms,
              [orderTierPath]: totalTipCount.orderTiers,
            })
            .then((ref) => {
              dispatch({ type: 'ORDER_TIPS_CALCULATED' }); //WE will remove this line once we want approvals to go
            });
        } else {
          //This is my data that we currently have
          let totalOrderData = {};
          let quarterData = getCurrentQuarter();
          let totalTipCount = calculateTipsFromAllOrders(totalOrderData, order);
          //Update the data in the database with the new information

          firestore
            .collection('companyTotalOrders')
            .doc(companyID)
            .set({
              totalTips: totalTipCount.totalTipCount,
              tips: totalTipCount.tips,
              totalOrders: totalTipCount.totalOrders,
              orderPrograms: { [quarterData.year]: totalTipCount.orderPrograms },
              orderTiers: { [quarterData.year]: totalTipCount.orderTiers },
            })

            .then((ref) => {
              dispatch({ type: 'ORDER_TIPS_CALCULATED' }); //WE will remove this line once we want approvals to go
            });
        }
      });
  };
};

export const calculateNationalOrderTips = (order, nationalProgramID) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    //We need to update the total for the company's orders
    //We need to update all of the tip counts per brand
    //We need to remove this function until the order has been approved
    firestore
      .collection('companyTotalOrders')
      .doc(nationalProgramID)
      .get()
      .then((snapShot) => {
        if (snapShot.exists) {
          //This is my data that we currently have
          let totalOrderData = snapShot.data();
          let totalTipCount = calculateTipsFromAllOrdersGroup(totalOrderData, order);
          let quarterData = getCurrentQuarter();

          //Update the data in the database with the new information
          let orderProgramPath = 'orderPrograms.' + quarterData.year;
          let orderTierPath = 'orderTiers.' + quarterData.year;
          firestore
            .collection('companyTotalOrders')
            .doc(nationalProgramID)
            .update({
              totalTips: firestore.FieldValue.increment(totalTipCount.totalTipCount),
              tips: totalTipCount.tips,
              totalOrders: totalTipCount.totalOrders,
              [orderProgramPath]: totalTipCount.orderPrograms,
              [orderTierPath]: totalTipCount.orderTiers,
            })
            .then((ref) => {
              dispatch({ type: 'NATIONAL_TIPS_CALCULATED' }); //WE will remove this line once we want approvals to go
            });
        } else {
          //This is my data that we currently have
          let totalOrderData = {};
          let quarterData = getCurrentQuarter();
          let totalTipCount = calculateTipsFromAllOrders(totalOrderData, order);
          //Update the data in the database with the new information

          firestore
            .collection('companyTotalOrders')
            .doc(nationalProgramID)
            .set({
              totalTips: totalTipCount.totalTipCount,
              tips: totalTipCount.tips,
              totalOrders: totalTipCount.totalOrders,
              orderPrograms: { [quarterData.year]: totalTipCount.orderPrograms },
              orderTiers: { [quarterData.year]: totalTipCount.orderTiers },
            })

            .then((ref) => {
              dispatch({ type: 'NATIONAL_TIPS_CALCULATED' }); //WE will remove this line once we want approvals to go
            });
        }
      });
  };
};

export const calculateRepSales = (orderID, userID, order) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    let quarterData = getCurrentQuarter();

    firestore
      .collection('reps')
      .doc(userID)
      .get()
      .then((snapShot) => {
        if (snapShot.exists) {
          //Then update the users profile with the correct data
          let userPath = quarterData.year + '.' + quarterData.quarter;
          firestore
            .collection('reps')
            .doc(userID)
            .update({
              [userPath]: firestore.FieldValue.arrayUnion({ order: orderID, total: order.order.totalOrder }),
              totalOrders: firestore.FieldValue.increment(order.order.totalOrder),
            })
            .then((ref) => {
              dispatch({ type: 'REP_TOTAL_CALCULATED' });
            });
        } else {
          firestore
            .collection('reps')
            .doc(userID)
            .set({
              [quarterData.year]: { [quarterData.quarter]: [{ order: orderID, total: order.order.totalOrder }] },
              totalOrders: order.order.totalOrder,
            })
            .then((ref) => {
              dispatch({ type: 'REP_TOTAL_CALCULATED' });
            });
        }
      });
  };
};

export const getEmailStatus = (emailID, orderID) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    console.log('We have found a new email lets log the status', emailID);
    firestore
      .collection('mail')
      .doc(emailID)
      .get()
      .then((snapShot) => {
        if (snapShot.exists) {
          firestore
            .collection('orders')
            .doc(orderID)
            .update({ submittedEmailState: snapShot.data().delivery.state })
            .then((ref) => {
              let emailStatus = { [emailID]: snapShot.data().delivery.state };
              dispatch({ type: 'EMAIL_FOUND', emailStatus });
            });
        } else {
          firestore
            .collection('orders')
            .doc(orderID)
            .update({ submittedEmailState: 'FAILED' })
            .then((ref) => {
              let emailStatus = { [emailID]: 'FAILED' };
              dispatch({ type: 'EMAIL_FOUND', emailStatus });
            });
        }
      });
  };
};

//This will be used to calculate tips for total orders on approve
const calculateTipsFromAllOrders = (totalOrderData, order) => {
  console.log('this is my totalData', totalOrderData);
  let quarterData = getCurrentQuarter();
  let totalTipCount = [];

  let orderData = totalOrderData;

  if (!orderData.tips) {
    orderData.tips = {};
    orderData.tips[quarterData.year] = { bonus: {}, supplies: {} };
  }

  //If there is no supplies
  if (!orderData.tips[quarterData.year].supplies) {
    orderData.tips[quarterData.year].supplies = {};
  }

  //lets go through the items
  Object.keys(order.order.order).map((o) => {
    order.order.order[o].items.map((i) => {
      //We need to check to see if the item is not a tip

      if (i.data.itemSupply) {
        return (orderData.tips[quarterData.year].supplies[i.item] = orderData.tips[quarterData.year].supplies ? (orderData.tips[quarterData.year].supplies[i.item] ? orderData.tips[quarterData.year].supplies[i.item] + i.quantiy : i.quantiy) : i.quantiy);
      } else {
        totalTipCount.push(i.quantiy);
        return (orderData.tips[quarterData.year][i.item] = orderData.tips[quarterData.year][i.item] ? orderData.tips[quarterData.year][i.item] + i.quantiy : i.quantiy);
      }
    });
    if (order.order.order[o].bonus) {
      order.order.order[o].bonus.items.map((i) => {
        return (orderData.tips[quarterData.year].bonus[i.item] = orderData.tips[quarterData.year].bonus[i.item] ? orderData.tips[quarterData.year].bonus[i.item] + i.quantiy : i.quantiy);
      });
    }
    return true;
  });

  orderData.totalOrders = orderData.totalOrders ? orderData.totalOrders + order.order.totalOrder : order.order.totalOrder;
  //Figure out what programs they are in.
  let orderPrograms = orderData.orderPrograms ? (orderData.orderPrograms[quarterData.year] ? orderData.orderPrograms[quarterData.year] : []) : [];

  if (orderData.orderPrograms) {
    if (!orderData.orderPrograms[quarterData.year].includes(order.orderProgram)) {
      orderPrograms.push(order.orderProgram);
    }
  } else {
    orderPrograms.push(order.orderProgram);
  }

  //Figure out what tiers the clinic is in

  let orderTiers = orderData.orderTiers ? (orderData.orderTiers[quarterData.year] ? orderData.orderTiers[quarterData.year] : []) : [];

  if (orderData.orderTiers) {
    if (!orderData.orderTiers[quarterData.year].includes(order.order.orderTier)) {
      orderTiers.push(order.order.orderTier);
    }
  } else {
    orderTiers.push(order.order.orderTier);
  }

  return { totalTipCount: totalTipCount.length > 0 ? totalTipCount.reduce((a, b) => a + b) : 0, tips: orderData.tips, totalOrders: orderData.totalOrders, orderPrograms: orderPrograms, orderTiers: orderTiers };
};

export const sendingEmail = (orderID) => {
  return (dispatch, getState) => {
    dispatch({ type: 'SENDING_EMAIL', orderID });
  };
};

///////////////////////////
//This function is used to calculate all of the orders for the companies
//It is activated when you click on the button on the view orders page
///////////////////////////

export const customItemPricing = ({ price, techID, row }) => {
  return (dispatch, getState) => {
    const resp = { price, techID, row };

    dispatch({ type: 'CUSTOM_PRICE_ADJUSTMENT', resp });
  };
};

///////////////////////////
//This function is used to calculate all of the orders for the companies
//It is activated when you click on the button on the view orders page
///////////////////////////
export const calculateAllOrders = () => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    //First thing we need to do is pull ALL of the orders
    firestore
      .collection('orders')
      .get()
      .then((orderSnapShots) => {
        //First thing we need to do is check to see if it is approved
        let orderNatList = {};
        let orderList = {};

        orderSnapShots.forEach((order) => {
          if (order.data().approved && !order.data().cancel) {
            if (order.data().nationalProgramOrder) {
              orderNatList[order.data().orderProgram] = calculateTipsFromAllOrdersGroup(orderNatList[order.data().orderProgram] ? orderNatList[order.data().orderProgram] : {}, order.data());
              orderList[order.data().clinicID] = calculateTipsFromAllOrdersGroup(orderList[order.data().clinicID] ? orderList[order.data().clinicID] : {}, order.data());
            } else {
              orderList[order.data().clinicID] = calculateTipsFromAllOrdersGroup(orderList[order.data().clinicID] ? orderList[order.data().clinicID] : {}, order.data());
            }
          }
        });

        console.log('this is my order List ----> ', orderList);
        console.log('this is my order Nat List ----> ', orderNatList);

        //Now we need to update the total orders on the database
        Object.keys(orderNatList).map((natID) => {
          //console.log('this is my natlist I am updating', natID);
          //console.log('this is my orderNatList I am going to update', orderNatList[natID]);
          return firestore
            .collection('companyTotalOrders')
            .doc(natID)
            .set({
              ...orderNatList[natID],
            });
        });

        Object.keys(orderList).map((clinicID) => {
          //console.log('this is my clinicList I am updating', clinicID);
          //console.log('this is my clinicList I am going to update', orderList[clinicID]);
          return firestore
            .collection('companyTotalOrders')
            .doc(clinicID)
            .set({
              ...orderList[clinicID],
            });
        });

        //console.log('this is my orderNatList', orderNatList);

        //console.log('this is my orderList', orderList);
      });
  };
};

///////////////////////////
//This function is used to calculate all of the sales reps sales from all of the orders
//It is activated when you click on the button on the view orders page
///////////////////////////
export const calculateAllSalesRepOrders = () => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    //First thing we need to do is pull ALL of the orders
    firestore
      .collection('orders')
      .get()
      .then((orderSnapShots) => {
        //First thing we need to do is check to see if it is approved
        let salesReps = {}; // We will use this to store the sales rep

        orderSnapShots.forEach((order) => {
          if (order.data().approved && !order.data().cancel) {
            //We need to add the capture the sales rep
            //We also need to caputre the total amounts

            //We will save the data as follows
            //salesReps.salesRepID
            ////Year
            //////Quarter
            //////// This is an array or {ORDER ID, TOTAL}
            if (salesReps[order.data().submittedUserID]) {
              let orderDate = order.data().submittedDate.toDate();
              //We need to check to make sure that the quarter is in the array
              let orderQuarter = whatQuarterIsMonth(orderDate.getMonth());

              if (salesReps[order.data().submittedUserID][orderDate.getFullYear()][orderQuarter]) {
                salesReps[order.data().submittedUserID][orderDate.getFullYear()][orderQuarter].push({ order: order.id, total: order.data().order.totalOrder });
              } else {
                salesReps[order.data().submittedUserID][orderDate.getFullYear()][orderQuarter] = [];
                salesReps[order.data().submittedUserID][orderDate.getFullYear()][orderQuarter].push({ order: order.id, total: order.data().order.totalOrder });
              }
            } else {
              //We have to build the data structure first
              salesReps[order.data().submittedUserID] = {};
              let orderDate = order.data().submittedDate.toDate();
              salesReps[order.data().submittedUserID][orderDate.getFullYear()] = {};

              let orderQuarter = whatQuarterIsMonth(orderDate.getMonth());
              salesReps[order.data().submittedUserID][orderDate.getFullYear()][orderQuarter] = [];

              //Now we can add the data to the array
              salesReps[order.data().submittedUserID][orderDate.getFullYear()][orderQuarter].push({ order: order.id, total: order.data().order.totalOrder });
            }
          }
        });

        let salesRepTotals = {};
        Object.keys(salesReps).map((rep) => {
          let repSales = {};
          repSales.total = [];
          salesRepTotals[rep] = {};

          Object.keys(salesReps[rep]).map((year) => {
            repSales[year] = [];
            salesRepTotals[rep][year] = {};

            Object.keys(salesReps[rep][year]).map((quarter) => {
              repSales[quarter] = [];

              //Calculate the quarters totals
              salesReps[rep][year][quarter].map((order) => {
                repSales[quarter].push(order.total);
                repSales.total.push(order.total);
                return true;
              });

              if (repSales[quarter].length > 0) {
                repSales[year].push(
                  repSales[quarter].reduce(function (acc, val) {
                    return acc + val;
                  }, 0)
                );
                salesRepTotals[rep][year][quarter] = repSales[quarter].reduce(function (acc, val) {
                  return acc + val;
                }, 0);
                salesRepTotals[rep][year].total = repSales[year].reduce(function (acc, val) {
                  return acc + val;
                }, 0);
              }
              return true;
            });

            //Lets Add the years together
            return (salesRepTotals[rep].total = repSales.total.reduce(function (acc, val) {
              return acc + val;
            }, 0));
          });

          return true;
        });

        //After we have done this we can then calculate all of the totals
        //We need to update the sales rep informaiton
        Object.keys(salesReps).map((rep) => {
          return firestore
            .collection('reps')
            .doc(rep)
            .update({
              ...salesReps[rep],
              totals: { ...salesRepTotals[rep] },
              totalOrders: salesRepTotals[rep].total,
            });
        });
      });
  };
};

//This will be used to calculate tips for total orders on approve
const calculateTipsFromAllOrdersGroup = (totalOrderData, order) => {
  //let quarterData = getCurrentQuarter();
  let totalTipCount = [];
  let totalTips = [];
  let totalBonusTips = [];
  let orderTiers = '';
  let orderData = { ...totalOrderData };

  console.log('this is my order Data', order);

  let orderApprovedDate = order.approved.approvedOn ? order.approved.approvedOn.toDate() : order.submittedDate.toDate();

  //console.log('this is my order approved date ----> ', orderApprovedDate);

  let orderYear = orderApprovedDate.getFullYear();

  //console.log('this is my orders year --->', orderYear);

  let orderQuarter = whatQuarterIsMonth(orderApprovedDate.getMonth());

  //console.log('this is my order Quarter   ---->', orderQuarter);

  //console.log('this is my quarterDAta --->', quarterData);

  //We will need to grab the year of the order
  if (!orderData.ordersTotals) {
    orderData.ordersTotals = {};
  }

  if (!orderData.ordersTotals[orderYear]) {
    orderData.ordersTotals[orderYear] = {};
  }

  //Then we will need to grab the quarter that the order falls in.

  //We will need to then grab that orders total and put it in the proper field

  if (orderData.totalTipCount) {
    totalTipCount.push(orderData.totalTipCount);
  }

  if (orderData.totalTips) {
    totalTips.push(orderData.totalTips);
  }

  if (orderData.totalBonusTips) {
    totalBonusTips.push(orderData.totalBonusTips);
  }

  if (!orderData.tips) {
    orderData.tips = {};
    orderData.tips[orderYear] = { tips: {}, bonus: {}, supplies: {}, promos: {} };
  }

  //If there is no supplies
  if (!orderData.tips[orderYear]) {
    orderData.tips[orderYear] = {};
    if (!orderData.tips[orderYear].supplies) {
      orderData.tips[orderYear].supplies = {};
    }
  }

  //lets go through the items
  //console.log('this is my order before I fail --->', order);
  if (order.tierID !== 'capitalOrder') {
    Object.keys(order.order.order).map((o) => {
      order.order.order[o].items.map((i) => {
        //We need to check to see if the item is not a tip

        if (i.item !== '') {
          if (order.order.selectedPromo) {
            totalTipCount.push(i.quantiy);

            if (!orderData.tips[orderYear].promos) {
              orderData.tips[orderYear].promos = {};
            }

            return (orderData.tips[orderYear].promos[i.item] = orderData.tips[orderYear].promos ? (orderData.tips[orderYear].promos[i.item] ? orderData.tips[orderYear].promos[i.item] + i.quantiy : i.quantiy) : i.quantiy);
          } else {
            // totalTipCount.push(i.quantiy);
            if (i.data.itemSupply || i.data.list === 0) {
              totalTipCount.push(i.quantiy);
              return (orderData.tips[orderYear].supplies[i.item] = orderData.tips[orderYear].supplies ? (orderData.tips[orderYear].supplies[i.item] ? orderData.tips[orderYear].supplies[i.item] + i.quantiy : i.quantiy) : i.quantiy);
            } else {
              totalTipCount.push(i.quantiy);
              totalTips.push(i.quantiy);
              //console.log('this is my order Data before tip count ---->', orderData.tips[quarterData.year]);
              //console.log('this is my order Data before tip count ---->', orderData.tips[quarterData.year].tips ? (orderData.tips[quarterData.year].tips[i.item] ? orderData.tips[quarterData.year].tips[i.item] + i.quantiy : i.quantiy) : i.quantiy);

              let tipCount = orderData.tips[orderYear].tips ? (orderData.tips[orderYear].tips[i.item] ? orderData.tips[orderYear].tips[i.item] + i.quantiy : i.quantiy) : i.quantiy;

              if (!orderData.tips[orderYear].tips) {
                orderData.tips[orderYear].tips = {};
              }

              return (orderData.tips[orderYear].tips[i.item] = tipCount);
            }
          }
        }
        return true;
      });
      if (order.order.order[o].bonus) {
        order.order.order[o].bonus.items.map((i) => {
          if (i.item !== '') {
            totalBonusTips.push(i.quantiy);
            //console.log('this is my order Data Bonus ---->', orderData.tips[orderYear]);
            if (!orderData.tips[orderYear].bonus) {
              orderData.tips[orderYear].bonus = {};
            }
            //console.log('this  is my item ---->', i);
            return (orderData.tips[orderYear].bonus[i.item] = orderData.tips[orderYear].bonus[i.item] ? orderData.tips[orderYear].bonus[i.item] + i.quantiy : i.quantiy);
          }
          return true;
        });
      }

      return true;
    });

    orderData.totalOrders = orderData.totalOrders ? orderData.totalOrders + order.order.totalOrder : order.order.totalOrder;

    //I will need to check to see if the quarter already exists
    orderData.ordersTotals[orderYear][orderQuarter] = orderData.ordersTotals ? (orderData.ordersTotals[orderYear] ? (orderData.ordersTotals[orderYear][orderQuarter] ? orderData.ordersTotals[orderYear][orderQuarter] + order.order.totalOrder : order.order.totalOrder) : order.order.totalOrder) : order.order.totalOrder;

    //Figure out what tiers the clinic is in

    orderTiers = orderData.orderTiers ? (orderData.orderTiers[orderYear] ? orderData.orderTiers[orderYear] : []) : [];

    if (orderData.orderTiers) {
      //console.log('this is my orderDate.orderTiers', order.order.orderTier);
      if (orderData.orderTiers[orderYear]) {
        if (!orderData.orderTiers[orderYear].includes(order.order.orderTier)) {
          orderTiers.push(order.order.orderTier ? order.order.orderTier : '');
        }
      } else {
        orderTiers.push(order.order.orderTier ? order.order.orderTier : '');
      }
    } else {
      orderTiers.push(order.order.orderTier ? order.order.orderTier : '');
    }
  } else {
    orderData.totalOrders = orderData.totalOrders ? orderData.totalOrders : 0;
    orderData.ordersTotals[orderYear][orderQuarter] = orderData.ordersTotals ? (orderData.ordersTotals[orderYear] ? (orderData.ordersTotals[orderYear][orderQuarter] ? orderData.ordersTotals[orderYear][orderQuarter] : 0) : 0) : 0;
  }

  //Figure out what programs they are in.
  let orderPrograms = orderData.orderPrograms ? (orderData.orderPrograms[orderYear] ? orderData.orderPrograms[orderYear] : []) : [];

  if (orderData.orderPrograms) {
    if (orderData.orderPrograms[orderYear]) {
      if (!orderData.orderPrograms[orderYear].includes(order.orderProgram)) {
        orderPrograms.push(order.orderProgram);
      }
    } else {
      orderPrograms.push(order.orderProgram);
    }
  } else {
    orderPrograms.push(order.orderProgram);
  }

  return {
    totalBonusTips: totalBonusTips.length > 0 ? totalBonusTips.reduce((a, b) => a + b) : 0,
    totalTips: totalTips.length > 0 ? totalTips.reduce((a, b) => a + b) : 0,
    totalTipCount: totalTipCount.length > 0 ? totalTipCount.reduce((a, b) => a + b) : 0,
    tips: orderData.tips,
    totalOrders: orderData.totalOrders,
    orderPrograms: orderPrograms,
    orderTiers: orderTiers,
    ordersTotals: orderData.ordersTotals,
  };
};

//This will be used to calculate tips for total orders on approve
const removeTipsFromAllOrdersGroup = (oldTotals, totalTipCount) => {
  let updatedTotals = { ...oldTotals };
  Object.keys(updatedTotals).map((year) => {
    return Object.keys(updatedTotals[year]).map((item) => {
      if (item !== 'bonus' && item !== 'supplies') {
        if (totalTipCount[year][item]) {
          updatedTotals[year][item] = oldTotals[year][item] - totalTipCount[year][item];
        }
      } else {
        Object.keys(updatedTotals[year][item]).map((bonus) => {
          if (totalTipCount[year][item][bonus]) {
            return (updatedTotals[year][item][bonus] = oldTotals[year][item][bonus] - totalTipCount[year][item][bonus]);
          }
          return true;
        });
      }
      return true;
    });
  });
  return updatedTotals;
};

const whatQuarterIsMonth = (month) => {
  return Math.floor((month + 3) / 3);
};

export const createNewCapitalOrder = (clinic, user) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    const today = new Date();

    firestore
      .collection('orders')
      .add({
        approved: {
          approvedBy: user.fullName,
          approvedByID: user.id,
          approvedOn: new Date(),
        },
        clinicID: clinic.id,
        clinicName: clinic.data.name,
        clinicTier: clinic.data.companyLevel,
        salesRep: clinic.data.rep ? clinic.data.rep : '',
        userID: user.id,
        userName: user.fullName,
        tier: 'Capital Order',
        tierID: 'capitalOrder',
        pricingLevel: 'capitalOrder',
        orderProgram: 'capitalOrder',
        orderProgramName: 'Capital Order',
        orderStarted: new Date(),
        lastEdited: new Date(),
        submitted: true,
        submittedDate: new Date(),
        submittedUserID: user.id,
        submittedUser: user.fullName,
        submittedUserEmail: user.email,
        name: 'Capital Order ' + today.toISOString().split('T')[0],
      })
      .then((ref) => {
        //console.log('this is my ref', ref);
        //Create an entrie in the company order listing
        console.log('this is my ref order', ref);

        const autoshipField = 'orders.' + ref.id;

        firestore
          .collection('companies')
          .doc(clinic.id)
          .update({
            [autoshipField]: { created: new Date(), clinic: clinic.data.name, orderProgram: 'Capital Order', clinicID: clinic.id, approved: false },
          })
          .then(() => {
            //Then add it to the users files
            firestore
              .collection('users')
              .doc(user.id)
              .update({
                [autoshipField]: { created: new Date(), clinic: clinic.data.name, clinicID: clinic.id, orderProgram: 'Capital Order', approved: false },
              })
              .then((resp) => {
                dispatch({ type: 'NEW_CAPITAL_ORDER_WAS_CREATED', resp });
              });
          });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_CREATING_ORDER_ORDER', err });
      });
  };
};
