const initState = {
  itemError: null,
  itemErrorPopup: false,
  itemMessage: null,
  itemPopUp: false,
  items: [],
  loadingitem: false,
};

const itemReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CLOSE_ITEM_POPUPS':
      return {
        ...state,
        itemMessage: null,
        itemPopUp: false,
        itemErrorPopup: false,
        itemError: null,
      };
    case 'ITEM_CREATED':
      return {
        ...state,
        itemPopUp: true,
        itemMessage: 'The item was created',
      };
    case 'FAILED_TO_CREATE_item':
      return {
        ...state,
        itemErrorPopup: true,
        itemError: action.err.message,
      };
    case 'ITEM_ALL_READY_MADE':
      return {
        ...state,
        itemErrorPopup: true,
        itemError: 'That item is already in the system',
      };
    default:
      return state;
  }
};

export default itemReducer;
