const initState = {
  companyError: null,
  companyMessage: null,
  companyLogo: "",
  loadedLogo: false,
  importCount: 0,
  loadedCompanies: [],
  loadingCompany: false,
  companyErrorPopup: false,
  companyMesPopup: false,
  companyModal: false,
  companyOwner: [],
  ownerLoaded: false,
  reloadCompany: false,
  companyUpdated: false,
  companyLoaded: false,
  report: null,
};

const companyReducer = (state = initState, action) => {
  switch (action.type) {
    case "NEW_COMPANY_CREATED":
      return {
        ...state,
        companyMessage: "Company has been created",
        companyMesPopup: true,
        companyModal: false,
      };
    case "NEW_COMPANY_IMPORTED":
      return {
        ...state,
        companyMesPopup: true,
        companyMessage: "Company has been imported",
        importCount: action.i,
      };
    case "ERROR_CREATING_NEW_COMPANY":
      return {
        ...state,
        companyError: action.err.message,
        companyMessage: "Error when creating the company",
        companyErrorPopup: true,
      };
    case "ERROR_SEARCHING_FOR_NEW_COMPANY":
      return {
        ...state,
        companyError: action.err.message,
        companyMessage: "Error when search for the company",
        companyErrorPopup: true,
      };
    case "COMPANY_LOGO_UPDATED":
      return {
        ...state,
        companyMessage: "Company Logo has been uploaded",
        companyMesPopup: true,
      };
    case "COMPANY_LOGO_FAILED_TO_UPLOAD":
      return {
        ...state,
        companyError: action.err.message,
        companyMessage: "Error uploadeding companies logo",
        companyErrorPopup: true,
      };
    case "LOGO_WAS_FOUND":
      //console.log('this is my action ', action)
      return {
        ...state,
        companyLogo: action.resp.url,
        loadedLogo: true,
      };
    case "ERROR_SEARCHING_FOR_COMPANY":
      return {
        ...state,
        companyError: action.err.message,
        companyErrorPopup: true,

        //companyMessage: "Error uploadeding companies logo",
      };
    case "NO_COMPANY_WAS_FOUND":
      return {
        ...state,
        companyError: "No company was found with that ID",
        companyErrorPopup: true,
      };
    case "COMPANY_FOUND":
      //console.log("this is my action company found", action);
      return {
        ...state,
        ownerLoaded: false,
        companyOwner: [],
        loadedCompanies: [...state.loadedCompanies, action.clinic],
        reloadCompany: false,
      };
    case "SINGLE_COMPANY_FOUND":
      return {
        ...state,
        ownerLoaded: false,
        companyOwner: [],
        loadedCompanies: [action.clinic],
        reloadCompany: false,
        companyLoaded: true,
      };
    case "UPDATING_COMPANY":
      return {
        ...state,
        loadingCompany: true,
      };
    case "REP_ADDED_TO_COMPANY":
      return {
        ...state,
        loadingCompany: false,
        companyMesPopup: true,
        companyMessage: "The rep has been added to the clinic",
        reloadCompany: true,
      };
    case "FAILED_TO_ADD_REP_TO_COMPANY":
      return {
        ...state,
        loadingCompany: false,
        companyError: action.err.message,
        companyErrorPopup: true,
      };
    case "COMPANY_CLOSE_POPUPS":
      return {
        ...state,
        companyError: null,
        companyErrorPopup: false,
        companyMesPopup: false,
        companyMessage: null,
        companyUpdated: false,
      };
    case "COMPANY_OPEN_MODAL":
      return {
        ...state,
        companyModal: true,
      };

    case "COMPANY_CLOSE_MODAL":
      return {
        ...state,
        companyModal: false,
      };
    case "COMPANY_REG_EMAIL_SENT":
      return {
        ...state,
        companyMesPopup: true,
        companyMessage: "Registration email has been sent",
      };
    case "ERROR_SENDING_COMPANY_REG_EMAIL":
      return {
        ...state,
        companyErrorPopup: true,
        companyError: action.err.message,
      };
    case "NO_USER_FOUND":
      return {
        ...state,
        companyError: "No owner was found",
        companyErrorPopup: true,
      };
    case "COMPANY_OWNER_FOUND":
      //console.log('this is my us')
      return {
        ...state,
        ownerLoaded: true,
        companyOwner: [...state.companyOwner, action.user],
      };
    case "ERROR_LOOKING_UP_COMPANY_OWNER":
      return {
        ...state,
        companyErrorPopup: true,
        companyError: action.err.message,
      };
    case "COMPANY_HAS_BEEN_UPDATED":
      return {
        ...state,
        companyMesPopup: true,
        companyMessage: "Company has been updated",
        reloadCompany: true,
        companyUpdated: true,
      };
    case "COMPANY_HAS_BEEN_REMOVED":
      return {
        ...state,
        companyMesPopup: true,
        companyMessage: "Company has been removed from the system",
      };
    case "ERROR_REMOVING_COMPANY":
      return {
        ...state,
        companyErrorPopup: true,
        companyError: action.err.message,
      };
    case "ERROR_UPLOADING_COMPANY_CERT":
      return {
        ...state,
        companyErrorPopup: true,
        companyError: action.err.message,
      };
    case "COMPANY_CERT_HAS_BEEN_ADDED":
      return {
        ...state,
        companyMesPopup: true,
        companyMessage: "Company Certifcate has been uploaded",
        reloadCompany: true,
      };
    case "CLINIC_WARRANTIES_WAS_UPDATED":
      return {
        ...state,
        companyMesPopup: true,
        companyMessage: "Companys warranties have been updated",
        reloadCompany: true,
        companyUpdated: true,
      };
    case "PROMO_ADS_HAVE_BEEN_UPLOADED":
      return {
        ...state,
        companyMesPopup: true,
        companyMessage: "Your ad has been uploaded",
        reloadCompany: true,
        companyUpdated: true,
        companyLoaded: false,
      };
    case "FAILED_TO_UPDATE_CLINIC_WARRANTIES":
      return {
        ...state,
        companyErrorPopup: true,
        companyError: action.err.message,
      };
    case "COMPANY_LOADED":
      return {
        ...state,
        reloadCompany: true,
        companyLoaded: false,
      };
    case 'REPORT_SUCCESSFUL':
      return{
        ...state,
        companyMesPopup: true,
        companyMessage: 'Warranty Report Created',
        report: action.midReport,
      };
    default:
      return state;
  }
};

export default companyReducer;
