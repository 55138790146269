const initState = {
  orderError: null,
  orderErrorPopup: false,
  orderMessage: null,
  orderMessagePopUp: false,
  orderCompany: null, //This is the clinic data ({id, data, pureTier, naturalTier})
  clinicLoaded: false, //Once a clinic is loaded
  orderID: null, //This is the id of the autoship order
  loadingAutoShipOrder: false, //This will show the order form if true else shows the clinic overview
  programElevate: false, //This handles if the clinic is going to elevate or not
  autoshipSettings: null, //This has all of the settins, starting month etc
  months: null, //This is the month data to load in the information
  autoshipSettingsLoaded: false, // This will be used to make sure the base settings are loaded
  items: null, //This will be where all of the items will be stored
  cbItems: [],
  fItems: [],
  tItems: [],
  cbmonths: [],
  tmonths: [],
  fmonths: [],
  cbMonthItems: [],
  tMonthItems: [],
  fMonthItems: [],
  monthTotals: [],
  monthlyCommitment: 0,
  minAutoshipCommitment: 0,
  minToKeep: 0,
  leftToFillAutoship: 0,
  lefttoEarnAutoshipPromo: 0,
  totalOrder: 0, // this is the total tally of the order
  editMonth: 0, // This is the month that will be editing the list DEFAULT IS FIRST MONTH
  editBrand: null, //This is the brand that will be brand that we are editing
  loadFreshAutoShipOrder: true, //This is used to check if we are loading a fresh order or data from saved one
  order: [], //This is what we will use to to store the order data
  pricingTier: null, //We will use this to set the pricing tier so we can use the correct data
  autoShipTiers: null, // WE will store all of the autoship tiers here
  savingOrder: false, //This is used for the icon and the user experince
  promoTips: null, //This is the number of tips that they could get
  autoShipCreatorUserID: null, //this is the user that created the autoship order
  orderName: '',
  orderChanged: false, //We are going to use this to save the order for autosave
  technologies: [],
  orderNotes: '',
  orderType: null, //This is where we are going to store the type of order
  orderBonus: false, //This is the variable that we will use to see if the order contains a bonus
  orderProgram: '', //This is the ID that we will use for the order program
  orderProgramName: '',
  orderTierID: '', //We will use this to keep track of the tiers
  orderBonusType: [], // We will store if it has a bonus type here for preview stuff
  loadingOrderCompanyID: '', //We are going to use this to handle swithcing between companies
  companyTotalOrders: [],
  nationalTotalOrders: [],
  loadingOrderID: '',
  nationalProgramOrder: false,
  approveOrder: {}, //We will send the approve order to this.
  openApproveDialog: false, //We will use this to open the approve dialog
  orderEmails: {},
  sendEmail: false,
  selectedPromos: [], //We will check to see if the orders has any promos and put them in here
  orderDiscount: false, // This will track if the order discount
  orderDiscountAmount: 0, //The amount that  the items will be discounted
  orderCheckedForPromo: false,
};

const autoShipReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CLOSE_ORDER_POPUPS':
      return {
        ...state,
        orderMessagePopUp: false,
        orderErrorPopup: false,
        orderError: null,
        orderMessage: null,
      };

    case 'CLEAN_ORDER_ORDER':
      return {
        ...state,
        clinicLoaded: false, //Once a clinic is loaded
        orderID: null, //This is the id of the autoship order
        loadingAutoShipOrder: false, //This will show the order form if true else shows the clinic overview
        autoshipSettingsLoaded: false, // This will be used to make sure the base settings are loaded
        items: null, //This will be where all of the items will be stored
        totalOrder: 0, // this is the total tally of the order
        loadFreshAutoShipOrder: true, //This is used to check if we are loading a fresh order or data from saved one
        order: [], //This is what we will use to to store the order data
        pricingTier: null, //We will use this to set the pricing tier so we can use the correct data
        savingOrder: false, //This is used for the icon and the user experince
        promoTips: null,
        nationalProgramOrder: false,
        orderBonus: false, //This is the variable that we will use to see if the order contains a bonus
        orderName: '',
        editBrandName: '',
        nationalTotalOrders: [],
        selectedPromos: [], //We will check to see if the orders has any promos and put them in here
        orderDiscount: false, // This will track if the order discount
        orderDiscountAmount: 0, //The amount that  the items will be discounted
        orderCheckedForPromo: false,
      };
    case 'ORDER_COMPANY_FOUND':
      //console.log('tis isi my pricing tier ', action.clinic.companyLevel);
      return {
        ...state,
        orderCompany: action.clinic,
        clinicLoaded: true,
        pricingTier: action.clinic.companyLevel,
        companyTotalOrders: action.clinic.totalSales,
        nationalTotalOrders: action.clinic.nationalSales ? action.clinic.nationalSales : [],
        loadingAutoShipOrder: false,
      };
    case 'ORDER_COMPANY_COULD_NOT_BE_FOUND':
      return {
        ...state,
        orderError: 'We are sorry that clinics information could not be found',
        orderErrorPopup: true,
      };
    case 'ERROR_FINDING_ORDER_COMPANY':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
      };
    case 'ERROR_CREATING_ORDER_ORDER':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
      };
    case 'NEW_ORDER_WAS_CREATED':
      let newOrderCompany = state.orderCompany;
      newOrderCompany.tier = action.resp.tier;
      //console.log('this is my resp', action.resp);
      return {
        ...state,
        orderCompany: newOrderCompany,
        orderID: action.resp.id,
        loadingAutoShipOrder: true,
        nationalProgramOrder: action.resp.natProgram,
        companyTotalOrders: action.resp.companyTotalOrders,
        nationalTotalOrders: action.resp.natProgram ? action.resp.nationalProgram : '',
      };

    case 'ELVATE_ORDER_ORDER':
      //We are going to need to cycle through the order and update all of the prices.
      const elvateOrder = state.order;
      const elvatedMonths = state.monthTotals;

      state.order.months.map((o, i) => {
        //Go through clear + Brilliant
        o['Clear + Brilliant'].map((ci, k) => {
          let newPrice = getItemPricing({ singleItem: ci.item, allItems: state.items, autoShipTiers: state.autoShipTiers, orderCompany: state.autoShipCompany, programElevate: state.programElevate ? false : true });
          elvateOrder.months[i]['Clear + Brilliant'][k].price = newPrice * elvateOrder.months[i]['Clear + Brilliant'][k].quantiy;

          let monthTotal = getTotalNumberofSpend({ orderGroup: elvateOrder.months[i]['Clear + Brilliant'] });
          return (elvateOrder.months[i].total = { ...elvateOrder.months[i].total, 'Clear + Brilliant': monthTotal });
        });
        //Go through Thermage
        o['Thermage'].map((ti, k) => {
          let newPrice = getItemPricing({ singleItem: ti.item, allItems: state.items, autoShipTiers: state.autoShipTiers, orderCompany: state.autoShipCompany, programElevate: state.programElevate ? false : true });
          elvateOrder.months[i]['Thermage'][k].price = newPrice * elvateOrder.months[i]['Thermage'][k].quantiy;

          let monthTotal = getTotalNumberofSpend({ orderGroup: elvateOrder.months[i]['Thermage'] });
          return (elvateOrder.months[i].total = { ...elvateOrder.months[i].total, Thermage: monthTotal });
        });
        //Go through Fraxel
        o['Fraxel'].map((fi, k) => {
          let newPrice = getItemPricing({ singleItem: fi.item, allItems: state.items, autoShipTiers: state.autoShipTiers, orderCompany: state.autoShipCompany, programElevate: state.programElevate ? false : true });
          elvateOrder.months[i]['Fraxel'][k].price = newPrice * elvateOrder.months[i]['Fraxel'][k].quantiy;

          let monthTotal = getTotalNumberofSpend({ orderGroup: elvateOrder.months[i]['Fraxel'] });
          return (elvateOrder.months[i].total = { ...elvateOrder.months[i].total, Fraxel: monthTotal });
        });

        let monthTotalA = getMonthTotal({ orderGroup: elvateOrder.months[i] });
        elvatedMonths[i].total = monthTotalA;

        return null;
      });

      let elvatedTotal = totalAmount({ orderGroup: elvatedMonths });
      //console.log('this is my new elveated order', elvateOrder);
      let companyTier = state.programElevate ? state.autoShipCompany.pureTier.tierName : state.autoShipCompany.pureTier.tierElevate;
      let elvatedTier = state.autoShipTiers.filter((t) => t.data.tierName === companyTier);

      //console.log('this is my elevatedTier', elvatedTier);
      //console.log('these are my autoshipTiers', state.autoShipTiers);
      //console.log('this is my company Tier', companyTier[0]);

      return {
        ...state,
        monthlyCommitment: state.programElevate ? Math.round(state.autoShipCompany.pureTier.tierMaintainMin / 6) : Math.round(state.autoShipCompany.pureTier.tierElevateMin / 6),
        minAutoshipCommitment: state.programElevate ? state.autoShipCompany.pureTier.tierMaintainMin : state.autoShipCompany.pureTier.tierElevateMin,
        minToKeep: state.programElevate ? state.autoShipCompany.pureTier.tierMaintainTrueUp : state.autoShipCompany.pureTier.tierElevateTrueup,
        leftToFillAutoship: state.programElevate ? state.autoShipCompany.pureTier.tierMaintainMin : state.autoShipCompany.pureTier.tierElevateMin,
        lefttoEarnAutoshipPromo: state.programElevate ? state.autoShipCompany.pureTier.tierMaintainTrueUp : state.autoShipCompany.pureTier.tierElevateTrueup,
        programElevate: state.programElevate ? false : true,
        order: elvateOrder,
        monthTotals: [...elvatedMonths],
        totalOrder: elvatedTotal,
        pricingTier: elvatedTier[0].id,
        promoTips: state.programElevate ? state.autoShipCompany.pureTier.promoBonus : state.autoShipCompany.pureTier.elevatedPromoBonus,
        orderChanged: true,
      };

    case 'ORDER_SETTINGS_LOADED':
      //We are going to want to do some things before we get things going
      //Figure out based on the settings we only want six months
      //We might want to load in the all of the items and have them sorted by brand

      //Get the starting month
      const allItems = action.resp.items;
      const allTechnologies = action.resp.tech;
      //console.log('this is my orderCompany from orderSetings', state.orderCompany.data.technologies[0]);
      //const cbItemsSorted = [];

      //Create the total order setup
      const order = {};
      const sortedItems = {};

      const companyTech = [];

      allTechnologies.map((tech) => {
        sortedItems[tech.id] = [];
        return (order[tech.id] = { items: [], itemCount: 0, total: 0, amount: 3 });
      });

      //WE are going to sort the brands into thier own item lists.

      allTechnologies.map((tech) => {
        if (state.orderCompany.data.technologies.includes(tech.id)) {
          companyTech.push({ ...tech });
          allItems.map((item) => {
            if (item.data.techID === tech.id) {
              //If they are in a national program they will not have a tier
              if (state.orderCompany.data.nationalProgram) {
                sortedItems[tech.id].push(item); //For now push all items
              } else {
                if (state.orderCompany.tier.timeFrame === 0) {
                  sortedItems[tech.id].push(item);
                } else {
                  sortedItems[tech.id].push(item); //For now push all items
                  /*//If the item is a supply don't add it
                  if (!item.data.itemSupply) {
                    sortedItems[tech.id].push(item);
                  }
                  */
                }
              }
            }

            return true;
          });
        }

        return true;
      });

      Object.keys(sortedItems).map((techID) => {
        //let sorted = sortedItems[techID].sort((a, b) => b.data.sku.localeCompare(a.data.sku));
        let sorted = sortedItems[techID].sort((a, b) => a.data.listOrder - b.data.listOrder);
        return (sortedItems[techID] = sorted);
      });

      //console.log('this is my sorted Items ---> ', sortedItems);

      //Lets sort the items so that they are in order
      //const cbItemsSorted = cbItems.sort((a, b) => a.data.listOrder - b.data.listOrder);
      //const tItemsSorted = tItems.sort((a, b) => a.data.listOrder - b.data.listOrder);
      //const fItemsSorted = fItems.sort((a, b) => a.data.listOrder - b.data.listOrder);
      //console.log('this is my items ', tItemsSorted);
      //console.log('this is my order Company Data --->', state.orderCompany.data);
      //console.log('this is setting me edit brand???? ', companyTech[0].id);
      return {
        ...state,
        autoshipSettingsLoaded: true,
        items: sortedItems,
        order: { ...order },
        totalOrder: 0,
        orderNotes: '',
        pricingTier: state.orderCompany
          ? state.orderCompany.data.nationalProgram
            ? state.orderCompany.data.nationalProgramName === 'National Meta Studios Account' || state.orderCompany.data.nationalProgramName === 'National Account Dermapure'
              ? 'platinum'
              : 'Diamond'
            : state.orderCompany.tier.pricingLevel
            ? state.orderCompany.tier.pricingLevel
            : state.orderCompany.tier.tierName
          : '',
        technologies: [...companyTech],
        editBrand: companyTech[0].id,
        editBrandName: companyTech[0].data.name,
      };
    case 'ORDER_SETTINGS_LIGHT_LOADED':
      //We are going to want to do some things before we get things going
      //Figure out based on the settings we only want six months
      //We might want to load in the all of the items and have them sorted by brand
      const lightItems = action.resp.items;
      const lightTech = action.resp.tech;
      //Set all of the default values

      const lightSortedItems = {};

      lightTech.map((tech) => {
        return (lightSortedItems[tech.id] = []);
      });

      let lightCompanyTech = [];

      lightTech.map((tech) => {
        if (state.orderCompany.data.technologies.includes(tech.id)) {
          lightCompanyTech.push({ ...tech });
          lightItems.map((item) => {
            if (item.data.techID === tech.id) {
              if (state.orderCompany.data.nationalProgram) {
                lightSortedItems[tech.id].push(item); //For now push all items
              } else {
                if (state.orderCompany.tier.timeFrame === 0) {
                  lightSortedItems[tech.id].push(item);
                } else {
                  lightSortedItems[tech.id].push(item); //For now push all items
                  /*//If the item is a supply don't add it
                  if (!item.data.itemSupply) {
                    sortedItems[tech.id].push(item);
                  }
                  */
                }
              }
            }

            return true;
          });
        }

        return true;
      });

      Object.keys(lightSortedItems).map((techID) => {
        //let sorted = lightSortedItems[techID].sort((a, b) => b.data.sku.localeCompare(a.data.sku));
        let sorted = lightSortedItems[techID].sort((a, b) => a.data.listOrder - b.data.listOrder);
        return (lightSortedItems[techID] = sorted);
      });

      //console.log('this is ymy eit brand???', lightTech[0].id);
      //console.log('this is my lighttecc', lightCompanyTech);
      return {
        ...state,
        autoshipSettingsLoaded: true,
        items: lightSortedItems,
        pricingTier: state.orderCompany.data.nationalProgram ? (state.orderCompany.data.nationalProgramName === 'National Meta Studios Account' || state.orderCompany.data.nationalProgramName === 'National Account Dermapure' ? 'platinum' : 'Diamond') : state.orderCompany.tier.pricingLevel ? state.orderCompany.tier.pricingLevel : state.orderCompany.tier.tierName,
        technologies: [...lightCompanyTech],
        editBrand: lightCompanyTech[0].id,
        editBrandName: lightCompanyTech[0].data.name,
      };

    case 'ERROR_NO_MONTHS_FOUND':
      return {
        ...state,
        orderError: 'Sorry, there seems to be no month data loaded',
        orderErrorPopup: true,
      };
    case 'ERROR_GETTING_MONTHS':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
      };
    case 'ORDER_NO_ITEMS':
      return {
        ...state,
        orderError: 'Sorry, there seems to be no items loaded',
        orderErrorPopup: true,
      };
    case 'SELECTING_MONTHING_TO_EDIT':
      return {
        ...state,
        editMonth: action.month,
      };
    case 'SELECTING_BRAND_TO_EDIT':
      return {
        ...state,
        editBrand: action.brand,
        editBrandName: action.brandName,
      };
    case 'ADD_ITEM_TO_ORDER':
      const updatedOrder = state.order;
      const currentMonthTotal = state.monthTotals;

      const newItem = action.resp;
      let itemPricing = state.items[newItem.techID].filter((item) => item.data.sku === newItem.item);
      let newPrice = getItemPricing({ singleItem: itemPricing[0], orderCompany: state.orderCompany });

      updatedOrder[newItem.techID].items[newItem.row] = { item: newItem.item, quantiy: itemPricing.length > 0 ? 1 : 0, data: itemPricing.length > 0 ? itemPricing[0].data : 0, price: newPrice };

      //Calculate the total number of items
      let updatedItemNumber = getTotalNumberofItems({ orderGroup: updatedOrder[newItem.techID] });
      updatedOrder[newItem.techID].itemCount = updatedItemNumber;

      //Calculate the TOTAL for the Month
      let itemTotal = getTotalNumberofSpend({ orderGroup: updatedOrder[newItem.techID].items });
      updatedOrder[newItem.techID].total = itemTotal;

      //Calculate the TOTAL COSTS OVERALL
      let totalCost = totalAmount({ orderGroup: updatedOrder });

      //console.log('this is my total cost ??????????????', totalCost);

      return {
        ...state,
        order: { ...updatedOrder },
        monthTotals: [...currentMonthTotal],
        totalOrder: totalCost,
        orderChanged: true,
      };

    case 'CUSTOM_PRICE_ADJUSTMENT':
      const customOrder = state.order;

      const newPricing = action.resp;

      //console.log('this is my new pricing i am getting and I will have to adjust', newPricing);

      //Update the rows price

      if (customOrder[newPricing.techID].items) {
        if (customOrder[newPricing.techID].items[newPricing.row]) {
          customOrder[newPricing.techID].items[newPricing.row].price = parseInt(newPricing.price);

          //Calculate the TOTAL for the Month
          let customItemTotal = getTotalNumberofSpend({ orderGroup: customOrder[newPricing.techID].items });
          customOrder[newPricing.techID].total = customItemTotal;

          //Calculate the TOTAL COSTS OVERALL
          let customTotalCost = totalAmount({ orderGroup: customOrder });

          //console.log('this is my total custom total cost?????????????????????', customTotalCost);

          return {
            ...state,
            order: { ...customOrder },
            totalOrder: customTotalCost,
            orderChanged: true,
          };
        } else {
          return { ...state };
        }
      } else {
        return { ...state };
      }

    case 'ADD_BONUS_ITEM_TO_ORDER':
      const updatedBOrder = state.order;

      const newBItem = action.resp;
      //console.log('this is my new bonus item', newBItem);
      let itemBPricing = state.items[newBItem.techID].filter((item) => item.data.sku === newBItem.item);
      let newBPrice = 'FREE';

      updatedBOrder[newBItem.techID].bonus.items[newBItem.row] = { item: newBItem.item, quantiy: 1, data: itemBPricing.length > 0 ? itemBPricing[0].data : 0, price: newBPrice, type: '' };
      updatedBOrder[newBItem.techID].bonus.items[newBItem.row].type = 'carStock'; //This will be the default option
      //Calculate the total number of items
      let updatedBItemNumber = getTotalNumberofItems({ orderGroup: updatedBOrder[newBItem.techID] });
      updatedBOrder[newBItem.techID].itemCount = updatedBItemNumber;

      //Calculate the TOTAL for the Month
      //updatedBOrder[newBItem.techID].total = 0;

      return {
        ...state,
        order: { ...updatedBOrder },
        orderChanged: true,
        orderBonus: true,
      };

    case 'ADD_BONUS_TYPE_TO_ITEM':
      const updatedBTOrder = state.order;

      const newBTItem = action.resp;
      //console.log('this is my new bonus item', newBItem);

      updatedBTOrder[newBTItem.techID].bonus.items[newBTItem.row].type = newBTItem.type;

      return {
        ...state,
        order: { ...updatedBTOrder },
        orderChanged: true,
        orderBonus: true,
        orderBonusType: [...state.orderBonusType, newBTItem.type],
      };

    case 'ADD_DISCOUNT_ITEM_TO_ORDER':
      //console.log('lets add a discounted item !!!!!');

      const updatedDOrder = state.order;

      const newDiscountItem = action.resp;
      //console.log('this is my new bonus item', newBItem);
      let itemDiscountPricing = state.items[newDiscountItem.techID].filter((item) => item.data.sku === newDiscountItem.item);
      let newDiscountPriceItem = getItemPricing({ singleItem: itemDiscountPricing[0], orderCompany: state.orderCompany });

      let discountPrice = newDiscountPriceItem - (newDiscountPriceItem * state.orderDiscountAmount) / 100;
      let discountSaving = (newDiscountPriceItem * state.orderDiscountAmount) / 100;

      updatedDOrder[newDiscountItem.techID].discount.items[newDiscountItem.row] = { item: newDiscountItem.item, quantiy: 1, data: itemDiscountPricing.length > 0 ? itemDiscountPricing[0].data : 0, price: discountPrice, type: '' };
      updatedDOrder[newDiscountItem.techID].discount.items[newDiscountItem.row].discount = state.orderDiscountAmount; //this is the percentage we are saving
      updatedDOrder[newDiscountItem.techID].discount.items[newDiscountItem.row].saving = discountSaving; //This will be the default option

      //Calculate the total number of items
      //console.log('this is all of my items ----> ', updatedDOrder[newDiscountItem.techID]);
      let updatedDItemNumber = getTotalNumberofItems({ orderGroup: updatedDOrder[newDiscountItem.techID] });
      updatedDOrder[newDiscountItem.techID].discount.itemCount = updatedDItemNumber;

      //Calculate the TOTAL for the item
      let itemDTotal = getTotalNumberofSpend({ orderGroup: updatedDOrder[newDiscountItem.techID].discount.items });
      updatedDOrder[newDiscountItem.techID].discount.total = itemDTotal;

      //Calculate the TOTAL COSTS OVERALL
      let totalDCost = totalAmount({ orderGroup: updatedDOrder });

      //console.log('this is my total D Cost ------>', totalDCost);

      return {
        ...state,
        order: { ...updatedDOrder },
        orderChanged: true,
        totalOrder: totalDCost,
      };

    case 'ADJUST_ORDER_NOTES':
      return {
        ...state,
        orderNotes: action.notes,
      };

    case 'ADD_QUANTITY_TO_ITEM_ORDER':
      let updatedQuantiyOrder = state.order;
      let currentMonthTotalQ = state.monthTotals;
      let newQuantityItem = action.resp;

      let newQuantity = action.resp.adjust === 'add' ? updatedQuantiyOrder[newQuantityItem.techID].items[newQuantityItem.row].quantiy + 1 : updatedQuantiyOrder[newQuantityItem.techID].items[newQuantityItem.row].quantiy - 1;
      let newQuantityPrice = getItemPricing({ singleItem: updatedQuantiyOrder[newQuantityItem.techID].items[newQuantityItem.row], orderCompany: state.orderCompany }) * newQuantity;

      if (newQuantity === 0) {
        updatedQuantiyOrder[newQuantityItem.techID].items[newQuantityItem.row].item = '';
        updatedQuantiyOrder[newQuantityItem.techID].items.splice([newQuantityItem.row], 1);

        //Update the groups total count of items
        let updatedItemCount = getTotalNumberofItems({ orderGroup: updatedQuantiyOrder[newQuantityItem.techID] });
        updatedQuantiyOrder[newQuantityItem.techID].itemCount = updatedItemCount;
        //console.log('this is my updated Item Count', updatedItemCount);

        let itemTotalQ = getTotalNumberofSpend({ orderGroup: updatedQuantiyOrder[newQuantityItem.techID].items });
        updatedQuantiyOrder[newQuantityItem.techID].total = itemTotalQ;

        let totalCostQ = totalAmount({ orderGroup: updatedQuantiyOrder });

        //console.log('this is my total Cost Q ---------->', totalCostQ);

        return {
          ...state,
          order: { ...updatedQuantiyOrder },
          monthTotals: [...currentMonthTotalQ],
          totalOrder: totalCostQ,
          orderChanged: true,
        };
      } else {
        updatedQuantiyOrder[newQuantityItem.techID].items[newQuantityItem.row].quantiy = newQuantity;
        updatedQuantiyOrder[newQuantityItem.techID].items[newQuantityItem.row].price = newQuantityPrice;

        //Update the groups total count of items
        let updatedItemCount = getTotalNumberofItems({ orderGroup: updatedQuantiyOrder[newQuantityItem.techID] });
        updatedQuantiyOrder[newQuantityItem.techID].itemCount = updatedItemCount;
        //console.log('this is my updated Item Count', updatedItemCount);

        let itemTotalQ = getTotalNumberofSpend({ orderGroup: updatedQuantiyOrder[newQuantityItem.techID].items });
        updatedQuantiyOrder[newQuantityItem.techID].total = itemTotalQ;

        let totalCostQ = totalAmount({ orderGroup: updatedQuantiyOrder });

        //console.log('titalcostq --->', totalCostQ);

        return {
          ...state,
          order: { ...updatedQuantiyOrder },
          monthTotals: [...currentMonthTotalQ],
          totalOrder: totalCostQ,
          orderChanged: true,
        };
      }

    case 'ADD_BONUS_QUANTITY_TO_ITEM_ORDER':
      let updatedBQuantiyOrder = { ...state.order };

      let newBQuantityItem = action.resp;

      let newBQuantity = action.resp.adjust === 'add' ? updatedBQuantiyOrder[newBQuantityItem.techID].bonus.items[newBQuantityItem.row].quantiy + 1 : updatedBQuantiyOrder[newBQuantityItem.techID].bonus.items[newBQuantityItem.row].quantiy - 1;

      if (newBQuantity === 0) {
        let newBonusItms = updatedBQuantiyOrder[newBQuantityItem.techID].bonus.items.filter((item, x) => {
          if (x !== newBQuantityItem.row) {
            return item;
          } else {
            return false;
          }
        });

        updatedBQuantiyOrder[newBQuantityItem.techID].bonus.items = [...newBonusItms];
        updatedBQuantiyOrder[newBQuantityItem.techID].bonus.amount = state.order[newBQuantityItem.techID].bonus.amount - 1;
      } else {
        updatedBQuantiyOrder[newBQuantityItem.techID].bonus.items[newBQuantityItem.row].quantiy = newBQuantity;
      }

      //Update the groups total count of items
      let updatedBItemCount = getTotalNumberofItems({ orderGroup: updatedBQuantiyOrder[newBQuantityItem.techID] });
      updatedBQuantiyOrder[newBQuantityItem.techID].itemCount = updatedBItemCount;
      //console.log('this is my updated Item Count', updatedItemCount);

      //let itemBTotalQ = getTotalNumberofSpend({ orderGroup: updatedBQuantiyOrder[newBQuantityItem.techID].items });
      //updatedBQuantiyOrder[newBQuantityItem.techID].total = itemBTotalQ;

      //let totalCostQ = totalAmount({ orderGroup: updatedQuantiyOrder });

      return {
        ...state,
        order: { ...updatedBQuantiyOrder },
        //monthTotals: [...currentMonthTotalQ],
        //totalOrder: totalCostQ,
        orderChanged: true,
      };

    case 'ADD_DISCOUNT_QUANTITY_TO_ITEM_ORDER':
      let updatedDisQuantiyOrder = state.order; //updatedDisQuantiyOrder

      let newDisQuantityItem = action.resp; //newDisQuantityItem

      const singleDisItem = updatedDisQuantiyOrder[newDisQuantityItem.techID].discount.items[newDisQuantityItem.row];

      let newDisQuantity = action.resp.adjust === 'add' ? singleDisItem.quantiy + 1 : singleDisItem.quantiy - 1;
      let newItemActualPrice = getItemPricing({ singleItem: singleDisItem, orderCompany: state.orderCompany });

      let newDisQuantityPrice = (newItemActualPrice - (newItemActualPrice * state.orderDiscountAmount) / 100) * newDisQuantity;
      let newDisQuantitySavings = ((newItemActualPrice * state.orderDiscountAmount) / 100) * newDisQuantity;

      console.log('this is my quantiy ---->', newDisQuantity);

      console.log('this is my new total price and savings ----> ', newDisQuantityPrice, newDisQuantitySavings);

      if (newDisQuantity === 0) {
        singleDisItem.item = '';
        updatedDisQuantiyOrder[newDisQuantityItem.techID].discount.items.splice([newDisQuantityItem.row], 1);

        //Update the groups total count of items
        let updatedItemCount = getTotalNumberofItems({ orderGroup: updatedDisQuantiyOrder[newDisQuantityItem.techID] });
        updatedDisQuantiyOrder[newDisQuantityItem.techID].discount.itemCount = updatedItemCount;
        //console.log('this is my updated Item Count', updatedItemCount);

        let itemTotalQ = getTotalNumberofSpend({ orderGroup: updatedDisQuantiyOrder[newDisQuantityItem.techID].discount.items });
        updatedDisQuantiyOrder[newDisQuantityItem.techID].discount.total = itemTotalQ;

        let totalCostQ = totalAmount({ orderGroup: updatedDisQuantiyOrder });

        //console.log('this is my total cost q --->', totalCostQ);

        return {
          ...state,
          order: { ...updatedDisQuantiyOrder },
          totalOrder: totalCostQ,
          orderChanged: true,
        };
      } else {
        singleDisItem.quantiy = newDisQuantity;
        singleDisItem.price = newDisQuantityPrice;
        singleDisItem.saving = newDisQuantitySavings;

        console.log('this is my pricing and savings ---->', newDisQuantityPrice, newDisQuantitySavings);

        //Update the groups total count of items
        let updatedItemCount = getTotalNumberofItems({ orderGroup: updatedDisQuantiyOrder[newDisQuantityItem.techID].discount });
        updatedDisQuantiyOrder[newDisQuantityItem.techID].discount.itemCount = updatedItemCount;
        //console.log('this is my updated Item Count', updatedItemCount);

        let itemTotalQ = getTotalNumberofSpend({ orderGroup: updatedDisQuantiyOrder[newDisQuantityItem.techID].discount.items });
        updatedDisQuantiyOrder[newDisQuantityItem.techID].discount.total = itemTotalQ;

        let totalCostQ = totalAmount({ orderGroup: updatedDisQuantiyOrder });

        //console.log('this is my total cost q ----?>', totalCostQ);

        return {
          ...state,
          order: { ...updatedDisQuantiyOrder },
          totalOrder: totalCostQ,
          orderChanged: true,
        };
      }

    case 'ERROR_SEARCHING_FOR_ORDER_ORDER':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
      };
    case 'NO_ORDER_ORDER_FOUND':
      return {
        ...state,
        orderError: 'Sorry there is no order with that id',
        orderErrorPopup: true,
      };

    case 'ORDER_ORDER_FOUND': //This will be used to load the autoship data
      const autoshipOrder = action.resp;
      const orderCompanyO = state.orderCompany;

      console.log('this is my order company', orderCompanyO);
      console.log('this is my autoship Rtoder ---', autoshipOrder);
      orderCompanyO.tier = action.resp.tier;
      //If we have an order in the saved data then use that
      if (autoshipOrder.data.order) {
        //console.log('this is my promoTip Cal', promoTipCal);

        //console.log('this is my total order -----> ', autoshipOrder.data.order.totalOrder);

        return {
          ...state,
          order: { ...autoshipOrder.data.order.order },
          loadFreshAutoShipOrder: false,
          orderID: autoshipOrder.id,
          loadingAutoShipOrder: true,
          orderCompany: orderCompanyO,
          orderNotes: autoshipOrder.data.order.orderNotes,
          totalOrder: autoshipOrder.data.order.totalOrder,
          orderName: autoshipOrder.data.name ? autoshipOrder.data.name : '',
          orderProgram: autoshipOrder.data.orderProgram,
          orderProgramName: autoshipOrder.data.orderProgramName,
          nationalProgramOrder: autoshipOrder.data.nationalProgramOrder ? autoshipOrder.data.nationalProgramOrder : false,
          orderDiscount: autoshipOrder.data.order.orderDiscount ? autoshipOrder.data.order.orderDiscount : false,
          orderDiscountAmount: autoshipOrder.data.order.orderDiscountAmount ? autoshipOrder.data.order.orderDiscountAmount : 0,
          selectedPromos: autoshipOrder.data.selectedPromos ? [...autoshipOrder.data.selectedPromos] : [],
        };
      } else {
        return {
          ...state,
          orderID: autoshipOrder.id,
          loadingAutoShipOrder: true,
          orderCompany: orderCompanyO,
          orderName: autoshipOrder.data.name ? autoshipOrder.data.name : '',
          orderProgram: autoshipOrder.data.orderProgram,
          orderProgramName: autoshipOrder.data.orderProgramName,
          nationalProgramOrder: autoshipOrder.data.nationalProgramOrder ? autoshipOrder.data.nationalProgramOrder : false,
          selectedPromos: autoshipOrder.data.selectedPromos ? [...autoshipOrder.data.selectedPromos] : [],
          orderDiscount: autoshipOrder.data.order.orderDiscount ? autoshipOrder.data.order.orderDiscount : false,
          orderDiscountAmount: autoshipOrder.data.order.orderDiscountAmount ? autoshipOrder.data.order.orderDiscountAmount : 0,
        };
      }

    case 'ORDER_ORDER_AND_CLINIC_FOUND': //This will be used to load the autoship data and clinic
      //console.log(action.clinic);
      const autoshipOrderC = action.resp;

      //console.log('this is my order ---------> ', autoshipOrderC.data);
      //console.log('this is my state ------?', state);

      if (autoshipOrderC.data.order) {
        console.log('we hae orders ???????');

        console.log('this is my total order from import? ---->', autoshipOrderC.data.order.totalOrder);

        return {
          ...state,
          order: { ...autoshipOrderC.data.order.order },
          orderBonus: autoshipOrderC.data.order.orderBonus ? autoshipOrderC.data.order.orderBonus : false,
          totalOrder: autoshipOrderC.data.order.totalOrder,
          orderID: action.resp.id,
          loadingAutoShipOrder: true,
          orderCompany: action.resp.clinic,
          clinicLoaded: true,
          pricingTier: autoshipOrderC.data.nationalProgramOrder ? autoshipOrderC.data.tier : action.resp.clinic.tier.pricingLevel ? action.resp.clinic.tier.pricingLevel : action.resp.clinic.tier.tierName,
          loadFreshAutoShipOrder: false,
          autoShipCreatorUserID: autoshipOrderC.data.userID,
          orderName: autoshipOrderC.data.name ? autoshipOrderC.data.name : '',
          orderNotes: autoshipOrderC.data.order.orderNotes ? autoshipOrderC.data.order.orderNotes : '',
          orderProgram: autoshipOrderC.data.orderProgram,
          orderProgramName: autoshipOrderC.data.orderProgramName,
          orderTierID: autoshipOrderC.data.tierID,
          companyTotalOrders: action.resp.clinic.totalSales,
          nationalTotalOrders: action.resp.clinic.nationalSales ? action.resp.clinic.nationalSales : [],
          loadingOrderCompanyID: action.resp.clinic.id,
          loadingOrderID: action.resp.id,
          nationalProgramOrder: autoshipOrderC.data.nationalProgramOrder ? autoshipOrderC.data.nationalProgramOrder : false,
          selectedPromos: autoshipOrderC.data.selectedPromos ? [...autoshipOrderC.data.selectedPromos] : [],
          orderDiscount: autoshipOrderC.data.orderDiscount ? autoshipOrderC.data.orderDiscount : false,
          orderDiscountAmount: autoshipOrderC.data.orderDiscountAmount ? autoshipOrderC.data.orderDiscountAmount : 0,
          orderCheckedForPromo: autoshipOrderC.data.promotionAsked ?? false,
        };
      } else {
        console.log('we do not have orders');
        return {
          ...state,
          orderID: action.resp.id,
          loadingAutoShipOrder: true,
          orderCompany: action.resp.clinic,
          clinicLoaded: true,
          pricingTier: autoshipOrderC.data.nationalProgramOrder ? (state.orderCompany.data.nationalProgramName === 'National Meta Studios Account' || state.orderCompany.data.nationalProgramName === 'National Account Dermapure' ? 'platinum' : 'diamond') : action.resp.clinic.tier.pricingLevel ? action.resp.clinic.tier.pricingLevel : action.resp.clinic.tier.tierName,
          autoShipCreatorUserID: autoshipOrderC.data.userID,
          orderName: autoshipOrderC.data.name ? autoshipOrderC.data.name : '',
          orderProgram: autoshipOrderC.data.orderProgram,
          orderProgramName: autoshipOrderC.data.orderProgramName,
          orderTierID: autoshipOrderC.data.tierID,
          companyTotalOrders: action.resp.clinic.totalSales,
          nationalTotalOrders: action.resp.clinic.nationalSales ? action.resp.clinic.nationalSales : [],
          loadingOrderCompanyID: action.resp.clinic.id,
          loadingOrderID: action.resp.id,
          nationalProgramOrder: autoshipOrderC.data.nationalProgramOrder ? autoshipOrderC.data.nationalProgramOrder : false,
          orderCheckedForPromo: autoshipOrderC.data.promotionAsked ?? false,
          orderDiscount: autoshipOrderC.data.orderDiscount ? autoshipOrderC.data.orderDiscount : false,
          orderDiscountAmount: autoshipOrderC.data.orderDiscountAmount ? autoshipOrderC.data.orderDiscountAmount : 0,
          selectedPromos: autoshipOrderC.data.selectedPromos ? [...autoshipOrderC.data.selectedPromos] : [],
        };
      }

    case 'STARTING_SAVING_ORDER':
      return {
        ...state,
        savingOrder: true,
      };

    case 'ORDER_ORDER_WAS_SAVED':
      return {
        ...state,
        savingOrder: false,
        orderMessage: 'Your order was saved',
        orderMessagePopUp: true,
        orderChanged: false,
      };
    case 'ERROR_SAVING_ORDER_ORDER':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
      };

    case 'COPY_MONTH_ORDER':
      const monthCopy = state.order.months[action.month];
      const copyCB = monthCopy['Clear + Brilliant'];
      const copyThermage = monthCopy['Thermage'];
      const copyFraxel = monthCopy['Fraxel'];
      const copyItemCount = monthCopy.itemCount;
      const copyTotal = monthCopy.total;
      const copyOrder = state.order;

      const monthTotalCopy = state.monthTotals[action.month];
      const copyMonthTotals = state.monthTotals;

      state.order.months.map((o, i) => {
        copyOrder.months[i]['Clear + Brilliant'] = copyCB;
        copyOrder.months[i]['Thermage'] = copyThermage;
        copyOrder.months[i]['Fraxel'] = copyFraxel;
        copyOrder.months[i].itemCount = copyItemCount;
        copyOrder.months[i].total = copyTotal;
        return null;
      });

      state.monthTotals.map((t, i) => {
        return (copyMonthTotals[i].total = monthTotalCopy.total);
      });

      let copyTotalOrder = totalAmount({ orderGroup: copyMonthTotals });

      //console.log('this is my new copyTotalOrder', copyTotalOrder);

      return {
        ...state,
        order: { ...copyOrder },
        monthTotals: [...copyMonthTotals],
        totalOrder: copyTotalOrder,
        orderChanged: true,
      };

    case 'EXIT_ORDER_ORDER':
      return {
        ...state,
        loadingAutoShipOrder: false,
      };

    case 'SUBMIT_ORDER_ORDER':
      return {
        ...state,
        loadingAutoShipOrder: false,
        orderMessagePopUp: true,
        orderMessage: 'Your order has been submitted',
      };
    case 'ERROR_SUBMITTING_ORDER_ORDER':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
      };

    case 'ADD_ANOTHER_ITEM_TO_MONTH':
      //We will need to find the item in the charts items

      let anotherOrder = state.order;

      anotherOrder[action.resp.techID].amount = state.order[action.resp.techID].amount + 1;
      return {
        ...state,
        order: { ...anotherOrder },
      };

    case 'ADD_ANOTHER_BONUS_ITEM':
      //We will need to find the item in the charts items

      let anotherBOrder = state.order;

      anotherBOrder[action.resp.techID].bonus = { items: state.order[action.resp.techID].bonus ? (state.order[action.resp.techID].bonus.items ? [...state.order[action.resp.techID].bonus.items] : []) : [], amount: state.order[action.resp.techID].bonus ? (state.order[action.resp.techID].bonus.amount ? state.order[action.resp.techID].bonus.amount + 1 : 1) : 1 };
      return {
        ...state,
        order: { ...anotherBOrder },
      };

    case 'ADD_ANOTHER_DISCOUNT_ITEM':
      //We will need to find the item in the charts items

      let anotherDOrder = state.order;

      anotherDOrder[action.resp.techID].discount = {
        items: state.order[action.resp.techID].discount ? (state.order[action.resp.techID].discount.items ? [...state.order[action.resp.techID].discount.items] : []) : [],
        amount: state.order[action.resp.techID].discount ? (state.order[action.resp.techID].discount.amount ? state.order[action.resp.techID].discount.amount + 1 : 1) : 1,
      };
      return {
        ...state,
        order: { ...anotherDOrder },
      };

    case 'ERROR_COPYING_ORDER':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
      };

    case 'ERROR_COPYING_ORDER_ORDER':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
      };

    case 'ERROR_COPYNO_ORDER_ORDER_FOUNDING_ORDER_ORDER':
      return {
        ...state,
        orderError: 'No autoship order found to copy',
        orderErrorPopup: true,
      };
    case 'ORDER_ON_CHANGE':
      return {
        ...state,
        [action.event.target.name]: action.event.target.value,
      };
    case 'ERROR_DELETING_ORDER_ORDER':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
      };
    case 'ORDER_ORDER_WAS_DELETED':
      return {
        ...state,
        orderMessagePopUp: true,
        orderMessage: 'Your order has been deleted',
      };
    default:
      return {
        ...state,
      };

    case 'SET_LOADING_COMPNAY_ID':
      return {
        ...state,
        loadingOrderCompanyID: action.clinicID,
      };
    case 'ORDER_WAS_APPROVED':
      return {
        ...state,
        orderMessagePopUp: true,
        orderMessage: 'This order has been approved',
        approveOrder: {},
        openApproveDialog: false,
      };
    case 'ERROR_APPROVING_ORDER':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
      };
    case 'ORDER_WAS_UNAPPROVED':
      return {
        ...state,
        orderMessagePopUp: true,
        orderMessage: 'This order has been unapproved',
        approveOrder: {},
        openApproveDialog: false,
      };
    case 'ERROR_UNAPPROVING_ORDER':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
      };
    case 'ERROR_SENDING_APPROVE_EMAIL_OFF':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
        sendEmail: false,
      };
    case 'ORDER_EMAIL_HAS_BEEN_SENT':
      return {
        ...state,
        orderMessagePopUp: true,
        orderMessage: 'An email has been sent to shipping',
        sendEmail: false,
      };
    case 'ORDER_EMAIL_HAS_ALREADY_BEEN_SENT':
      return {
        ...state,
        orderMessagePopUp: true,
        orderMessage: 'An email has already been sent to shipping',
        sendEmail: false,
      };

    case 'START_APPROVAL_ORDER':
      return {
        ...state,
        approveOrder: action.order,
        openApproveDialog: true,
      };

    case 'CLOSE_APPROVAL_ORDER':
      return {
        ...state,
        approveOrder: {},
        openApproveDialog: false,
      };

    case 'ORDER_WAS_REJECTED':
      return {
        ...state,
        approveOrder: {},
        openApproveDialog: false,
        orderMessagePopUp: true,
        orderMessage: 'Order was rejected - An email has been sent',
      };
    case 'ERROR_SENDING_REJECTED_EMAIL_OFF':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
      };
    case 'ORDER_WAS_CANCELED':
      return {
        ...state,
        approveOrder: {},
        openApproveDialog: false,
        orderMessagePopUp: true,
        orderMessage: 'Order was canceled - An email has been sent',
      };
    case 'ERROR_SENDING_CANCELED_EMAIL_OFF':
      return {
        ...state,
        orderError: action.err.message,
        orderErrorPopup: true,
      };
    case 'EMAIL_FOUND':
      //console.log('this is my email response', action.emailStatus);

      return {
        ...state,
        orderEmails: { ...state.orderEmails, ...action.emailStatus },
      };
    case 'SENDING_EMAIL':
      return {
        ...state,
        sendEmail: action.orderID,
      };
    case 'ORDER_HAS_DISCOUNT':
      return {
        ...state,
        orderDiscountAmount: action.discount,
        orderDiscount: true,
      };
    case 'ORDER_HAS_SELECTED_PROMOS':
      //console.log('this is my action call ----> ', action);
      return {
        ...state,
        selectedPromos: [...action.promos],
      };
  }
};

export default autoShipReducer;

const getItemPricing = ({ singleItem, orderCompany }) => {
  let newItemPricing = [];

  if (orderCompany) {
    if (orderCompany.data.nationalProgram) {
      newItemPricing.push(singleItem ? (orderCompany.data.nationalProgramName === 'National Meta Studios Account' || orderCompany.data.nationalProgramName === 'National Account Dermapure' ? singleItem.data['platinum'] : singleItem.data['diamond']) : 0);
    } else {
      newItemPricing.push(singleItem ? singleItem.data[orderCompany.tier.pricingLevel !== 'diamond' ? orderCompany.tier.pricingLevel.toLowerCase() : orderCompany.tier.pricingLevel] : 0);
    }
  } else {
    newItemPricing.push(singleItem ? singleItem.data['list'] : 0);
  }

  return newItemPricing[0];
};

//This will figure out how many items are in groups.
const getTotalNumberofItems = ({ orderGroup }) => {
  let orderNumber = [];

  Object.keys(orderGroup.items).map((order) => {
    return orderNumber.push(orderGroup.items[order].quantiy);
  });
  let bonusGroup = [];
  if (orderGroup.bonus) {
    Object.keys(orderGroup.bonus.items).map((order) => {
      return bonusGroup.push(orderGroup.bonus.items[order].quantiy);
    });
  }
  if (orderGroup.discount) {
    Object.keys(orderGroup.discount.items).map((order) => {
      return bonusGroup.push(orderGroup.discount.items[order].quantiy);
    });
  }

  let totalAmount = (orderNumber.length > 0 ? orderNumber.reduce((a, b) => a + b) : 0) + (bonusGroup.length > 0 ? bonusGroup.reduce((a, b) => a + b) : 0);

  return totalAmount;
};

const getTotalNumberofSpend = ({ orderGroup }) => {
  let orderNumber = [];
  Object.keys(orderGroup).map((order) => {
    return orderNumber.push(orderGroup[order].price);
  });

  if (orderNumber.length > 0) {
    return orderNumber.reduce((a, b) => a + b);
  } else {
    return 0;
  }
};

const getMonthTotal = ({ orderGroup }) => {
  let orderNumber = [];
  //console.log('this is my orderGroup', orderGroup);
  //I am going to have to go through each group

  orderGroup['Clear + Brilliant'].map((order) => {
    return orderNumber.push(order.price);
  });

  orderGroup['Fraxel'].map((order) => {
    return orderNumber.push(order.price);
  });

  orderGroup['Thermage'].map((order) => {
    return orderNumber.push(order.price);
  });

  if (orderNumber.length > 0) {
    return orderNumber.reduce((a, b) => a + b);
  } else {
    return 0;
  }
};

const totalAmount = ({ orderGroup }) => {
  console.log('this is my orderGroup from the total amount --->', orderGroup);
  //console.log('this is my order Group', orderGroup);
  let totalValue = [];
  Object.keys(orderGroup).map((order) => {
    //console.log('this is my order  value ----> ', order);

    //If we have a discount field in it we need to find the cost
    if (orderGroup[order].discount) {
      //console.log('we have a discount field we need to figure out ---->');
      totalValue.push(orderGroup[order].discount.total);
    }

    return totalValue.push(orderGroup[order].total);
  });

  const total = totalValue.reduce((a, b) => a + b);
  //console.log('this is my total =====', total);

  return total;
};
