const initState = {
  authError: null,
  authErrorPopup: false,
  userSignOut: false,
  authMessage: null,
  authMessagePopup: false,
  authLoading: false,
  editingUser: null,
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case "LOGIN_ERROR":
      return {
        ...state,
        authError: action.err.message,
        authErrorPopup: true,
      };
    case "LOGIN_SUCCESS":
      return {
        ...state,
        userSignOut: false,
      };
    case "SIGNOUT_SUCCESS":
      return {
        ...state,
        userSignOut: true,
      };
    case "NEW_USER_CREATED":
      return {
        ...state,
        authMessage: "New user has been created",
        authMessagePopup: true,
      };
    case "NEW_USER_ERROR":
      return {
        ...state,
        authError: action.err.message,
        authErrorPopup: true,
      };
    case "USER_DB_INFO_UPDATED":
      return {
        ...state,
        authMessagePopup: true,
        authMessage: "Users information has been updated",
      };
    case "USER_DB_INFO_ERROR":
      return {
        ...state,
        authError: action.err.message,
        authErrorPopup: true,
      };
    case "USER_EMAIL_HAS_BEEN_UPDATED":
      return {
        ...state,
        authMessage:
          "Users login email has been updated in the database and online",
        authMessagePopup: true,
      };
    case "USER_EMAIL_NOT_UPDATED":
      return {
        ...state,
        authError: action.err.message,
        authErrorPopup: true,
      };
    case "USER_ID_EMAIL_NOT_UPDATED":
      return {
        ...state,
        authError: action.err.message,
        authErrorPopup: true,
      };
    case "CHANGE_EMAIL_REAUTHENTICATION_ERROR":
      return {
        ...state,
        authError: action.err.message,
        authErrorPopup: true,
      };
    case "USER_PASSWORD_WAS_NOT_UPDATED":
      return {
        ...state,
        authError: action.err.message,
        authErrorPopup: true,
      };
    case "CHANGE_PASSWORD_REAUTHENTICATION_ERROR":
      return {
        ...state,
        authError: action.err.message,
        authErrorPopup: true,
      };
    case "USER_PASSWORD_WAS_UPDATED":
      return {
        ...state,
        authMessagePopup: true,
        authMessage: "Users password has been updated!",
      };
    case "USER_EMAIL_RESET_SENT":
      return {
        ...state,
        authMessage:
          "An Email has been sent to the user to reset their password. ",
        authMessagePopup: true,
      };
    case "USER_EMAIL_RESET_NOT_SENT":
      return {
        ...state,
        authError: action.err.message,
        authErrorPopup: true,
      };

    case "STARTING_USER_CREATION":
      return {
        ...state,
        authMessage: "Adding a user",
        authLoading: true,
      };
    case "USER_WAS_CREATED":
      return {
        ...state,
        authMessage: "User was added",
        authMessagePopup: true,
        authLoading: false,
      };

    case "FAILED_TO_CREATE_USER":
      console.log("this is my message", action.result);
      return {
        ...state,
        authError: action.result.data.message.errorInfo.message,
        authErrorPopup: true,
        authLoading: false,
      };

    case "LOADING_INFO_FOR_THIS_USER":
      return {
        ...state,
        editingUser: action.userID,
      };

    case "CLOSE_AUTH_POPUP":
      return {
        ...state,
        authMessage: null,
        authMessagePopup: false,
        authError: null,
        authErrorPopup: false,
      };

    case "USER_WAS_DELETED":
      return {
        ...state,
        authMessage: "User was deleted",
        authMessagePopup: true,
        authLoading: false,
      };

    case "USER_WAS_ARCHIVED":
      return {
        ...state,
        authMessage: "User was archived",
        authMessagePopup: true,
        authLoading: false,
      };

    case "USER_WAS_ENABLED":
      return {
        ...state,
        authMessage: "User was enabled",
        authMessagePopup: true,
        authLoading: false,
      };

    default:
      return state;
  }
};

export default authReducer;
