//import { deepPurple, grey, red } from '@material-ui/core/colors';
//const greyText = '#656366';
import { lighten } from '@material-ui/core/styles';
import { green, red, grey } from '@material-ui/core/colors';

const soltaColor = '#5A9BAB';
const white = '#fff';
const thermageColor = '#45143F';
const clearColor = '#077FB2';
const fraxelColor = '#691E33';
const vaserColor = '#0079C1';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: '#fff',
  },
  right: {
    textAlign: 'right',
  },
  list: {
    width: 250,
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflowY: 'scroll',
    width: '100%',
    height: '100%',
    //maxWidth: 500,
  },
  paper: {
    padding: 40,
    width: '100%',
    maxWidth: 500,
    height: '100%',
    overflowY: 'scroll',
    display: 'block',
  },
  textField: {
    marginLeft: 1,
    marginRight: 1,
    width: '100%',
  },
  formControl: {
    margin: 10,
    minWidth: 120,
    width: '100%',
  },
  fullWidthForm: {
    width: '100%',
  },
  selectEmpty: {
    marginTop: 20,
  },
  tableRoot: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  tableTitle: {
    flex: '1 1 100%',
  },
  reactVideoWrapper: {
    position: 'relative',
    paddingTop: '56.25% /* Player ratio: 100 / (1280 / 720) */',
  },
  reactVideo: {
    position: 'absolute',
    top: 0,
    left: 0,
  },
  whiteText: {
    color: '#fff',
  },
  quizQuestion: {
    //paddingLeft: 20,
  },
  questionBlock: {
    marginBottom: 20,
  },
  correctAnswer: {
    color: green[500],
    fontWeight: 700,
  },
  wrongAnswer: {
    color: grey[500],
    fontWeight: 200,
  },
  wrongPickedAnswer: {
    color: red[800],
    fontWeight: 700,
  },
  loginLogo: {
    display: 'block',
    maxWidth: '50%',
    marginTop: 60,
    marginBottom: 60,
  },
  drawer: {
    width: 250,
    flexShrink: 0,
  },
  soltaText: {
    color: soltaColor,
  },
  soltaBackground: {
    backgroundColor: soltaColor,
  },
  boldText: {
    fontWeight: 700,
  },
  allCapText: {
    textTransform: 'uppercase',
  },
  soltaButton: {
    backgroundColor: soltaColor,
    color: white,
  },
  adImage: {
    maxWidth: '100%',
    width: '100%',
  },
  marginGridBottom: {
    marginBottom: 30,
  },

  whiteIcon: {
    color: white,
  },

  backgroundJYDsuH0VqEmwrND2gFIu: {
    backgroundColor: thermageColor,
  },

  backgroundcj9ZviArI89RqRZwpwOh: {
    backgroundColor: clearColor,
  },
  backgroundgDAPDdRQFnk5phP9pcLi: {
    backgroundColor: fraxelColor,
  },

  backgroundN4B4u4AzgInsHHl3t7jf: {
    backgroundColor: vaserColor,
  },

  colorJYDsuH0VqEmwrND2gFIu: {
    color: thermageColor,
  },

  colorcj9ZviArI89RqRZwpwOh: {
    color: clearColor,
  },
  colorgDAPDdRQFnk5phP9pcLi: {
    color: fraxelColor,
  },

  colorN4B4u4AzgInsHHl3t7jf: {
    color: vaserColor,
  },
  resourceImage: {
    marginBottom: 30,
    marginTop: 30,
    //maxHeight: 275,
  },

  resourceLogos: {
    height: 35,
    display: 'block',
  },
});

export default styles;
