export const createQuiz = (quizDetails) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();

    //Logic to ADD IT
    firestore
      .collection('quiz')
      .where('title', '==', quizDetails.name)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          firestore
            .collection('quiz')
            .add({
              title: quizDetails.name,
              course: quizDetails.courseID,
              lang: quizDetails.lang,
              createdDate: new Date(),
            })
            .then((ref) => {
              let resp = { ref: ref, quizDetails: quizDetails };
              let quizText = 'quizzes.' + quizDetails.lang;
              firestore
                .collection('courses')
                .doc(quizDetails.courseID)
                .update({
                  [quizText]: ref.id,
                });

              dispatch({ type: 'QUIZ_CREATED', resp });
            })
            .catch((err) => {
              dispatch({ type: 'FAILED_TO_CREATE_QUIZ', err });
            });
        } else {
          let resp = { ref: snapshot, quizDetails: quizDetails };
          dispatch({ type: 'QUIZ_ALL_READY_MADE', resp });
        }
      });
  };
};

export const closeQuizPopups = () => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: 'CLOSE_QUIZ_POPUPS' });
  };
};

export const addQuestion = (questionDetails) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();

    //I will have to go throught the amount of numbers and add it to an options array {option: text, answer: false}
    const questionOptions = [];
    for (var i = 0; i < questionDetails.numberOfOptions; i++) {
      questionOptions.push({ option: questionDetails.state['questionOption' + i], answer: questionDetails.state['questionOptionAnswer' + i] });
    }

    //Logic to ADD IT
    firestore
      .collection('quiz')
      .doc(questionDetails.quizID)
      .update({
        questions: firestore.FieldValue.arrayUnion({ question: questionDetails.name, options: questionOptions }),
      })
      .then((resp) => {
        dispatch({ type: 'QUESTION_ADDED_TO_QUIZ', resp });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_QUESTION_ADDED_TO_QUIZ', err });
      });
  };
};

export const updateQuestion = (questionDetails) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();

    //I will have to go throught the amount of numbers and add it to an options array {option: text, answer: false}
    //Logic to ADD IT

    //I might have to grab it first I will have to look into how to replace it
    //I will also have to add in the question itself
    //console.log('this is the question that I am updating it too'.questionDetails);

    firestore
      .collection('quiz')
      .doc(questionDetails.quizID)
      .get()
      .then((snapshot) => {
        if (!snapshot.empty) {
          let questions = snapshot.data().questions;

          //console.log('these are my questions', questions);

          questions[questionDetails.questionToUpdate] = { question: questionDetails.question, options: [...questionDetails.questionOptions] };

          //console.log('this is my updated questions', questions);

          firestore
            .collection('quiz')
            .doc(questionDetails.quizID)
            .update({
              questions: questions,
            })
            .then((resp) => {
              dispatch({ type: 'QUESTION_HAS_BEEN_UPDATED', resp });
            })
            .catch((err) => {
              dispatch({ type: 'ERROR_UPDATING_QUESTION', err });
            });
        } else {
          return dispatch({ type: 'ERROR_SEARCHING_FOR_QUIZ' });
        }
      });
  };
};

export const loadUsersResults = ({ quizID, userID }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();

    //First grab the quiz
    firestore
      .collection('quiz')
      .doc(quizID)
      .get()
      .then((resp) => {
        if (!resp.isEmpty) {
          const quiz = resp.data().questions;
          //next grab the users results
          firestore
            .collection('users')
            .doc(userID)
            .get()
            .then((resp) => {
              const userQuiz = resp.data().quizHistory[quizID];
              let runID = userQuiz ? Object.keys(userQuiz).length - 1 : 0;

              const userResults = resp.data().quizHistory[quizID][runID].answers;

              let userQuizResults = { quiz: quiz, usersResults: userResults };

              dispatch({ type: 'USERS_QUIZ_FOUND', userQuizResults });
            });
        } else {
          dispatch({ type: 'ERROR_FINDING_QUIZ' });
        }
      });
  };
};

export const loadQuizResults = ({ quizID }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();

    //First grab the quiz
    firestore
      .collection('quiz')
      .doc(quizID)
      .get()
      .then((resp) => {
        if (!resp.empty) {
          const quiz = resp.data().questions;
          //next grab the users results

          dispatch({ type: 'QUIZ_FOUND', quiz });
        } else {
          dispatch({ type: 'ERROR_FINDING_QUIZ' });
        }
      });
  };
};
