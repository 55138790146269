/**************************************************************************/
/*  File Name: resourceReducer                                            */
/*  Date:      April 5 2021                                               */
/*  Author:    Ben Wakutz                                                 */
/*  Purpose:   Supports the reducers for the resource actions             */
/*                                                                        */
/*  Last Modified: 4/5/2021                                               */
/**************************************************************************/

const initState = {
    resourceErrorMessage: null,
    resourceErrorPopUp: false,
    resourceMessage: null,
    resourcePopUp: false,
    uploadDialog: false,
    uploadProgress: 0,
  };

  const resourceLibraryReducer = (state = initState, action) => {
    switch (action.type) {
        case 'CLOSE_RESOURCE_POPUPS':
            return{
                ...state,
                resourceErrorMessage: null,
                resourceErrorPopUp: false,
                resourcePopUp: false,
                resourceMessage: null,
            };
            case 'ERROR_DELETE_RESOURCE':
            return{
                ...state,
                resourceErrorMessage: action.err.message,
                resourceErrorPopUp: true,
            };
            case 'RESOURCE_DELETED':
            return{
                ...state,
                resourceMessage: 'Resource has been deleted.',
                resourcePopUp: true,
            };
            case 'RESOURCE_NOT_UPDATED':
            return{
                ...state,
                resourceErrorMessage: action.err.message,
                resourceErrorPopUp: true,
                uploadDialog : false,
            };
            case 'RESOURCE_UPDATED':
            return{
                ...state,
                uploadDialog : false,
                resourcePopUp: true,
                resourceMessage: 'Resource has been updated.',
            };
            case 'RESOURCE_ADDED':
            return {
                ...state,
                resourcePopUp: true,
                uploadDialog: false,
                resourceMessage: 'Resource has been added.',
            }
            case 'RESOURCE_WAS_UPLOADED':
            return {
                ...state,
                resourceError: null,
                uploadDialog : false,

            }
            case 'RESOURCE_ALREADY_ADDED':
            return {
                ...state,
                resourceErrorMessage: 'Resource already exists.',
                resourceErrorPopUp: true,
                uploadDialog : false,
            }
        case 'RESOURCE_WAS_NOT_ADDED_TO_DATABASE':
            return {
                ...state,
                resourceErrorMessage: action.err.message,
                resourceErrorPopUp: true,
                uploadDialog : false,
            }

        case 'RESOURCE_FAILED_TO_UPLOAD':
            return {
                ...state,
                resourceErrorMessage: action.err.message,
                resourceErrorPopUp: true,
                uploadDialog : false,
            }
        case 'UPDATE_UPLOAD_PROGRESS':
            return {
                ...state,
                uploadProgress: action.progress,
            }
        case 'UPLOAD_COMPLETE':
            return{
                ...state,
                uploadComplete: true,
            }
            default:
                return state;
    }
  };

  export default resourceLibraryReducer;