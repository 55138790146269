export const closeNatAccountPopUps = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_NATIONAL_ACCOUNT_POPUPS' });
  };
};

export const createNatProgram = (natDetails) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('natPrograms')
      .add({
        name: natDetails.name,
        tierName: natDetails.tierName,
        purchaseUnitCommitmentStart: natDetails.purchaseUnitCommitmentStart,
        purchaseUnitCommitmentEnd: natDetails.purchaseUnitCommitmentEnd,
        promoOrderProgram: natDetails.promoOrderProgram,
        freeTips: natDetails.freeTips,
        freeTipPercent: natDetails.freeTipPercent,
        purchaseFrequency: natDetails.purchaseFrequency,
        marketingFunds: natDetails.marketingFunds,
        timeFrame: natDetails.timeFrame,
      })
      .then((ref) => {
        let resp = { ref: ref, natDetails: natDetails };

        dispatch({ type: 'NAT_PROGRAM_CREATED', resp });
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_TO_CREATE_NAT_PROGRAM', err });
      });
  };
};

export const updateNatProgram = (natDetails) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    //Logic to ADD IT
    firestore
      .collection('tiers')
      .doc(natDetails.tierID)
      .update({
        name: natDetails.name,
        tierName: natDetails.tierName,
        purchaseUnitCommitmentStart: natDetails.purchaseUnitCommitmentStart,
        purchaseUnitCommitmentEnd: natDetails.purchaseUnitCommitmentEnd,
        promoOrderProgram: natDetails.promoOrderProgram,
        freeTips: natDetails.freeTips,
        freeTipPercent: natDetails.freeTipPercent,
        purchaseFrequency: natDetails.purchaseFrequency,
        marketingFunds: natDetails.marketingFunds,
        timeFrame: natDetails.timeFrame,
      })
      .then((ref) => {
        let resp = { ref: ref, natDetails: natDetails };

        dispatch({ type: 'NAT_PROGRAM_UPDATED', resp });
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_TO_UPDATE_NAT_PROGRAM', err });
      });
  };
};
