import { compose } from 'redux';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { withStyles } from '@material-ui/core/styles';
import { closeStaffPopUps } from '../../actions/staffActions';
import { closeAuthPopUp } from '../../actions/authActions';
import { closeCoursePopUps } from '../../actions/coursesActions';
import { closeCompanyPopups } from '../../actions/companyActions';
//import { closeMailPopUp } from '../../actions/mailActions';
import { closeQuizPopups } from '../../actions/quizActions';
import { closePromoPopups } from '../../actions/promoActions'; /* was closePromoMessage */
//import { closeTierPopUps } from '../../actions/tierActions';
//import { closeItemPopUps } from '../../actions/itemActions';
import { closeOrderProgramPopUps } from '../../actions/orderProgramActions';
import { closeNatAccountPopUps } from '../../actions/nationalAccountActions';
import { closeOrderPopUps } from '../../actions/orderActions';
import { closeResourcePopUps } from '../../actions/resourceActions';
import styles from '../Styles';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
  return <MuiAlert elevation={10} variant='filled' {...props} />;
}

class SystemAlerts extends Component {
  closePopUps = () => {
    this.props.closeStaffPopUps();
    this.props.closeAuthPopUp();
    this.props.closeCoursePopUps();
    this.props.closeCompanyPopups();
    this.props.closeQuizPopups();
    this.props.closePromoPopups();
    this.props.closeNatAccountPopUps();
    this.props.closeOrderProgramPopUps();
    this.props.closeOrderPopUps();
    this.props.closeResourcePopUps();
  };

  render() {
    const {
      authError,
      authMessage,
      authMessagePopup,
      authErrorPopup,
      staffError,
      staffErrorPopup,
      staffMessage,
      staffPopUp,
      coursesError,
      courseErrorPopup,
      coursesMessage,
      courseMessagePopup,
      tierError,
      tierErrorPopup,
      tierMessage,
      tierPopUp,
      itemError,
      itemMessage,
      itemPopUp,
      itemErrorPopup,
      companyError,
      companyMessage,
      companyErrorPopup,
      companyMesPopup,
      quizError,
      quizMessage,
      quizMessagePopup,
      quizErrorPopup,
      promoErrorPopUp,
      promoError,
      promoMessage,
      promoPopUp,
      orderProgramError,
      orderProgramMessage,
      orderProgramPopUp,
      orderProgramErrorPopup,
      nationalAccountError,
      nationalAccountMessage,
      nationalAccountPopUp,
      nationalAccountErrorPopup,
      orderError,
      orderErrorPopup,
      orderMessage,
      orderMessagePopUp,
      resourceErrorPopUp,
      resourceError,
      resourcePopUp,
      resourceMessage,
    } = this.props;

    return (
      <div className='alerts'>
        <Snackbar open={staffPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Staff Message - {staffMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={staffErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Staff Error - {staffError}
          </Alert>
        </Snackbar>
        <Snackbar open={authMessagePopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Auth Message - {authMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={authErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Auth Error - {authError}
          </Alert>
        </Snackbar>

        <Snackbar open={courseMessagePopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Course Message - {coursesMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={courseErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Course Error - {coursesError}
          </Alert>
        </Snackbar>

        <Snackbar open={tierPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Tier Message - {tierMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={tierErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Tier Error - {tierError}
          </Alert>
        </Snackbar>

        <Snackbar open={itemPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Item Message - {itemMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={itemErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Item Error - {itemError}
          </Alert>
        </Snackbar>

        <Snackbar open={companyMesPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Company Message - {companyMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={companyErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Company Error - {companyError}
          </Alert>
        </Snackbar>

        <Snackbar open={quizMessagePopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Quiz Message - {quizMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={quizErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Quiz Error - {quizError}
          </Alert>
        </Snackbar>

        <Snackbar open={promoPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Promo Message - {promoMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={promoErrorPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Promo Error - {promoError}
          </Alert>
        </Snackbar>
        {/* Order Program Popups */}
        <Snackbar open={orderProgramPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Order Programs - {orderProgramMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={orderProgramErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Order Programs - {orderProgramError}
          </Alert>
        </Snackbar>
        {/* National Account Popups */}
        <Snackbar open={nationalAccountPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            National Accounts - {nationalAccountMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={nationalAccountErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            National Accounts - {nationalAccountError}
          </Alert>
        </Snackbar>
        {/* Order Popups */}
        <Snackbar open={orderMessagePopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Order - {orderMessage}
          </Alert>
        </Snackbar>
        <Snackbar open={orderErrorPopup} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Order - {orderError}
          </Alert>
        </Snackbar>
        {/* Resource PopUps */}
        <Snackbar open={resourceErrorPopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='error'>
            Resource - {resourceError}
          </Alert>
        </Snackbar>
        <Snackbar open={resourcePopUp} autoHideDuration={6000} onClose={this.closePopUps}>
          <Alert onClose={this.closePopUps} severity='success'>
            Resource - {resourceMessage}
          </Alert>
        </Snackbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  //console.log('this is the alerts state', state);
  return {
    authError: state.auth.authError,
    authMessage: state.auth.authMessage,
    authMessagePopup: state.auth.authMessagePopup,
    authErrorPopup: state.auth.authErrorPopup,
    staffError: state.staff.staffError,
    staffMessage: state.staff.staffMessage,
    staffPopUp: state.staff.staffPopUp,
    staffErrorPopup: state.staff.staffErrorPopup,
    coursesError: state.courses.coursesError,
    coursesMessage: state.courses.coursesMessage,
    courseMessagePopup: state.courses.courseMessagePopup,
    courseErrorPopup: state.courses.courseErrorPopup,
    companyError: state.company.companyError,
    companyMessage: state.company.companyMessage,
    companyErrorPopup: state.company.companyErrorPopup,
    companyMesPopup: state.company.companyMesPopup,
    quizErrorPopup: state.quiz.quizErrorPopup,
    quizMessagePopup: state.quiz.quizMessagePopup,
    quizError: state.quiz.quizError,
    quizMessage: state.quiz.quizMessage,
    promoError: state.promos.promoError,
    promoErrorPopUp: state.promos.promoErrorPopUp,
    promoMessage: state.promos.promoMessage,
    promoPopUp: state.promos.promoPopUp,
    orderProgramError: state.orderPrograms.orderProgramError,
    orderProgramMessage: state.orderPrograms.orderProgramMessage,
    orderProgramPopUp: state.orderPrograms.orderProgramPopUp,
    orderProgramErrorPopup: state.orderPrograms.orderProgramErrorPopup,
    nationalAccountError: state.nationalAccounts.nationalAccountError,
    nationalAccountMessage: state.nationalAccounts.nationalAccountMessage,
    nationalAccountPopUp: state.nationalAccounts.nationalAccountPopUp,
    nationalAccountErrorPopup: state.nationalAccounts.nationalAccountErrorPopup,
    orderError: state.order.orderError,
    orderErrorPopup: state.order.orderErrorPopup,
    orderMessage: state.order.orderMessage,
    orderMessagePopUp: state.order.orderMessagePopUp,
    resourceErrorPopUp: state.resource.resourceErrorPopUp,
    resourceError: state.resource.resourceError,
    resourceMessage: state.resource.resourceMessage,
    resourcePopUp: state.resource.resourcePopUp,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeStaffPopUps: () => dispatch(closeStaffPopUps()),
    closeAuthPopUp: () => dispatch(closeAuthPopUp()),
    closeCoursePopUps: () => dispatch(closeCoursePopUps()),
    closeCompanyPopups: () => dispatch(closeCompanyPopups()),
    closeQuizPopups: () => dispatch(closeQuizPopups()),
    closePromoPopups: () => dispatch(closePromoPopups()),
    closeOrderProgramPopUps: () => dispatch(closeOrderProgramPopUps()),
    closeNatAccountPopUps: () => dispatch(closeNatAccountPopUps()),
    closeOrderPopUps: () => dispatch(closeOrderPopUps()),
    closeResourcePopUps: () => dispatch(closeResourcePopUps()),
  };
};

export default compose(withStyles(styles), connect(mapStateToProps, mapDispatchToProps), withTranslation())(SystemAlerts);
