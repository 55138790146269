const getCurrentQuarter = () => {
  const today = new Date();
  const m = Math.floor((today.getMonth() + 3) / 3);
  return {
    quarter: m,
    year: today.getFullYear(),
  };
};

export default getCurrentQuarter;
