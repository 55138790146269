const initState = {
  technologyError: null,
  technologyMessage: null,
  companyTechnologies: [],
  loadingTechnologies: false,
};

const technologyReducer = (state = initState, action) => {
  switch (action.type) {
    case 'TECHNOLOGY_ADDED':
      return {
        ...state,
        technologyError: null,
        technologyMessage: 'Technology has been added',
      };
    case 'TECHNOLOGY_IMAGE_WAS_UPLOADED':
      return {
        ...state,
        technologyError: null,
        technologyMessage: 'Technology image has been uploaded',
      };
    case 'TECHNOLOGY_ALREADY_ADDED':
      return {
        ...state,
        technologyError: null,
        technologyMessage: 'Technology is already in the system',
      };
    case 'TECHNOLOGY_WAS_NOT_ADDED_TO_DATABASE':
      return {
        ...state,
        technologyError: action.err.message,
        technologyMessage: 'Technology Failed to be hadded to Database',
      };

    case 'TECHNOLOGY_IMAGE_FAILED_TO_UPLOAD':
      return {
        ...state,
        technologyError: action.err.message,
        technologyMessage: 'Technology Image Failed to be uploaded to the server',
      };
    case 'TECHNOLOGY_FOUND':
      let companyTechLoaded = state.companyTechnologies.length > 0 ? state.companyTechnologies.filter((t) => t.id === action.tech.id) : [];

      if (companyTechLoaded.length === 0) {
        return {
          ...state,
          companyTechnologies: [...state.companyTechnologies, action.tech],
          loadingTechnologies: true,
        };
      } else {
        return {
          ...state,
        };
      }

    case 'STARTING_FRESH_TECH_SEARCH':
      return {
        ...state,
        companyTechnologies: [],
        loadingTechnologies: false,
      };
    case 'TECHNOLOGY_WAS_NOT_FOUND':
      return {
        ...state,
        technologyError: action.err.message,
      };
    default:
      return state;
  }
};

export default technologyReducer;
