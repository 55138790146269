export const createCourse = (courseDetails) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();

    //Logic to ADD IT
    firestore
      .collection('courses')
      .where('title', '==', courseDetails.name)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          firestore
            .collection('courses')
            .add({
              title: courseDetails.name,
              technology: courseDetails.courseTechnology,
              createdDate: new Date(),
              lastUpdated: new Date(),
            })
            .then((ref) => {
              let resp = { ref: ref, courseDetails: courseDetails };
              dispatch({ type: 'COURSE_CREATED', resp });
            })
            .catch((err) => {
              dispatch({ type: 'FAILED_TO_CREATE_COURSE', err });
            });
        } else {
          let resp = { ref: snapshot, courseDetails: courseDetails };
          dispatch({ type: 'COURSE_ALL_READY_MADE', resp });
        }
      });
  };
};

export const editCourse = (courseDetails) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    dispatch({ type: 'EDIT_COURSE', courseDetails });
  };
};

export const createPage = ({ pageDetails, courseDetails }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const pageContents = {};
    const pageFileLocation = 'pages';

    //console.log('these are my page variables', pageDetails);
    //console.log('tese are my course Details', courseDetails);

    const uploadFiles = pageDetails.languages.map((l) => {
      pageContents[l] = {};
      return pageDetails.pageFields.map((f) => {
        if (f === 'PageImage' || f.substring(0, 4) === 'File') {
          if (pageDetails[l + f]) {
            if (pageDetails[l + f][0]) {
              const fileLocation = pageFileLocation + '/' + courseDetails.id + '/' + l;
              pageContents[l][l + f] = fileLocation + '/' + pageDetails[l + f][0].name;

              //Upload the file to storage
              firebase
                .uploadFiles(fileLocation, pageDetails[l + f])
                .then((snapshot) => {
                  //pageContents[l][l + f] = snapshot[0].uploadTaskSnapshot.metadata.fullPath;
                  dispatch({ type: 'PAGE_IMAGE_UPLOADED' });
                })
                .catch((err) => {
                  dispatch({ type: 'PAGE_IMAGE_FAILED_TO_UPLOAD', err });
                });
            } else {
              pageContents[l][l + f] = pageDetails[l + f];
            }
          } else {
            pageContents[l][l + f] = '';
          }
        } else if (f.substring(f.length - 5, f.length) === 'Count') {
          pageContents[f] = pageDetails[f] ? pageDetails[f] : '';
        } else {
          pageContents[l][l + f] = pageDetails[l + f] ? pageDetails[l + f] : '';
        }
        return 'done';
      });
    });

    Promise.all(uploadFiles).then(() => {
      //console.log('page Detials ', pageDetails)
      const pageNumbers = Object.keys(pageDetails)
        .filter((d) => d.substring(d.length - 5, d.length) === 'Count')
        .reduce((obj, d) => {
          obj[d] = pageDetails[d];
          return obj;
        }, {});

      //Add the details to the database
      firestore
        .collection('pages')
        .add({
          pageType: pageDetails.pageType,
          createdDate: new Date(),
          pageNumbers: pageNumbers,
          pageDetails: pageDetails.pageFields,
          pageContents: pageContents,
          course: courseDetails.id,
        })
        .then((ref) => {
          firestore
            .collection('courses')
            .doc(courseDetails.id)
            .update({
              pages: firestore.FieldValue.arrayUnion({ id: ref.id, pageType: pageDetails.pageType, page: courseDetails.pageNumber }), //Add the page to the course
              lastUpdated: new Date(),
            })
            .then(() => {
              dispatch({ type: 'PAGE_HAS_BEEN_CREATED' });
            });
        })
        .catch((err) => {
          dispatch({ type: 'FAILED_TO_ADD_PAGE_TO_DATABASE', err });
        });
    });
  };
};

export const updatePage = ({ pageDetails, pageInfo }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();
    const pageContents = {};
    const pageFileLocation = 'pages';
    let pageField = [...pageDetails.pageFields];
    //console.log('these are my pageinfo', pageInfo)
    //Clean the files structure
    const uploadFiles = pageDetails.languages.map((l) => {
      pageContents[l] = {};
      if(!pageField.includes('PageCopy')){
        //console.log("pagefield-> ", pageField);
        pageField.push('PageCopy')
      }
      return pageField.map((f) => {
        if (f === 'PageImage' || f.substring(0, 4) === 'File') {
          if (pageDetails[l + f]) {
            if (pageDetails[l + f][0].name) {
              //console.log('this is my page details to upload? ', pageDetails[l + f]);
              const fileLocation = pageFileLocation + '/' + pageInfo.course + '/' + l;
              pageContents[l][l + f] = fileLocation + '/' + pageDetails[l + f][0].name;

              //Upload the file to storage
              firebase
                .uploadFiles(fileLocation, pageDetails[l + f])
                .then((snapshot) => {
                  //pageContents[l][l + f] = snapshot[0].uploadTaskSnapshot.metadata.fullPath;
                  dispatch({ type: 'PAGE_IMAGE_UPLOADED' });
                })
                .catch((err) => {
                  dispatch({ type: 'PAGE_IMAGE_FAILED_TO_UPLOAD', err });
                });
            } else {
              pageContents[l][l + f] = pageDetails[l + f];
            }
          } else {
            pageContents[l][l + f] = '';
          }
        } else {
          pageContents[l][l + f] = pageDetails[l + f] ? pageDetails[l + f] : '';
        }
        return 'done';
      });
    });
   // console.log('these are my pagedetails', pageDetails);
   // console.log("pagefield-> ", pageField);
    Promise.all(uploadFiles).then(() => {
      //Add the details to the database
      firestore
        .collection('pages')
        .doc(pageInfo.id)
        .update({
          //pageType: pageDetails.pageType,
          lastUpdated: new Date(),
          pageDetails: pageField,
          pageContents: pageContents,
          //course: courseDetails.id
        })
        .then((ref) => {
          firestore
            .collection('courses')
            .doc(pageInfo.course)
            .update({
              //pages: firestore.FieldValue.arrayUnion({ id: ref.id, pageType: pageDetails.pageType, page: courseDetails.pageNumber }), //Add the page to the course
              lastUpdated: new Date(),
            })
            .then(() => {
              dispatch({ type: 'PAGE_HAS_BEEN_UPDATED' });
            });
        })
        .catch((err) => {
          dispatch({ type: 'FAILED_TO_UPDATED_PAGE_TO_DATABASE', err });
        });
    });
  };
};

export const listCoursesAvailble = (technologies) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    //console.log('these are my technologies', technologies)
    let availableCourses = [];
    technologies.map((t) => {
      //console.log('this is my technology id', t.id)
      return firestore
        .collection('courses')
        .where('technology', '==', t.id)
        .get()
        .then((snapshot) => {
          if (!snapshot.empty) {
            snapshot.forEach((doc) => {
              return availableCourses.push({ id: doc.id, data: doc.data() });
            });
          } else {
            //console.log('No course found');
            return;
          }
        })
        .catch((err) => {
          return dispatch({ type: 'NO_COURSES_FOUND', err });
        });
    });

    dispatch({ type: 'COURSES_FOUND', availableCourses });
  };
};

export const enrollUserInCourse = ({ course, user }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    //first to check to see if the corse is actually in the users list
    firestore
      .collection('users')
      .doc(user)
      .update({
        courses: firestore.FieldValue.arrayUnion({ id: course, page: 0, startDate: new Date() }), //Add the page to the course
      })
      .then(() => {
        dispatch({ type: 'COURSE_STARTED' });
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_TO_START_COURSE', err });
      });
  };
};

export const getSlideImage = ({ imageURL, pageID, lang }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    //const firestore = getFirestore();
    const storage = firebase.storage().ref();
    console.log('this is my imageURL', imageURL);
    if (imageURL) {
      storage
        .child(imageURL)
        .getDownloadURL()
        .then((url) => {
          // `url` is the download URL for 'images/stars.jpg'

          // This can be downloaded directly:
          let xhr = new XMLHttpRequest();
          xhr.responseType = 'blob';
          xhr.onload = function (event) {
            //var blob = xhr.response;
          };
          xhr.open('GET', url);
          xhr.send();

          let resp = { url: url, pageID: pageID, lang: lang };
          dispatch({ type: 'SLIDE_IMAGE_FOUND', resp });
          // Or inserted into an <img> element:
          //let img = document.getElementById('myimg');
          //img.src = url;
        })
        .catch((error) => {
          dispatch({ type: 'SLIDE_IMAGE_WAS_NOT_FOUND', error });
        });
    } else {
      dispatch({ type: 'NO_SLIDE_IMAGE' });
    }
  };
};

export const passUserInCourse = ({ course, user, teacher }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    const firestore = getFirestore();
    //first to check to see if the corse is actually in the users list
    const courseField = 'courses.' + [course] + '.inPersonTrainingComplete';
    const courseAuthorized = 'courses.' + [course] + '.authorizedPassBy';

    firestore
      .collection('users')
      .doc(user)
      .update({
        [courseField]: true,
        [courseAuthorized]: {
          authorized: teacher,
          date: new Date(),
        },
        //Add the page to the course
      })
      .then(() => {
        dispatch({ type: 'USER_PASSED' });
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_PASS_USER', err });
      });
  };
};

export const closeCoursePopUps = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_COURSE_POPUPS' });
  };
};
