const initState = {
  staffError: null,
  staffMessage: null,
  staffPopUp: false,
  staffErrorPopup: false,
  staff: [],
  loadingStaff: false,
};

const staffReducer = (state = initState, action) => {
  switch (action.type) {
    case 'STAFF_WERE_FOUND':
      return {
        ...state,
        staff: action.staff,
      };
    case 'CLOSE_STAFF_POPUPS':
      return {
        ...state,
        staffPopUp: false,
        staffErrorPopup: false,
        staffError: null,
        staffMessage: null,
      };
    case 'COMPANY_HAS_NO_EMPLOYEES':
      //console.log('company has not staff');
      return {
        ...state,
        staff: 0,
        loadingStaff: true,
      };
    case 'FOUND_COMPANY_EMPLOYEES':
      //console.log('we found employee', action.companyEmployees);
      let employeeLoaded = state.staff.length > 0 ? state.staff.filter((s) => s.id === action.companyEmployees.id) : [];

      if (employeeLoaded.length === 0) {
        if (state.staff !== 0) {
          return {
            ...state,
            staff: [...state.staff, action.companyEmployees],
            loadingStaff: true,
          };
        } else {
          return {
            ...state,
            staff: [action.companyEmployees],
            loadingStaff: true,
          };
        }
      } else {
        return {
          ...state,
        };
      }

    case 'STAFF_MESSAGE_POPUP':
      return {
        ...state,
        staffMessage: action.message,
        staffPopUp: true,
      };

    case 'STAFF_ERROR_MESSAGE_POPUP':
      return {
        ...state,
        staffError: action.err.message,
        staffErrorPopup: true,
      };

    case 'ERROR_SEARCHING_FOR_EMPLOYEES':
      return {
        ...state,
        staffError: action.err.message,
        staffErrorPopup: true,
        loadingStaff: true,
      };
    case 'STARTING_FRESH_STAFF_SEARCH':
      //console.log('lets clear the staff');
      return {
        ...state,
        staff: [],
        loadingStaff: false,
      };
    default:
      return state;
  }
};

export default staffReducer;
