export const closeStaffPopUps = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_STAFF_POPUPS' });
  };
};

export const staffPopUps = (message) => {
  return (dispatch, getState) => {
    dispatch({ type: 'STAFF_MESSAGE_POPUP', message });
  };
};

export const findStaffByCompany = (companyID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    //console.log('we need to find this many companies', Array.isArray(companies));
    //Function CollectionReference.doc() requires its first argument to be of type non-empty string, but it was: an array
    //console.log('just one company');

    firestore
      .collection('users')
      .where('company', 'array-contains', companyID)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch({ type: 'COMPANY_HAS_NO_EMPLOYEES' });
        } else {
          snapshot.forEach((employee) => {
            //const companyEmployees = {};

            firestore
              .collection('companies')
              .doc(companyID)
              .get()
              .then((company) => {
                if (company.empty) {
                  dispatch({ type: 'NO_COMPANY_WAS_FOUND' });
                } else {
                  let companyEmployees = { id: employee.id, data: employee.data(), company: company.data() };
                  dispatch({ type: 'FOUND_COMPANY_EMPLOYEES', companyEmployees });
                }
              })
              .catch((err) => {
                dispatch({ type: 'ERROR_SEARCHING_FOR_COMPANY', err });
              });
          });
          //console.log('this is my staff information', companyEmployees);
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SEARCHING_FOR_EMPLOYEES', err });
      });
  };
};

export const findStaff = (userID) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    //console.log('we need to find this many companies', Array.isArray(companies));
    //Function CollectionReference.doc() requires its first argument to be of type non-empty string, but it was: an array
    //console.log('just one company');
    firestore
      .collection('users')
      .doc(userID)
      .get()
      .then((snapshot) => {
        if (snapshot.isEmpty) {
          dispatch({ type: 'COMPANY_HAS_NO_EMPLOYEES' });
        } else {
          let employee = snapshot;
          if (employee.data().company[0]) {
            firestore
              .collection('companies')
              .doc(employee.data().company[0])
              .get()
              .then((company) => {
                if (company.empty) {
                  dispatch({ type: 'NO_COMPANY_WAS_FOUND' });
                } else {
                  let companyEmployees = { id: employee.id, data: employee.data(), company: company.data() };
                  dispatch({ type: 'FOUND_COMPANY_EMPLOYEES', companyEmployees });
                }
              })
              .catch((err) => {
                dispatch({ type: 'ERROR_SEARCHING_FOR_COMPANY', err });
              });
          } else {
            let companyEmployees = { id: employee.id, data: employee.data() };
            dispatch({ type: 'FOUND_COMPANY_EMPLOYEES', companyEmployees });
          }

          //console.log('this is my staff information', companyEmployees);
        }
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_SEARCHING_FOR_EMPLOYEES', err });
      });
  };
};

export const clearStaff = () => {
  return (dispatch) => {
    dispatch({ type: 'STARTING_FRESH_STAFF_SEARCH' });
  };
};
