const initState = {
  tierError: null,
  tierMessage: null,
  tierPopUp: false,
  tierErrorPopup: false,
  tier: [],
  loadingtier: false,
};

const tierReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CLOSE_TIER_POPUPS':
      return {
        ...state,
        tierPopUp: false,
        tierErrorPopup: false,
        tierError: null,
        tierMessage: null,
      };
    case 'TIER_CREATED':
      return {
        ...state,
        tierPopUp: true,
        tierMessage: 'The tier was created',
      };
    case 'FAILED_TO_CREATE_TIER':
      return {
        ...state,
        tierErrorPopup: true,
        tierError: action.err.message,
      };
    case 'TIER_ALL_READY_MADE':
      return {
        ...state,
        tierErrorPopup: true,
        tierError: 'That tier is already in the system',
      };
    case 'FAILED_TO_FIND_TIER':
      return {
        ...state,
        tierErrorPopup: true,
        tierError: action.err.message,
      };
    case 'TIER_WAS_FOUND':
      return {
        ...state,
        tier: [...state.tier, action.resp],
        loadingtier: true,
      };
    case 'CLEAR_TIERS':
      return {
        ...state,
        tier: [],
        loadingtier: false,
      };
    default:
      return state;
  }
};

export default tierReducer;
