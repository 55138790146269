const initState = {
  course: {
    id: '',
    name: '',
    pageNumber: '',
  },
  availableCourses: [],
  availableCoursesLoaded: false,
  activeStep: 0,
  coursesError: null,
  coursesMessage: null,
  courseMessagePopup: false,
  courseErrorPopup: false,
  slideImages: [],
  loadedSlideImage: false,
};

const coursesReducer = (state = initState, action) => {
  switch (action.type) {
    case 'COURSE_CREATED':
      return {
        ...state,
        coursesError: null,
        coursesMessage: 'Course has been started',
        activeStep: 1,
        course: {
          id: action.resp.ref.id,
          name: action.resp.courseDetails.name,
          pageNumber: 1,
        },
      };
    case 'COURSE_ALL_READY_MADE':
      return {
        ...state,
        coursesError: null,
        coursesMessage: 'Course has already been made, Please add Pages',
        activeStep: 1,
        course: {
          id: action.resp.ref.docs[0].id,
          name: action.resp.courseDetails.name,
        },
      };
    case 'EDIT_COURSE':
      console.log('this edit deailts', action.courseDetails.editCourse[0]);

      return {
        ...state,
        coursesError: null,
        coursesMessage: 'Lets edit the course',
        activeStep: 1,
        course: {
          id: action.courseDetails.state.existingCourse,
          name: action.courseDetails.editCourse[0].title,
          pageNumber: action.courseDetails.editCourse[0].pages ? action.courseDetails.editCourse[0].pages.length + 1 : 1,
        },
      };
    case 'PAGE_IMAGE_UPLOADED':
      return {
        ...state,
        coursesError: null,
        coursesMessage: 'The pages image has been uploaded',
      };
    case 'PAGE_IMAGE_FAILED_TO_UPLOAD':
      return {
        ...state,
        coursesError: action.err.message,
      };
    case 'FAILED_TO_CREATE_COURSE':
      return {
        ...state,
        coursesError: action.err.message,
      };
    case 'NO_PAGE_TYPE_FOUND':
      return {
        ...state,
        coursesError: 'The page type not found',
      };
    case 'PAGE_HAS_BEEN_CREATED':
      console.log('this is my page number when creating anothe page', state.course.pageNumber);
      return {
        ...state,
        coursesError: null,
        coursesMessage: 'Your page has been created',
        course: {
          name: state.course.name,
          id: state.course.id,
          pageNumber: state.course.pageNumber + 1,
        },
      };
    case 'FAILED_TO_ADD_PAGE_TO_DATABASE':
      return {
        ...state,
        coursesError: action.err.message,
      };
    case 'COURSES_FOUND':
      return {
        ...state,
        availableCourses: [action.availableCourses],
        availableCoursesLoaded: true,
      };
    case 'SLIDE_IMAGE_FOUND':
      //console.log('this is my action ', action)
      return {
        ...state,
        slideImages: [...state.slideImages, action.resp],
        loadedSlideImage: true,
      };
    case 'SLIDE_IMAGE_WAS_NOT_FOUND':
      //console.log('this is my action ', action)
      return {
        ...state,
        coursesError: action.error,
        courseErrorPopup: true,
        loadedSlideImage: true,
      };
    case 'CLOSE_COURSE_POPUPS':
      return {
        ...state,
        courseErrorPopup: false,
        courseMessagePopup: false,
        coursesError: null,
        coursesMessage: null,
      };

    case 'USER_PASSED':
      return {
        ...state,
        courseMessagePopup: true,
        coursesMessage: 'User has passed the inperson course',
      };

    case 'FAILED_PASS_USER':
      return {
        ...state,
        courseErrorPopup: true,
        coursesError: action.err.message,
      };
    default:
      return state;
  }
};

export default coursesReducer;
