import moment from 'moment';

export const addNewPromo = (newPromo) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();

    //console.log('this is my end date', moment(newPromo.promoEndDate).valueOf())

    firestore
      .collection('promos')
      .where('name', '==', newPromo.promoNameen)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          firestore
            .collection('promos')
            .doc()
            .set({
              nameen: newPromo.promoNameen,
              namefr: newPromo.promoNamefr,
              namezh: newPromo.promoNamezh,
              descriptionen: newPromo.promoDescriptionen,
              descriptionfr: newPromo.promoDescriptionfr,
              descriptionzh: newPromo.promoDescriptionzh,
              qualificationText: newPromo.promoQualificationText,
              promoStartDate: newPromo.promoStartDate,
              promoEndDate: newPromo.promoEndDate,
              promoTechnology: newPromo.promoTechnology,
              justForReps: newPromo.justForReps,
              doctors: [],
            })
            .then(() => {
              dispatch({ type: 'PROMO_ADDED' });
            })
            .catch((err) => {
              dispatch({ type: 'PROMO_WAS_NOT_ADDED_TO_DATABASE', err });
            });
        } else {
          dispatch({ type: 'PROMO_ALREADY_ADDED' });
        }
      });
  };
};

export const findDoctorsForPromo = (promoTechnology) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    firestore
      .collection('companies')
      .where('technologies', 'array-contains', promoTechnology)
      .orderBy('name')
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch({ type: 'PROMO_NO_DOCTORS_FOUND' });
        } else {
          const resp = [];
          snapshot.forEach((doc) => {
            //console.log(doc.id, '=>', doc.data())
            resp.push({ id: doc.id, data: doc.data() });
          });
          dispatch({ type: 'PROMO_DOCTORS_W_TECHNOLOGY', resp });
        }
        //console.log('this is my snapshot', snapshot)
      })
      .catch((err) => {
        dispatch({ type: 'PROMO_ERROR_LOOKING_FOR_DOCS_TECH', err });
      });
  };
};

export const addDoctorstoPromo = (promoDetails) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    firestore
      .collection('promos')
      .doc(promoDetails.addDoctoPromoID)
      .update({
        doctors: promoDetails.docsChecked,
      })
      .then((resp) => {
        //We are going to be going to through the docts and adding the promo to their details
        promoDetails.docsChecked
          .map((id) => {
            //console.log('updating the promos record for the following docs', id)
            return firestore
              .collection('companies')
              .doc(id)
              .update({
                promos: firestore.FieldValue.arrayUnion(promoDetails.addDoctoPromoID),
              });
            //return null
          })
          .then(() => {
            dispatch({ type: 'DOCTORS_ADDED_TO_PROMO' });
          });
      })
      .catch((err) => {
        dispatch({ type: 'DOCTORS_NOT_ADDED_TO_PROMO', err });
      });
  };
};

export const addSingleDoctorstoPromo = ({ promoID, clinicID }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    firestore
      .collection('promos')
      .doc(promoID)
      .update({
        doctors: firestore.FieldValue.arrayUnion(clinicID),
      })
      .then((resp) => {
        //We are going to be going to through the docts and adding the promo to their details
        return firestore
          .collection('companies')
          .doc(clinicID)
          .update({
            promos: firestore.FieldValue.arrayUnion(promoID),
          });
        //return null
      })
      .then(() => {
        dispatch({ type: 'DOCTORS_ADDED_TO_PROMO' });
      });
  };
};

export const approvePromo = (submittedPromoID) => {
  console.log('did I get called', submittedPromoID);
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();
    console.log('amm i here');
    firestore
      .collection('submittedPromos')
      .doc(submittedPromoID)
      .update({
        promoProcessed: true,
      })
      .then(() => {
        dispatch({ type: 'SUBMITTED_PROMO_APPROVED' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_APPROVING_SUBMITTED_PROMO', err });
      });
  };
};

export const paySubmittedPromo = ({ submittedPromoID, date }) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    firestore
      .collection('submittedPromos')
      .doc(submittedPromoID)
      .update({
        paided: date,
        promoProcessed: true,
      })
      .then(() => {
        dispatch({ type: 'SUBMITTED_PROMO_HAS_BEEN_PAIDED' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_PAYING_SUBMITTED_PROMO', err });
      });
  };
};

export const signUpForPromo = ({ clinicID, promoID }) => {
  return (dispatch, getState, { getFirebase, getFirestore }) => {
    //const firebase = getFirebase();
    const firestore = getFirestore();

    //console.log("this is my clinic id", clinicID);
    //console.log("this is my promoID", promoID);

    firestore
      .collection('promos')
      .doc(promoID)
      .update({
        doctors: firestore.FieldValue.arrayUnion(clinicID),
      })
      .then((resp) => {
        firestore
          .collection('companies')
          .doc(clinicID)
          .update({
            promos: firestore.FieldValue.arrayUnion(promoID),
          })
          .then(() => {
            dispatch({ type: 'DOCTOR_SIGNED_UP_FOR_PROMO' });
          });
      })
      .catch((err) => {
        dispatch({ type: 'DOCTOR_FAILED_TO_SIGN_UP_TO_PROMO', err });
      });
  };
};

export const closePromoPopups = () => {
  return (dispatch, getState, { getFirestore }) => {
    dispatch({ type: 'CLOSE_PROMO_POPUPS' });
  };
};

export const reminderUsersExport = () => {
  return (dispatch, getState, { getFirestore }) => {
    console.log('we are searching');
    const firestore = getFirestore();

    firestore
      .collection('lists')
      .doc('companyList')
      .get()
      .then((companyList) => {
        const clinicList = companyList.data().clinics;

        firestore
          .collection('submittedPromos')
          .get()
          .then((submittedPromos) => {
            const submittedPromosList = [];
            submittedPromos.forEach((doc) => {
              submittedPromosList.push(doc.data());
            });

            firestore
              .collection('consumer')
              .get()
              .then((consumerList) => {
                const exportList = [];

                consumerList.forEach((doc) => {
                  //I need to
                  let consumerData = doc.data().promos['a7jHub4RKXSW2a8CZXAa'];
                  //console.log('this is my doc.data', doc.data());
                  //console.log('this is my consumer Data', consumerData);
                  if (consumerData) {
                    let clinic = clinicList.filter((c) => c.id === consumerData.clinic);
                    let foundClinic = clinic ? clinic[0] : null;
                    if (consumerData.submittedDate) {
                      consumerData.submittedDate = moment(consumerData.submittedDate.toDate()).format('MMMM Do, YYYY');
                    }

                    if (consumerData.promoCodeClaimed) {
                      let paidedDate = submittedPromosList.filter((promo) => promo.promoCode === consumerData.promoCode);
                      //console.log('this is my paied data', paidedDate[0] ? paidedDate[0].paided : paidedDate[0]);
                      let readableDate = paidedDate[0] ? (paidedDate[0].paided ? moment(paidedDate[0].paided.toDate()).format('MMMM Do, YYYY') : null) : null;
                      consumerData.paided = readableDate;
                    }

                    let newData = { ...consumerData, ...foundClinic };
                    //console.log('tis is my new data no promo claimed', newData);
                    //console.log('clinic is', clinic ? clinic[0] : clinic);
                    exportList.push(newData);
                  }
                });

                dispatch({ type: 'EXPORT_CONSUMERS_REMINDER', exportList });
              });
          });
      });
  };
};

export const clinicExport = () => {
  return (dispatch, getState, { getFirestore }) => {
    //console.log('we are searching');
    const firestore = getFirestore();

    console.log('we are getting all of the doctors from the promo?');

    firestore
      .collection('promos')
      .doc('8oqF4ifcrvKLMyik6jZa')
      .get()
      .then((promoList) => {
        const clinicList = promoList.data().doctors;

        //We want to loop through each clinic and get their
        // Name
        // City
        // Promos Submitted Total
        // [Name, City, Submitted totals]
        //const clinicReport = [];

        clinicList.map((clinic) => {
          console.log('this is my clinicList', clinic);

          return firestore
            .collection('companies')
            .doc(clinic)
            .get()
            .then((company) => {
              let clinicInfo = company.data();
              console.log('this is my company info', clinicInfo);
              //let submittedPromos = [];

              /* if (clinicInfo.submittedPromos) {
                Object.keys(clinicInfo.submittedPromos).map((promo) => {
                  if (clinicInfo.submittedPromos[promo].promoID === '8oqF4ifcrvKLMyik6jZa') {
                    submittedPromos.push(1);
                  }
                  return true;
                });
              } */

              let clinicReport = { clinic: clinicInfo.name ?? company, email: clinicInfo.email, city: clinicInfo.location.city };
              dispatch({ type: 'EXPORT_CLINIC_FINDINGS', clinicReport });
            });
        });

        //console.log('this is my things for the report', clinicReport);
        //dispatch({ type: 'EXPORT_CLINIC_FINDINGS', clinicReport });
      });
  };
};

export const allClinicSubmittedPromos = (clinicID) => {
  return (dispatch, getState, { getFirestore }) => {
    //console.log('we are searching');
    const firestore = getFirestore();

    firestore
      .collection('submittedPromos')
      .where('clinicID', '==', clinicID)
      .get()
      .then((promoList) => {
        promoList.map((promo) => {
          let submittedPromo = {
            id: promo.id,
            promoCode: promo.data().promoCode,
            clinic: promo.data().clinicName,
            treatmentDate: promo.data().firstTreatment.treatmentDate,
            firstLot: promo.data().firstTreatment.lot,
            firstRef: promo.data().firstTreatment.ref,
            secondLot: promo.data().secondTreatment.lot,
            secondRef: promo.data().secondTreatment.ref,
            promoProcessed: promo.data().promoProcessed,
            promoPaid: promo.data().paided,
            submittedDate: promo.data().submittedDate,
          };
          return dispatch({ type: 'EXPORT_CLINIC_FINDINGS', submittedPromo });
        });
      });
  };
};

//This function will give you a list of ALL users that have signed up from a promo

export const promoUsersExport = (promoID) => {
  return async (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    let clinicListing = await firestore.collection('lists').doc('companyList').get();

    let consumers = await firestore.collection('consumer').orderBy(`promos.${promoID}`).get();

    let rep = '';
    if (!consumers.empty) {
      let consumerList = [];
      await Promise.all(
        consumers.docs.map(async (u) => {
          let companyName = clinicListing.data().clinics.filter((c) => c.id === u.data().promos[promoID].clinic);

          if (companyName.length > 0) {
            let company = await firestore.collection('companies').doc(companyName[0].id).get();

            if (company.exists) {
              //console.log('this is my sales rep ---> ', company.data().rep);
              if (company.data().rep) {
                let salesRep = await firestore.collection('reps').doc(company.data().rep).get();

                if (salesRep.exists) {
                  //console.log('This is my sales rep for the clinic ---->', salesRep.data().fullName);
                  rep = salesRep.data().fullName;
                }
              }
            }
          }

          consumerList.push({
            email: u.data().email,
            firstName: u.data().firstName,
            lastName: u.data().lastName,
            fullName: u.data().fullName,
            prov: u.data().province ? u.data().province : '-',
            promosEnrolled: Object.keys(u.data().promos).length,
            promos: Object.keys(u.data().promos).map((p) => p + ','),
            clinic: companyName.length > 0 ? companyName[0].company : '',
            promoClaimed: u.data().promos[promoID].promoCodeClaimed,
            salesRep: rep,
          });
        })
      );

      //console.log('this is all my consumersList', consumerList);
      dispatch({ type: 'CONSUMERS_HAVE_BEEN_FOUND_CSV', consumerList });
    } else {
      dispatch({ type: 'NO_USERS_HAVE_SIGNED_UP_FOR_THIS_PROMO' });
    }
  };
};
