export const closeOrderProgramPopUps = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_ORDER_PROGRAM_POPUPS' });
  };
};

export const clearOrderPrograms = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLEAR_ORDER_PROGRAMS' });
  };
};

export const addOrderProgram = (orderProgramDetails) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('orderPrograms')
      .where('name', '==', orderProgramDetails.programName)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          firestore
            .collection('orderPrograms')
            .add({
              name: orderProgramDetails.programName,
              description: orderProgramDetails.programDescrip,
            })
            .then((ref) => {
              dispatch({ type: 'ORDER_PROGRAM_CREATED' });
            })
            .catch((err) => {
              dispatch({ type: 'FAILED_TO_ORDER_PROGRAM', err });
            });
        } else {
          let resp = { ref: snapshot, orderProgramDetails: orderProgramDetails };
          dispatch({ type: 'ORDER_PROGRAM_ALL_READY_MADE', resp });
        }
      });
  };
};

export const getOrderProgramDetails = (orderProgramID) => {
  return (dispatch, getState, { getFirestore }) => {
    const firestore = getFirestore();
    //console.log('this is my order porgram ID', orderProgramID);
    firestore
      .collection('orderPrograms')
      .doc(orderProgramID)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          dispatch({ type: 'NO_ORDER_PROGRAM_FOUND' });
        } else {
          let orderProgram = { id: snapshot.id, data: snapshot.data() };
          dispatch({ type: 'ORDER_PROGRAM_WAS_FOUND', orderProgram });
        }
      })
      .catch((err) => {
        dispatch({ type: 'FAILED_TO_ORDER_PROGRAM', err });
      });
  };
};
