import authReducer from './authReducer';
import companyReducer from './companyReducer';
import coursesReducer from './coursesReducer';
import brandReducer from './brandReducer';
import promoReducer from './promoReducer';
import technologyReducer from './technologyReducer';
import tierReducer from './tierReducer';
import quizReducer from './quizReducer';
import staffReducer from './staffReducer';
import nationalAccountReducer from './nationalAccountReducer';
import orderProgramReducer from './orderProgramsReducer';
import itemReducer from './itemReducer';
import orderReducer from './orderReducer';
import { combineReducers } from 'redux';
import { firestoreReducer } from 'redux-firestore';
import { firebaseReducer } from 'react-redux-firebase';
import resourceLibraryReducer from './resourceLibraryReducer';

const rootReducer = combineReducers({
  firestore: firestoreReducer,
  firebase: firebaseReducer,
  auth: authReducer, //Handles all of the login infromation and user actions
  company: companyReducer, //Handles all company information
  brand: brandReducer, //Handles the different Solta Brands
  technology: technologyReducer, //Handles the different Technology
  items: itemReducer, //Handles all of the technology
  courses: coursesReducer, // Handles the different course vairables
  promos: promoReducer, //Handles the promos for the consumer site and clinic site
  quiz: quizReducer, // Handles the quiz stuff that are related to the courses
  staff: staffReducer, //Handles staff of clinics
  tiers: tierReducer, //Handles the different tiers for pricing of items
  nationalAccounts: nationalAccountReducer, //Handles the national accounts that clinics can be umbrellad under
  orderPrograms: orderProgramReducer, //Handles the different ordering programs that clinics can be in
  order: orderReducer, //This handles all of the order data
  resource: resourceLibraryReducer, //This handles all of the uploading/downloading of resources
});

export default rootReducer;
