import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import { signOut, editingThisUser } from '../../actions/authActions';
//import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
//import Menu from '@material-ui/core/Menu';
import { compose } from 'redux';
import { withTranslation } from 'react-i18next';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import { withStyles } from '@material-ui/core/styles';
import styles from '../Styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import LaptopIcon from '@material-ui/icons/Laptop';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import { Redirect } from 'react-router-dom';
import * as ROLES from '../../constants/roles';
import Hidden from '@material-ui/core/Hidden';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';
import BallotIcon from '@material-ui/icons/Ballot';
import FolderSpecialIcon from '@material-ui/icons/FolderSpecial';
import StarsIcon from '@material-ui/icons/Stars';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import AssessmentIcon from '@material-ui/icons/Assessment';
import AssistantPhotoIcon from '@material-ui/icons/AssistantPhoto';
import AssignmentIcon from '@material-ui/icons/Assignment';
import { faDolly } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import WorkIcon from '@material-ui/icons/Work';
import MessageIcon from '@material-ui/icons/Message';
import CalendarToday from '@material-ui/icons/CalendarToday';
import { faDoorOpen, faCalculator } from '@fortawesome/pro-solid-svg-icons';
import ListSubheader from '@material-ui/core/ListSubheader';

const INITIAL_STATE = {
  //loading: false,
  menuDrawer: false,
  anchor: 'left',
  redirect: false,
  redirectLink: null,
};

class Navigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...INITIAL_STATE,
    };
  }

  //const classes = useStyles();
  //const [anchorEl, setAnchorEl] = React.useState(null);
  //const open = Boolean(anchorEl);

  //console.log(props)

  handleMenu = (event) => {
    this.setState({
      anchorEll: event.currentTarget,
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null,
    });
  };

  openDrawer = () => {
    this.setState({
      menuDrawer: true,
    });
  };

  toggleDrawer = () => {
    this.setState({
      menuDrawer: false,
    });
  };

  clickLink = ({ link }) => {
    //console.log('this is my link', link);
    this.setState({
      menuDrawer: false,
      redirectLink: link,
      redirect: true,
    });
  };

  sendUserInfo = (userID) => {
    //console.log('this is my userId', userID);
    this.props.editingThisUser(userID);
  };

  render() {
    const { t, classes, auth, profile } = this.props;
    const { menuDrawer, anchor, redirect, redirectLink } = this.state;

    const signOutLinks = auth.uid ? null : (
      <Button type='button' onClick={this.handleClose} variant='contained' compontent={Link} to={ROUTES.LOGIN}>
        {t('navigation.signInButton')}
      </Button>
    );
    //console.log('logo props profile', props)
    return (
      <div>
        {redirect ? <Redirect push to={redirectLink} /> : null}
        <AppBar position='static'>
          <Toolbar className='nav-wrapper grey darken-3'>
            <div>
              <IconButton aria-label='account of current user' aria-controls='menu-appbar' aria-haspopup='true' onClick={this.openDrawer} color='inherit'>
                <MenuIcon />
              </IconButton>
              <SwipeableDrawer anchor={anchor} open={menuDrawer} onClose={this.toggleDrawer} onOpen={this.openDrawer}>
                <List className={classes.list}>
                  <ListItem button onClick={() => this.clickLink({ link: ROUTES.LANDING })}>
                    <ListItemIcon>
                      <HomeIcon />
                    </ListItemIcon>
                    <ListItemText primary='Home' />
                  </ListItem>
                </List>
                {auth.uid ? <><List
                  className={classes.list}
                  subheader={
                    <ListSubheader component='div' id='nested-list-subheader'>
                      Ordering System
                    </ListSubheader>
                  }
                >
                  <ListItem button onClick={() => this.clickLink({ link: ROUTES.ORDERS })}>
                    <ListItemIcon>
                      <AssignmentIcon />
                    </ListItemIcon>
                    <ListItemText primary='Orders' />
                  </ListItem>
                  {profile.role === ROLES.SUPERADMIN ? (
                    <>
                      <Divider />
                      <ListItem button onClick={() => this.clickLink({ link: ROUTES.REVIEW_ORDERS })}>
                        <ListItemIcon>
                          <FontAwesomeIcon icon={faDolly} />
                        </ListItemIcon>
                        <ListItemText primary='Review Orders' />
                      </ListItem>
                    </>
                  ) : (
                    ''
                  )}
                </List>
                <List
                  className={classes.list}
                  subheader={
                    <ListSubheader component='div' id='nested-list-subheader'>
                      Sales Rep Tools
                    </ListSubheader>
                  }
                >
                  <ListItem button onClick={() => this.clickLink({ link: ROUTES.USER })}>
                    <ListItemIcon>
                      <PeopleAltIcon />
                    </ListItemIcon>
                    <ListItemText primary='Users' />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={() => this.clickLink({ link: ROUTES.ROI })}>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faCalculator} size='1x' />
                    </ListItemIcon>
                    <ListItemText primary='ROI Calculator' />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={() => this.clickLink({ link: ROUTES.TRAINING })}>
                    <ListItemIcon>
                      <CalendarToday />
                    </ListItemIcon>
                    <ListItemText primary='Clinical Training' />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={() => this.clickLink({ link: ROUTES.CERTS })}>
                    <ListItemIcon>
                      <BallotIcon />
                    </ListItemIcon>
                    <ListItemText primary='Clinical Forms' />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={() => this.clickLink({ link: ROUTES.RESOURCE_LIBRARY })}>
                    <ListItemIcon>
                      <FolderSpecialIcon />
                    </ListItemIcon>
                    <ListItemText primary='Resource Library' />
                  </ListItem>
                  <Divider />
                  <ListItem button onClick={() => this.clickLink({ link: ROUTES.REVIEW_CLINIC })}>
                    <ListItemIcon>
                      <FontAwesomeIcon icon={faDoorOpen} />
                    </ListItemIcon>
                    <ListItemText primary='Clinic Review' />
                  </ListItem>
                </List>

                {profile.role === ROLES.SUPERADMIN ? (
                  <List
                    className={classes.list}
                    subheader={
                      <ListSubheader component='div' id='nested-list-subheader'>
                        Admin
                      </ListSubheader>
                    }
                  >
                    <ListItem button onClick={() => this.clickLink({ link: ROUTES.PROMOS })}>
                      <ListItemIcon>
                        <LoyaltyIcon />
                      </ListItemIcon>
                      <ListItemText primary='Promos' />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => this.clickLink({ link: ROUTES.TECH })}>
                      <ListItemIcon>
                        <LaptopIcon />
                      </ListItemIcon>
                      <ListItemText primary='Technologies' />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => this.clickLink({ link: ROUTES.TIERS })}>
                      <ListItemIcon>
                        <StarsIcon />
                      </ListItemIcon>
                      <ListItemText primary='Tiers' />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => this.clickLink({ link: ROUTES.ORDER_PROGRAMS })}>
                      <ListItemIcon>
                        <AssessmentIcon />
                      </ListItemIcon>
                      <ListItemText primary='Order Programs' />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => this.clickLink({ link: ROUTES.NATIONAL_ACCOUNTS })}>
                      <ListItemIcon>
                        <AssistantPhotoIcon />
                      </ListItemIcon>
                      <ListItemText primary='National Accounts' />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => this.clickLink({ link: ROUTES.MESSAGING })}>
                      <ListItemIcon>
                        <MessageIcon />
                      </ListItemIcon>
                      <ListItemText primary='Messaging System' />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => this.clickLink({ link: ROUTES.SALES_REPS })}>
                      <ListItemIcon>
                        <WorkIcon />
                      </ListItemIcon>
                      <ListItemText primary='Sales Reps' />
                    </ListItem>
                  </List>
                ) : (
                  ''
                )}

                {profile.role === ROLES.SUPERADMIN ? (
                  <List
                    className={classes.list}
                    subheader={
                      <ListSubheader component='div' id='nested-list-subheader'>
                        Courses
                      </ListSubheader>
                    }
                  >
                    <ListItem button onClick={() => this.clickLink({ link: ROUTES.COURSES })}>
                      <ListItemIcon>
                        <LibraryBooksIcon />
                      </ListItemIcon>
                      <ListItemText primary='Courses' />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => this.clickLink({ link: ROUTES.QUIZZES })}>
                      <ListItemIcon>
                        <AssignmentTurnedInIcon />
                      </ListItemIcon>
                      <ListItemText primary='Quizzes' />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => this.clickLink({ link: ROUTES.COURSE_DATA })}>
                      <ListItemIcon>
                        <EmojiPeopleIcon />
                      </ListItemIcon>
                      <ListItemText primary='Course Users' />
                    </ListItem>
                  </List>
                ) : (
                  ''
                )}

                <List className={classes.list}>
                  <ListItem button onClick={this.props.signOut}>
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                    <ListItemText primary='Logout' />
                  </ListItem>
                </List></> : ''}

                
              </SwipeableDrawer>
            </div>
            <Typography variant='h6' className={classes.title}>
              SOLTA - Admin
            </Typography>
            <Typography variant='h6' className={`${classes.title} ${classes.right} ${classes.whiteText}`} onClick={() => this.sendUserInfo(auth.uid)}>
              <Link to={ROUTES.USER + auth.uid} className={classes.whiteText}>
                <Hidden xsDown>{this.props.profile.isLoaded && !this.props.profile.isEmpty ? this.props.profile.fullName : ''}</Hidden>
                <Hidden smUp>{this.props.profile.isLoaded && !this.props.profile.isEmpty ? this.props.profile.initials : ''}</Hidden>
              </Link>{' '}
            </Typography>
            <MenuItem onClick={this.handleClose}>{signOutLinks}</MenuItem>
          </Toolbar>
        </AppBar>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth: state.firebase.auth,
    profile: state.firebase.profile,
  };
};

const mapDisptachToProps = (dispatch) => {
  return {
    signOut: () => dispatch(signOut()),
    editingThisUser: (userID) => dispatch(editingThisUser(userID)),
  };
};

Navigation.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withTranslation(), withStyles(styles), connect(mapStateToProps, mapDisptachToProps))(Navigation);
