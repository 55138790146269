//This is the reducer for the differnt National
// Accounts that clinics are a part of

const initState = {
  nationalAccountError: null,
  nationalAccountErrorPopup: false,
  nationalAccountMessage: null,
  nationalAccountPopUp: false,
};

const nationalAccountReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CLOSE_NATIONAL_ACCOUNT_POPUPS':
      return {
        ...state,
        nationalAccountPopUp: false,
        nationalAccountMessage: null,
        nationalAccountErrorPopup: false,
        nationalAccountError: null,
      };
    case 'NAT_PROGRAM_CREATED':
      return {
        ...state,
        nationalAccountPopUp: true,
        nationalAccountMessage: 'National Program was created',
      };
    case 'NAT_PROGRAM_UPDATED':
      return {
        ...state,
        nationalAccountPopUp: true,
        nationalAccountMessage: 'National Program was updated',
      };
    case 'FAILED_TO_CREATE_NAT_PROGRAM':
      return {
        ...state,
        nationalAccountErrorPopup: true,
        nationalAccountError: action.err.message,
      };
    case 'FAILED_TO_UPDATE_NAT_PROGRAM':
      return {
        ...state,
        nationalAccountErrorPopup: true,
        nationalAccountError: action.err.message,
      };
    default:
      return state;
  }
};

export default nationalAccountReducer;
