/**************************************************************************/
/*  File Name: resourceActions                                            */
/*  Date:      Jan 21 2021                                                */
/*  Author:    Ben Wakutz                                                 */
/*  Purpose:   Supports the actions for the resource adding and searching.*/
/*                                                                        */
/*  Last Modified: 4/05/2021                                              */
/**************************************************************************/
/*----------------------*/
/*   NEEDED ACTIONS     */
/*----------------------*/

//import { firestore } from "firebase";
//import { getFirebase } from "react-redux-firebase";

//ADD NEW RESOURCE
export const addNewResource = (resource) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    firestore
      .collection('resources')
      .where('partNumber', '==', resource.partNumber)
      .where('language', '==', resource.language)
      .get()
      .then((snapshot) => {
        if (snapshot.empty) {
          const storageRef = firebase.storage().ref();
          const uploadTask = storageRef.child(`resources/${resource.brand}/${resource.file[0].name}`).put(resource.file[0]);
          uploadTask.on('state_changed', function (snapshot) {
            var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
            dispatch({ type: 'UPDATE_UPLOAD_PROGRESS', progress });
            if (progress === 100) {
              dispatch({ type: 'UPLOAD_COMPLETE' });
            }
          });
          /*  firebase
            .uploadFiles(`resources/${resource.brand}`, resource.file) */
          //.then((snapshot) => {

          if (resource.thumbnail !== null) {
            const thumbFile = `resources/${resource.brand}/thumbs/${resource.thumbnail[0].name}`;
            const uploadThumbTask = storageRef.child(thumbFile).put(resource.thumbnail[0]);
            uploadThumbTask.on('state_changed', function (snapshot) {
              var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
              dispatch({ type: 'UPDATE_UPLOAD_PROGRESS', progress });
              if (progress === 100) {
                dispatch({ type: 'UPLOAD_COMPLETE' });
              }
            });
          }

          firestore
            .collection('resources')
            .add({
              //UPDATE TO RESOURCE
              title: resource.title,
              fileURL: `resources/${resource.brand}/${resource.file[0].name}`,
              dateAdded: firestore.FieldValue.serverTimestamp(),
              tags: resource.tags,
              brand: resource.brand,
              uploadUserID: resource.uploadUserID,
              uploadUser: resource.uploadUser,
              language: resource.language,
              mediaType: resource.mediaType,
              partNumber: resource.partNumber,
              clinicalDocument: resource.clinicalDocument,
            })
            .then((doc) => {
              if (resource.thumbnail !== null) {
                const thumbFile = `resources/${resource.brand}/thumbs/${resource.thumbnail[0].name}`;
                firestore.collection('resources').doc(doc.id).update({
                  thumbnail: thumbFile,
                });
              }

              dispatch({ type: 'RESOURCE_ADDED' });
            })
            .catch((err) => {
              dispatch({ type: 'RESOURCE_WAS_NOT_ADDED_TO_DATABASE', err });
            })
            //;
            //})
            .then(() => {
              dispatch({ type: 'RESOURCE_WAS_UPLOADED' });
            })
            .catch((err) => {
              dispatch({ type: 'RESOURCE_FAILED_TO_UPLOAD', err });
            });
        } else {
          dispatch({ type: 'RESOURCE_ALREADY_ADDED' });
        }
      });
  };
};

//ADD NEW THUMBNAIL
export const addNewThumbnail = (resource) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firebase = getFirebase();
    const firestore = getFirestore();

    console.log('this is my resource ---->< ', resource);
    const storageRef = firebase.storage().ref();
    const filePath = `resources/${resource.brand}/thumbs/${resource.thumbnail[0].name}`;

    const uploadTask = storageRef.child(filePath).put(resource.thumbnail[0]);
    uploadTask.on('state_changed', function (snapshot) {
      var progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
      dispatch({ type: 'UPDATE_UPLOAD_PROGRESS', progress });
      if (progress === 100) {
        dispatch({ type: 'THUMBNAIL_UPLOAD_COMPLETE' });
      }
    });
    /*  firebase
        .uploadFiles(`resources/${resource.brand}`, resource.file) */
    //.then((snapshot) => {

    firestore
      .collection('resources')
      .doc(resource.id)
      .update({
        thumbnail: filePath,
      })
      .then(() => {
        console.log('my resource was updated with the thumb nail??');
        dispatch({ type: 'THUMBNAIL_ADDED' });
      })
      .catch((err) => {
        console.log('my resource was updated with the thumb nail??', err);
        dispatch({ type: 'THUMBNAIL_WAS_NOT_ADDED_TO_DATABASE', err });
      })
      //;
      //})
      .then(() => {
        dispatch({ type: 'THUMBNAIL_WAS_UPLOADED' });
      })
      .catch((err) => {
        dispatch({ type: 'THUMBNAIL_FAILED_TO_UPLOAD', err });
      });
  };
};

//UPDATE RESOURCE
export const updateResource = (resource) => {
  return (dispatch, getState, { getFirestore, getFirebase }) => {
    const firestore = getFirestore();

    firestore
      .collection('resources')
      .doc(resource.id)
      .update({
        title: resource.title,
        partNumber: resource.partNumber,
        brand: resource.brand,
        language: resource.language,
        tags: resource.tags,
        //thumbnail: resource.thumbnail,
        clinicalDocument: resource.clinicalDocument,
      })
      .then(() => {
        dispatch({ type: 'RESOURCE_UPDATED' });
      })
      .catch((err) => {
        dispatch({ type: 'RESOURCE_NOT_UPDATED', err });
      });
  };
};

//DELETE
export const deleteResource = (resourceID) => {
  return (dispatch, getstate, { getFirestore }) => {
    const firestore = getFirestore();

    firestore
      .collection('resources')
      .doc(resourceID)
      .delete()
      .then(() => {
        dispatch({ type: 'RESOURCE_DELETED' });
      })
      .catch((err) => {
        dispatch({ type: 'ERROR_DELETE_RESOURCE', err });
      });
  };
};

export const closeResourcePopUps = () => {
  return (dispatch, getState) => {
    dispatch({ type: 'CLOSE_RESOURCE_POPUPS' });
  };
};

export const createMissingPDFs = () => {
  return (dispatch, getState, { getFirebase }) => {
    const firebase = getFirebase();

    const createThumbs = firebase.functions().httpsCallable('createPDFImageThumbNail');

    createThumbs().then((result) => {
      if (result.data.succes === true) {
        console.log('we have some nice thumbnails');
      } else if (result.data.success === false) {
        console.log('we failed', result);
      }
    });
  };
};
