//This is the reducer for the differnt order programs the they have
// VPP
// VPP Bulk
// VPP Autoship

const initState = {
  orderProgramError: null,
  orderProgramMessage: null,
  orderProgramPopUp: false,
  orderProgramErrorPopup: false,
  orderProgramsLoaded: false,
  orderPrograms: [],
};

const orderProgramReducer = (state = initState, action) => {
  switch (action.type) {
    case 'CLOSE_ORDER_PROGRAM_POPUPS':
      return {
        ...state,
        orderProgramPopUp: false,
        orderProgramErrorPopup: false,
        orderProgramError: null,
        orderProgramMessage: null,
      };
    case 'ORDER_PROGRAM_CREATED':
      return {
        ...state,
        orderProgramPopUp: true,
        orderProgramMessage: 'Order Program was added to the system',
      };
    case 'ORDER_PROGRAM_ALL_READY_MADE':
      return {
        ...state,
        orderProgramErrorPopup: true,
        orderProgramError: 'We are sorry that program already exists',
      };
    case 'FAILED_TO_ORDER_PROGRAM':
      return {
        ...state,
        orderProgramErrorPopup: true,
        orderProgramError: action.err.message,
      };
    case 'NO_ORDER_PROGRAM_FOUND':
      return {
        ...state,
        orderProgramErrorPopup: true,
        orderProgramError: 'No order program was found with that ID',
      };
    case 'ORDER_PROGRAM_WAS_FOUND':
      return {
        ...state,
        orderPrograms: [...state.orderPrograms, action.orderProgram],
        orderProgramsLoaded: true,
      };
    case 'CLEAR_ORDER_PROGRAMS':
      return {
        ...state,
        orderPrograms: [],
        orderProgramsLoaded: false,
      };
    default:
      return state;
  }
};

export default orderProgramReducer;
