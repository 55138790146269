import React, { Component } from 'react';
//import Footer from '../Footer';
import LogoHeader from '../Header';
import Navigation from '../Navigation';
import Grid from '@material-ui/core/Grid';
import styles from '../Styles';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';
import { compose } from 'redux';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Divider from '@material-ui/core/Divider';
import { sendError } from '../../actions/errorActions';
import { connect } from 'react-redux';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { error: null, errorInfo: null };
  }

  // For this example we'll just use componentDidCatch, this is only
  // here to show you what this method would look like.
  // static getDerivedStateFromProps(error){
  // return { error: true }
  // }

  componentDidCatch(error, info) {
    this.setState({ error: error, errorInfo: info });
    //console.log('sending off the error???', isDevReact());
    //console.log('this is my url', window.location.hostname);
    if (window.location.hostname !== 'localhost') {
      const sendErrors = { error: error, errorInfo: info, profile: this.props.profile, url: window.location.href, lang: this.props.i18n.language };
      //console.log('we are sending an error report');

      this.props.sendError(sendErrors);
    }

    //this.props.sendError(sendErrors);
  }

  render() {
    const { classes } = this.props;

    if (this.state.errorInfo) {
      return (
        <div className='meta-react'>
          <Navigation />
          <LogoHeader />
          <div className='container'>
            <div className='error-boundary'>
              <div className={classes.root}>
                <Grid container spacing={1} justifyContent='center' alignItems='center'>
                  <Grid item xs={1}>
                    <ErrorOutlineIcon className={`${classes.soltaColor} ${classes.svgIcon}`} alt='error' />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography variant='h5' className={`${classes.soltaColor} `}>
                      Looks like something went wrong
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Divider />
                  </Grid>
                  <Grid item xs={12}>
                    <details style={{ whiteSpace: 'pre-wrap' }}>
                      {this.state.error && this.state.error.toString()}
                      {this.state.errorInfo.componentStack}
                    </details>
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        </div>
      );
    }
    return this.props.children;
  }
}

const mapStateToProps = (state) => {
  //console.log('this is my props state', state);
  return {
    profile: state.firebase.profile,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    sendError: (error) => dispatch(sendError(error)),
  };
};

ErrorBoundary.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default compose(withStyles(styles), withTranslation(), connect(mapStateToProps, mapDispatchToProps))(ErrorBoundary);
